<template>
  <div class="purchase1" v-loading="loading">
    <el-form :model="siftForm" class="demo-form-inline" size="mini" inline @submit.native.prevent inline-message>
      <el-form-item>
        <el-button type="primary" plain v-if="handleDutys(ofoFlowWork.dutys, 'addType') && isShare == '1'" @click="handleAdd" icon="el-icon-plus">新增</el-button>
        <el-button type="warning" plain v-if="handleDutys(ofoFlowWork.dutys, 'addType') && isShare == '1'" @click="handleImport" icon="el-icon-upload2">活动导入</el-button>
      </el-form-item>
      <el-form-item label="上级" v-if="path != '/manage'">
        <el-select v-model="siftForm.baseId" placeholder="请选择上级" clearable filterable @change="inquire" >
          <el-option
            v-for="item in restaurants"
            :key="item.ofoWorkId"
            :label="item.value"
            :value="item.ofoWorkId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="编号/名称">
        <el-input
          v-model="siftForm.codename"
          placeholder="请输入编号/名称"
          @keyup.enter.native="inquire"
          @clear="inquire" 
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item :label="ofoFlowDeal.restype1Name" v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Name && ofoFlowDeal.restype1Kind != '6' && path != '/manage'">
        <el-select v-model="siftForm.dealId" :placeholder="'请选择' + ofoFlowDeal.restype1Name" clearable filterable @change="inquire" >
          <el-option
            v-for="item in resourceList1"
            :key="item.ofrResourceId"
            :label="item.resourceName"
            :value="item.ofrResourceId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="ofoFlowDeal.restype1Name" v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Name && ofoFlowDeal.restype1Kind == '6' && path != '/manage'">
        <el-select v-model="siftForm.dealowhId" :placeholder="'请选择' + ofoFlowDeal.restype1Name" clearable filterable @change="inquire" >
          <el-option 
            v-for="(item, index) in departmentList" 
            :key="index" 
            :label="item.name" 
            :value="item.cbOwhId">
          </el-option>
        </el-select>
      </el-form-item>
      
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" style="background:#0C75E5" @click="inquire">查询</el-button>
        <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-dropdown :hide-on-click="false" trigger="click" ref="dropdown">
          <span class="el-dropdown-link">
            <i class="el-icon-sort el-icon--right" style="font-size:14px">排序</i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-checkbox v-model="sortParams[0].value1">编号</el-checkbox>
              <el-button type="text" @click="handleSort(sortParams[0].sortValue, 0)" style="margin-left:10px">{{(sortParams[0].sortValue == 'asc') ? '正序' : '倒序'}}</el-button>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-checkbox v-model="sortParams[1].value1">状态</el-checkbox>
              <el-button type="text" @click="handleSort(sortParams[1].sortValue, 1)" style="margin-left:10px">{{(sortParams[1].sortValue == 'asc') ? '正序' : '倒序'}}</el-button>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-checkbox v-model="sortParams[2].value1">名称</el-checkbox>
              <el-button type="text" @click="handleSort(sortParams[2].sortValue, 2)" style="margin-left:10px">{{(sortParams[2].sortValue == 'asc') ? '正序' : '倒序'}}</el-button>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-checkbox v-model="sortParams[3].value1">日期</el-checkbox>
              <el-button type="text" @click="handleSort(sortParams[3].sortValue, 3)" style="margin-left:10px">{{(sortParams[3].sortValue == 'asc') ? '正序' : '倒序'}}</el-button>
            </el-dropdown-item>
            <!-- <el-dropdown-item>
              <el-checkbox v-model="sortParams[3].value1">数量</el-checkbox>
              <el-button type="text" @click="handleSort(sortParams[3].sortValue, 3)" style="margin-left:10px">{{(sortParams[3].sortValue == 'asc') ? '正序' : '倒序'}}</el-button>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-checkbox v-model="sortParams[4].value1">金额</el-checkbox>
              <el-button type="text" @click="handleSort(sortParams[4].sortValue, 4)" style="margin-left:10px">{{(sortParams[4].sortValue == 'asc') ? '正序' : '倒序'}}</el-button>
            </el-dropdown-item> -->
            <el-dropdown-item divided>
              <el-button size="mini" type="primary" @click="commitSort">确定</el-button>
              <el-button size="mini" @click="resetSort">重置</el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>
      <el-form-item>
        <el-button type="text" @click="changeShow" :icon="isShowCard ? 'el-icon-tickets': 'el-icon-s-grid'"></el-button>
      </el-form-item>
    </el-form>
    <div style="overflow:hidden" v-if="workList.length">
      <!-- 卡片形式 -->
      <template v-if="isShowCard">
        <!-- <el-row :gutter="20">
          <el-col :span="currentSpan" v-for="(item,index) in workList" :key="index"> -->
            <div class="el-card" v-for="(item,index) in workList" :key="index" @click="handleView (item)" :class="(index + 1) % 5 == 0 ? 'noright' : ''">
              <div v-if="item.appstatus != '3'" :style="item.documentstatus == '0' ? 'color: #0063FB;background-color: #E5F3FF' : (item.documentstatus == '1' ? 'color: #13ce66;background-color: #e7faf0' : (item.documentstatus == '2' ? 'color: #ffba00;background-color: #fff8e6' : 'color: #ff4949;background-color: #ffeded'))" class="status">
                <DictTag :options="dropDown.documentstatus" :value="item.documentstatus"/> 
              </div>
              <div v-else class="status" style="color: #ffba00;background-color: #fff8e6">审批中</div>
              <span @mouseenter="isShowToltip" @mouseout="hideTip" v-if="!isShowText" class="code">{{item.code}}</span>
              <el-tooltip popper-class="atooltip" 
                effect="dark" 
                :content="item.code" 
                placement="top-start"
                v-if="isShowText">
                <span class="code" @mouseout="hideTip">{{item.code}}</span>
              </el-tooltip>
              <i class="iconfont icon-yihexiao" v-if="item.checkStatus == '0'"></i>
              <div class="text header">
                <span class="text-left">名称:</span>
                <span @mouseenter="isShowToltip" @mouseout="hideTip" v-if="!isShowText" class="text-right">{{item.name}}</span>
                <el-tooltip popper-class="atooltip" 
                  effect="dark" 
                  :content="item.name" 
                  placement="top-start"
                  v-if="isShowText">
                  <span class="text-right" @mouseout="hideTip">{{item.name}}</span>
                </el-tooltip>
              </div> 
              <div class="text">
                <span class="text-left">日期:</span>
                <span class="text-right">{{item.workDate | filter_handleDate}}</span>
              </div>
              <div class="text" v-for="(val, id) in showList" :key="id">
                <div class="text-left">{{val.name + ':'}}</div>
                <div class="text-right">
                  <template v-if="val.type == 'b'">
                    <template v-if="val.column == 'ptotal'">
                      {{item[val.column] | comdify}}
                    </template>
                    <template v-else>
                      {{item[val.column]}}
                    </template>
                  </template>
                  <template v-else>{{item.workShowMap[val.column]}}</template>
                </div>
              </div>
              <template v-if="!showList.length">
                <div class="text" v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '4'">
                  <span class="text-left">数量:</span>
                  <span class="text-right">{{item.quantity}}</span>
                </div>
                <div class="text" v-if="ofoFlowWork.costmanagetype == '7'">
                  <span class="text-left">时间:</span>
                  <span class="text-right">{{item.quantity | filter_handleTimeRange}}</span>
                </div>
                <div class="text" v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8'">
                  <span class="text-left">金额:</span>
                  <span class="text-right">{{item.ptotal | comdify}}</span>
                </div>
              </template>
              <div class="text" v-if="ofoFlowWork.costmanagetype == '7' || ofoFlowWork.costmanagetype == '8' || ofoFlowWork.costmanagetype == '4'">
                <span class="text-left">&nbsp;</span>
              </div>
              <div v-if="(ofoFlowWork.checkType == '1' || ofoFlowWork.checkType == '2')" style="height: 18px">
                <el-progress :percentage="item.percentage" style="margin: 0" v-if="item.openTotal != null && item.percentage != 100 && item.checkStatus != '0'" :format="format"></el-progress>
              </div>
            </div>
          <!-- </el-col>
        </el-row> -->
        
      </template>
      <!-- 表格形式 -->
      <template v-else>
        <el-table
          :data="workList"
          border
          :cell-style="{
            padding: '3px 0'
          }"
          :header-cell-style="{
            padding: '3px 0',
            background:'#F9F9FB',
            color:'#606266'
          }"
          style="width: 100%;margin-bottom:10px">
          <el-table-column prop="code" label="编号" align="center" width="180" />
          <el-table-column prop="name" label="名称" align="center">
            <template slot-scope="scope">
              <div class="link" @click="handleView(scope.row)">{{scope.row.name}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="workDate" label="日期" align="center" width="110">
            <template slot-scope="scope">
              {{scope.row.workDate | filter_handleDate}}
            </template>
          </el-table-column>
          <el-table-column v-for="(val, id) in showList" :key="id" :label="val.name" :align="(val.column == 'quantity' || val.column == 'ptotal') ? 'right' : 'center'" header-align="center">
            <template slot-scope="scope">
              <template v-if="val.type == 'b'">
                <template v-if="val.column == 'ptotal'">
                  {{scope.row[val.column] | comdify}}
                </template>
                <template v-else>
                  {{scope.row[val.column]}}
                </template>
              </template>
              <template v-else>{{scope.row.workShowMap[val.column]}}</template>
            </template>
          </el-table-column>
          
          <template v-if="!showList.length">
            <el-table-column prop="quantity" label="数量" align="right" header-align="center" v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '4'" />
            <el-table-column prop="quantity" label="时间" align="center" v-if="ofoFlowWork.costmanagetype == '7'">
              <template slot-scope="scope">
                {{scope.row.quantity | filter_handleTimeRange}}
              </template>
            </el-table-column>
            <el-table-column prop="ptotal" label="金额" align="right" header-align="center" v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '4'">
              <template slot-scope="scope">
                {{scope.row.ptotal | comdify}}
              </template>
            </el-table-column>
          </template>
          <el-table-column label="状态" align="center" width="80">
            <template slot-scope="scope">
              <DictTag v-if="scope.row.appstatus != '3'" :options="dropDown.documentstatus" :value="scope.row.documentstatus" isStyle/>
              <el-tag v-else type="warning">审批中</el-tag>
            </template>
          </el-table-column>
          <el-table-column v-if="(ofoFlowWork.checkType == '1' || ofoFlowWork.checkType == '2')" label="进度" align="center" width="180">
            <template slot-scope="scope">
              <el-progress :percentage="scope.row.percentage" style="margin: 0" v-if="scope.row.openTotal != null && scope.row.percentage != 100 && scope.row.checkStatus != '0'" :format="format"></el-progress>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div v-if="!workList.length" style="text-align:center;color:#999">暂无数据~</div>
    <!-- <el-pagination
      @size-change="handleSizeChange"
      background
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="!isShowCard ? [10, 20, 50, 100, 200] : (currentSpan == 4 ? [12, 24, 48, 96, 192] : (currentSpan == 5 ? [10, 20, 50, 100, 200] : (currentSpan == 6 ? [8, 16, 32, 64, 128] : (currentSpan == 8 ? [12, 24, 48, 96, 192] : [10, 20, 50, 100, 200]))))"
      :page-size="pageSize"
      layout="->, prev, pager, next, sizes, jumper, total"
      :total="total"
    > -->
    <el-pagination
      @size-change="handleSizeChange"
      background
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[10, 20, 50, 100, 200]"
      :page-size="pageSize"
      layout="->, prev, pager, next, sizes, jumper, total"
      :total="total"
    >
    </el-pagination>
    <!-- <el-card class="create" @click.native="handleAdd" style="height: 112px" v-if="handleDutys(ofoFlowWork.dutys, 'addType')">
      <i class="el-icon-folder-add"></i>
      <p>新建</p>
    </el-card> -->
    <!-- 新增修改详情活动对话框 -->
    <el-dialog :visible.sync="dialogFormVisible" :append-to-body="true" :width="(ofoFlowWork.kindtype || ofoFlowWork.kinddocutype || fileList.length || ofoFlowWork.thingtype) ? '90%' : '50%'" top="59px" :show-close="false">
      <div slot="title" class="dialog-title">
        <div>
          <i :class="isShowNav ? 'el-icon-s-fold' : 'el-icon-s-unfold'" @click="changeNav" style="font-size:20px" v-if="isShowNavBtn"></i>
          <span style="font-weight:400;font-size:20px">{{title}}</span>
        </div>
        <div style="display: flex">
          <div v-if="isShowCollect && isShare == '1'" style="display:flex;margin-right: 8px">
            <el-button :icon="isCollect == '0' ? 'el-icon-star-on' : 'el-icon-star-off'" @click="handleCollection(ruleForm, 'c')" type="text" :style="isCollect == '0' ? {'font-size':'22px',padding: 0} : {'font-size':'18px',padding: 0}"></el-button>
            <el-button icon="el-icon-share" @click="handleCollection(ruleForm, 's')" type="text" style="padding: 0; font-size:18px" v-if="handleDutys(ofoFlowWork.dutys, 'shareType')"></el-button>
          </div>
          <el-button icon="el-icon-printer" type="text" style="font-size:17px; padding: 0;" v-if="disabled" @click="clickPrint"></el-button>
          <el-button icon="el-icon-close" @click="dialogFormVisible = false" type="text" style="padding: 0; font-size:18px; color: #666666"></el-button>
        </div>
      </div>
      <div class="dialog-content">
        <div class="content-left" v-show="isShowNav" >
          <el-timeline>
            <el-timeline-item v-for="(item, index) in processList" :key="index" placement="top" timestamp="" :color="item.color">
              <div
                v-for="(val, id) in item"
                :key="id"
                :style="{
                  ...{
                    border:'1px solid #EBEEF5',
                    'border-radius':'4px',
                    'box-shadow': '0 2px 12px 0 rgba(0,0,0,.1)',
                    padding: '22px 16px 0',
                    'margin-bottom':'10px',
                    position: 'relative'
                  },
                  ...{
                    background: (val.ofoWorkId == ruleForm.ofoWorkId) ? '#ecf5ff' : '#fff'
                  }
                }"
              >
                <div :style="{
                  ...{
                    position:'absolute',
                    left: 0,
                    top:0,
                    padding:'2px 5px',
                    'border-radius': '4px 0px 4px 0px',
                    color:'#282828',
                    'font-size': '13px'
                  },
                  ...{
                    color: (val.ofoWorkId == ruleForm.ofoWorkId) ? '#fff' : '#000',
                    background: (val.ofoWorkId == ruleForm.ofoWorkId) ? 'rgb(64, 158, 255)' : '#F2F2F2',
                  }
                }">
                  {{val.ofoflowworkName}}
                </div>
                <!-- 活动状态 -->
                <div :style="{
                  ...{
                    position:'absolute',
                    right: 0,
                    top:0,
                    padding:'2px 5px',
                    'border-radius': '0px 4px 0px 4px',
                    color:'#282828',
                    'font-size': '13px'
                  },
                  ...{
                    color: val.documentstatus == '0' ? '#0063FB' : (val.documentstatus == '1' ? '#13ce66' : (val.documentstatus == '2' ? '#ffba00' : '#ff4949')),
                    'background-color': val.documentstatus == '0' ? '#E5F3FF' : (val.documentstatus == '1' ? '#e7faf0' : (val.documentstatus == '2' ? '#fff8e6' : '#ffeded'))
                  }
                }">
                  <DictTag :options="dropDown.documentstatus" :value="val.documentstatus"/> 
                </div>
                <el-descriptions :column="1">
                  <el-descriptions-item label="名称" content-class-name="my-content">
                    <div @click="jumpWork(val)" class="link my-content" v-if="val.ofoWorkId != ruleForm.ofoWorkId">{{val.name}}</div>
                    <div v-show="val.ofoWorkId == ruleForm.ofoWorkId" class="my-content">{{val.name}}</div>
                  </el-descriptions-item>
                </el-descriptions>
                <el-descriptions :column="1" content-class-name="my-content">
                  <el-descriptions-item label="编号">
                    <div class="my-content">{{val.code}}</div>
                  </el-descriptions-item>
                </el-descriptions>
                <el-descriptions :column="2">
                  <el-descriptions-item label="时间" v-if="ofoFlowWork.costmanagetype == '7'">{{val.quantity | filter_handleTimeRange}}</el-descriptions-item>
                  <el-descriptions-item label="数量" v-else>{{val.quantity}}</el-descriptions-item>
                  <el-descriptions-item label="金额" v-if="ofoFlowWork.costmanagetype != '7'">{{val.ptotal | comdify}}</el-descriptions-item>
                </el-descriptions>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div class="content-right">
          <!-- <div style="margin-bottom: 16px; ">
          </div> -->
          <div style="margin-bottom: 16px;display: flex" v-if="isShare == '1' && isShowBtn">
            <!-- 编辑 -->
            <el-button size="mini" type="primary" plain @click="handleEditBtn" :disabled="approvalStatus == '3'" v-if="handleDutys(ofoFlowWork.dutys, 'updType') && documentstatus == '0' && disabled" :key="1">编辑</el-button>
            <el-button size="mini" type="primary" plain @click="handleEditBtn" :disabled="approvalStatus == '3'" v-if="handleDutys(ofoFlowWork.dutys, 'updType') && ofoFlowWork.statusEdit == '1' && disabled && documentstatus != '0'" :key="2">编辑</el-button>
            <el-button size="mini" type="primary" @click="handleReuse" :key="14" :disabled="isDisabled" v-if="ofoFlowWork.isReuse == '0' && handleDutys(ofoFlowWork.dutys, 'addType')">复用</el-button>
            <el-button size="mini" type="primary" @click="handleEditItem" :key="12" v-if="handleDutys(ofoFlowWork.dutys, 'updType') && ofoFlowWork.statusEdit == '2' && disabled && documentstatus != '0'">切换项目</el-button>
            <!-- 正式 -->
            <el-button size="mini" type="success" plain @click="handleFormal" :disabled="approvalStatus == '0' || approvalStatus == '2' || approvalStatus == '3' || approvalStatus == '5'" v-if="handleDutys(ofoFlowWork.dutys, 'updType') && documentstatus == '0' && disabled" :key="3">正式</el-button>
            <!-- 结算 -->
            <el-button size="mini" type="warning" plain @click="handleSettlement" v-if="handleDutys(ofoFlowWork.dutys, 'updType') && documentstatus == '1'" :key="4">结算</el-button>
            <!-- 作废 -->
            <!-- <el-button size="mini" type="danger" plain @click="handleVoid" :disabled="approvalStatus == '3'" v-if="handleDutys(ofoFlowWork.dutys, 'updType') && (documentstatus == '0' || documentstatus == '1') && disabled" :key="5">作废</el-button> -->
            <!-- 删除 -->
            <el-button size="mini" type="danger" plain @click="handleDel" :disabled="approvalStatus == '3'" v-if="handleDutys(ofoFlowWork.dutys, 'delType') && documentstatus != '' && disabled" :key="6">删除</el-button>
            <el-button size="mini" @click="dialogFormVisible = false" :key="13">关 闭</el-button>
            <!-- 申请意见 -->
            <template v-if="isStartApp == '1' && documentstatus == '0' && disabled">
              <el-input size="mini" placeholder="请输入申请意见" style="width: 200px;margin: 0 16px" v-model="approvalText"></el-input>
              <el-button size="mini" type="primary" @click="submitAppRemarks" :key="7">发起审批</el-button>
            </template>
            <!-- 撤回意见 -->
            <template v-if="isStartApp == '2' && documentstatus == '0' && disabled && approvalStatus == '3'">
              <el-input size="mini" placeholder="请输入撤回原因" style="width: 200px;margin: 0 16px" v-model="backText"></el-input>
              <el-button size="mini" type="danger" @click="backOsApp" :key="8">撤回审批</el-button>
            </template>
            <!-- 自由流审批 -->
            <template v-if="isStartApp == '3' && documentstatus == '0' && disabled">
              <el-input size="mini" placeholder="请输入申请意见" style="width: 200px;margin: 0 16px" v-model="approvalText"></el-input>
              <!-- <el-button size="mini" type="primary" @click="submitAppRemarks" :key="7">发起审批</el-button> -->
              <el-popover
                placement="bottom"
                width="160"
                ref="popover">
                <el-select size="mini" v-model="approvalPeople" placeholder="请选择审批人" style="width:100%" filterable multiple >
                  <el-option-group
                    v-for="group in chooseList"
                    :key="group.ofrResourceId"
                    :label="group.resourceName">
                    <el-option
                      v-for="item in group.ofrThingList"
                      :key="item.ofrThingId"
                      :label="item.name"
                      :value="item.ofrThingId">
                    </el-option>
                  </el-option-group>
                </el-select>
                <div style="text-align: right; margin-top: 10px">
                  <el-button type="primary" size="mini" @click="submitAppRemarks" :key="10">发起审批</el-button>
                </div>
                <el-button size="mini" type="primary" slot="reference" @click="selectAppPeople" :key="11">选择审批人</el-button>
              </el-popover>
              <!-- <el-button size="mini" type="primary" :key="7">发起审批</el-button> -->
            </template>
            <!-- 复制到 -->
            <template  v-if="ofoFlowWork.kindtype && ofoFlowWork.workkindmanagetype != '0' && documentstatus == '1' && workCopyToList.length">
              <el-select size="mini" style="margin-left:16px;margin-right:16px" v-model="copyTo" placeholder="请选择下一步活动类型">
                <el-option v-for="(item, index) in workCopyToList" :key="index" :label="item.name" :value="item.ofoFlowWorkId"></el-option>
              </el-select> 
              <el-button size="mini" type="info" plain @click="copyToAdd" :key="9">复制</el-button>
            </template>
            
          </div>
          <!-- <div style="margin-bottom: 16px;display: flex" v-if="!isShowBtn">
            <el-button size="mini" @click="dialogFormVisible = false" :key="12">关 闭</el-button>
            <el-button size="mini" type="primary" @click="submitForm('ruleForm')" v-show="!disabled" :disabled="isDisabled" :key="13">确 定</el-button>
          </div> -->
          <!-- <el-form :model="ruleForm" label-width="120px" :rules="!disabled? rules : {}" ref="ruleForm" :disabled="disabled"> -->
          <el-form :model="ruleForm" label-width="120px" :rules="!disabled? rules : {}" ref="ruleForm" size="small">
            <template v-if="!disabled">
              <!-- 名称 编号 -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="名称" prop="name">
                    <el-input v-model="ruleForm.name" style="width: 100%" clearable></el-input>
                    <!-- <div v-if="disabled">{{ruleForm.name}}</div> -->
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="编号" prop="code">
                    <!-- <el-input v-model="ruleForm.code" style="width: 100%"></el-input> -->
                    <el-autocomplete
                      class="inline-input"
                      v-model="ruleForm.code"
                      :fetch-suggestions="querySearch"
                      placeholder="请输入编号"
                      @select="handleSelect"
                      clearable
                      style="width: 100%"
                      :disabled="ofoFlowWork.codeConfig == '1'"
                    ></el-autocomplete>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 交易组件有甲方资源类型并且资源种类不为‘管理’ -->
              <el-row v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind != '6' && ofoFlowDeal.restype1Name" :gutter="20">
                <el-col :span="12">
                  <el-form-item :label="ofoFlowDeal.restype1Name" prop="dealId">
                    <template slot="label">
                      {{ofoFlowDeal.restype1Name}}
                      <el-button type="text" @click="addResource" v-if="resourceType.isAdd == '0'" icon="el-icon-circle-plus-outline"></el-button>
                    </template>
                    
                    <el-select 
                      v-model="ruleForm.dealId"
                      :placeholder="!disabled ? `请选择${ofoFlowDeal.restype1Name}`: ''"
                      style="width: 100%"
                      @change="getResChange"
                      filterable
                      clearable
                    >
                      <el-option v-for="(item, index) in resourceList" :key="index" :label="item.resourceName" :value="item.ofrResourceId" ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item >
                    <div slot="label" style="display:flex;justify-content:flex-end">
                      <template v-if="ofoFlowWork.thingDimeType">
                        <DictTag :options="dropDown.thingDimeType" :value="ofoFlowWork.thingDimeType" style="margin-right:3px"/>
                      </template>
                      <template v-if="!ofoFlowWork.thingDimeType">
                        内容
                      </template>
                      <el-button type="text" size="mini" @click="addThing" v-if="resourceType.isAdd == '0'" icon="el-icon-circle-plus-outline"></el-button>
                    </div>
                    
                    <el-select
                      v-model="ruleForm.dealownId"
                      :placeholder="!disabled? `请选择内容` : ''"
                      style="width: 100%"
                      filterable
                      clearable
                      multiple
                      @change="getDealownChange"
                    >
                      <el-option v-for="(item, index) in thingList" :key="index" :label="item.name" :value="item.ofrThingId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 交易组件有甲方资源类型并且资源种类为‘管理’ -->
              <el-row v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind == '6' && ofoFlowDeal.restype1Name" :gutter="20">
                <el-col :span="12">
                  <el-form-item :label="ofoFlowDeal.restype1Name" prop="dealowhId">
                    <el-select
                      v-model="ruleForm.dealowhId"
                      :placeholder="!disabled? `请选择${ofoFlowDeal.restype1Name}` : ''"
                      style="width: 100%"
                      @change="getDealowhChange"
                      filterable
                    >
                      <el-option v-for="(item, index) in departmentList" :key="index" :label="item.name" :value="item.cbOwhId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="联系人">
                    <el-select
                      v-model="ruleForm.dealownId"
                      :placeholder="!disabled ? '请选择联系人': ''"
                      style="width: 100%"
                      filterable
                      clearable
                      multiple
                    >
                      <el-option v-for="(item, index) in contactList" :key="index" :label="item.ofrthingName" :value="item.ofrthingId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 项目时间  是否预警 -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="活动时间" prop="workDate">
                    <el-date-picker
                      v-model="ruleForm.workDate"
                      type="date"
                      placeholder="选择活动时间"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      style="width: 100%"
                      :clearable='false'
                    >
                    </el-date-picker>
                    <!-- <el-date-picker
                      v-model="ruleForm.workDate"
                      type="datetime"
                      placeholder="选择项目时间"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      style="width: 100%"
                      :clearable='false'
                    >
                    </el-date-picker> -->
                  </el-form-item>
                </el-col>
                <el-col :span="4" v-if="ofoFlowWork.isNotice == '0'">
                  <el-form-item label="是否协同">
                    <el-checkbox v-model="ruleForm.isNotice" :true-label="'0'" :false-label="'1'" :disabled="documentstatus == '1'"></el-checkbox>
                    <!-- <el-radio v-model="ruleForm.isNotice" label="0">是</el-radio>
                    <el-radio v-model="ruleForm.isNotice" label="1">否</el-radio> -->
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="是否预警">
                    <el-checkbox v-model="ruleForm.ifalert" :true-label="'0'" :false-label="'1'"></el-checkbox>
                    <!-- <el-radio v-model="ruleForm.ifalert" label="0">是</el-radio>
                    <el-radio v-model="ruleForm.ifalert" label="1">否</el-radio> -->
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 是否协同 -->
              <el-row v-if="ruleForm.isNotice == '0'" :gutter="20">
                <el-col :span="12">
                  <el-form-item label="协同人">
                    <el-select
                      v-model="ruleForm.noticeUsers"
                      placeholder="请选择协同人"
                      style="width: 100%"
                      multiple
                      filterable
                      clearable
                      :disabled="documentstatus == '1'"
                    >
                      <el-option-group
                        v-for="group in postAndUserList"
                        :key="group.ofrResourceId"
                        :label="group.resourceName">
                        <el-option
                          v-for="item in group.ofrThingList"
                          :key="item.ofrThingId"
                          :label="item.name"
                          :value="item.ofrThingId + ''">
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="协同活动">
                    <el-select
                      v-model="ruleForm.noticeFlowWork"
                      placeholder="请选择协同活动"
                      style="width: 100%"
                      filterable
                      clearable
                      :disabled="documentstatus == '1'"
                    >
                      <el-option v-for="(item, index) in workCopyToList1" :key="index" :label="item.name" :value="item.ofoFlowWorkId  + ''"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 是否预警 -->
              <el-row v-if="ruleForm.ifalert == '0'" :gutter="20">
                <el-col :span="12">
                  <el-form-item label="预警时间" prop="alertDate">
                    <el-date-picker
                      v-model="ruleForm.alertDate"
                      type="date"
                      placeholder="选择预警时间"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      style="width: 100%"
                      :picker-options="pickerBeginOption"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="预警人">
                    <el-select
                      v-model="ruleForm.alertUsers"
                      placeholder="请选择预警人"
                      style="width: 100%"
                      multiple
                      filterable
                      clearable
                      :disabled="documentstatus == '1'"
                    >
                      <el-option-group
                        v-for="group in postAndUserList"
                        :key="group.ofrResourceId"
                        :label="group.resourceName">
                        <el-option
                          v-for="item in group.ofrThingList"
                          :key="item.ofrThingId"
                          :label="item.name"
                          :value="item.ofrThingId + ''">
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
            <template v-if="disabled">
              <el-descriptions :column="3" :contentStyle="{'padding-right': '16px'}">
                <el-descriptions-item label="名称">{{ruleForm.name}}</el-descriptions-item>
                <el-descriptions-item label="编号">{{ruleForm.code}}</el-descriptions-item>
                <el-descriptions-item label="活动时间">{{ruleForm.workDate}}</el-descriptions-item>
                <!-- 交易组件有甲方资源类型并且资源种类不为‘管理’ -->
                <el-descriptions-item :label="ofoFlowDeal.restype1Name" v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind != '6' && ofoFlowDeal.restype1Name">{{ruleForm.dealName}}</el-descriptions-item>
                <el-descriptions-item v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind != '6' && ofoFlowDeal.restype1Name">
                  <div slot="label">
                    <template v-if="ofoFlowWork.thingDimeType">
                      <DictTag :options="dropDown.thingDimeType" :value="ofoFlowWork.thingDimeType" style="margin-right:3px"/>
                    </template>
                    <template v-if="!ofoFlowWork.thingDimeType">
                      内容
                    </template>
                  </div>
                  {{ruleForm.dealownName}}
                </el-descriptions-item>
                <!-- 交易组件有甲方资源类型并且资源种类为‘管理’ -->
                <el-descriptions-item :label="ofoFlowDeal.restype1Name" v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind == '6' && ofoFlowDeal.restype1Name">{{ruleForm.dealowhName}}</el-descriptions-item>
                <el-descriptions-item label="联系人" v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind == '6' && ofoFlowDeal.restype1Name">
                  {{ruleForm.dealownName}}
                </el-descriptions-item>
                <template v-if="ofoFlowWork.isNotice == '0'">
                  <el-descriptions-item label="是否协同">{{ruleForm.isNotice == '0' ? '是' : '否'}}</el-descriptions-item>
                  <el-descriptions-item label="协同人" v-if="ruleForm.isNotice != '1'">{{ruleForm.noticeUsersName}}</el-descriptions-item>
                  <el-descriptions-item label="协同活动" v-if="ruleForm.isNotice != '1'">
                    {{ruleForm.noticeFlowWorkName}}
                    <!-- <SelectData :value="ruleForm.noticeFlowWork" :data="workCopyToList1" :id="'ofoFlowWorkId'" :label="'name'"/> -->
                  </el-descriptions-item>
                </template>
                <el-descriptions-item label="是否预警">{{ruleForm.ifalert == '0' ? '是' : '否'}}</el-descriptions-item>
                <el-descriptions-item label="预警时间" v-if="ruleForm.ifalert == '0'">{{ruleForm.alertDate}}</el-descriptions-item>
                <el-descriptions-item label="预警人" v-if="ruleForm.ifalert == '0'">{{ruleForm.alertUsersName}}</el-descriptions-item>
              </el-descriptions>
            </template>
            <!-- 导航栏 -->
            <div class="nav-tabs">
              <div v-for="(item, index) in navList" :key="index" @click="checked(index)" :class="['nav-title', isActive == index ? 'isActive' : '']" v-show="item.isShow">
                <div class="nav-icon">
                  <span :class="item.icon"></span>
                </div>
                <div class="nav-text">{{item.text}}</div>
              </div>
            </div>
            <!-- 明细 -->
            <div class="nav-content" v-show="isActive == 0">
              <keep-alive>
                <Subsidiary 
                  :disabled="disabled" 
                  :ofoFlowWork="ofoFlowWork" 
                  :thingsList="thingsList"
                  @sendThingsList="receiveThingsList"
                  @getWorkThingList="getWorkThingList"
                  :ofoWorkId="ruleForm.ofoWorkId"
                  :total="total2"
                  :pageNum="pageNum2"
                  :pageSize="pageSize2"
                  :ctotal2="ruleForm.ctotal"
                  :ctotalNovat2="ruleForm.ctotalNovat"
                  :ctotalVat2="ruleForm.ctotalVat"
                  :ptotal2="ruleForm.ptotal"
                  :ptotalNovat2="ruleForm.ptotalNovat"
                  :ptotalVat2="ruleForm.ptotalVat"
                  ref="subsidiary"
                  @copyFromConfirm="copyFromConfirm"
                  :workConfig="workConfig"
                  :key="timer"
                  :limit="limit"
                  :thingLimit="thingLimit"
                  :thingPreLimit="thingPreLimit"
                  :params="params"
                />
              </keep-alive>
            </div>
            <!-- 产品及服务 -->
            <div class="nav-content" v-show="isActive == 1">
              <keep-alive>
                <Product 
                  :disabled="disabled"
                  :ofoFlowWork="ofoFlowWork" 
                  :productsList="productsList"
                  @sendProductsList="receiveProductsList"
                  @copyFromConfirm="copyFromConfirm"
                  @getWorkResList="getWorkResList"
                  :ofoWorkId="ruleForm.ofoWorkId"
                  :total="total1"
                  :pageNum="pageNum1"
                  :pageSize="pageSize1"
                  :ctotal1="ruleForm.ctotal"
                  :ctotalNovat1="ruleForm.ctotalNovat"
                  :ctotalVat1="ruleForm.ctotalVat"
                  :ptotal1="ruleForm.ptotal"
                  :ptotalNovat1="ruleForm.ptotalNovat"
                  :ptotalVat1="ruleForm.ptotalVat"
                  ref="product"
                  :workConfig="workConfig"
                  :key="timer"
                  :limit="limit"
                  :resLimit="resLimit"
                  :resPreLimit="resPreLimit"
                  :params="params"/>
              </keep-alive>
            </div>
            <!-- 活动内容 -->
            <div class="nav-content" v-show="isActive == 2">
              <keep-alive>
                <Content 
                  :disabled="disabled" 
                  ref="children" 
                  :resource="resource" 
                  :resourceDimes="resourceDimes" 
                  :ofoWorkId="ruleForm.ofoWorkId + ''" 
                  :ofoFlowWork="ofoFlowWork"
                  :resTypesList="resTypesList"
                  @getCbussDimeList="getCbussDimeList"
                  :isEdit="isEdit"/>
              </keep-alive>
            </div>
            <!-- 文档 -->
            <div class="nav-content" v-show="isActive == 3">
              <keep-alive>
                <Document :disabled="disabled" :documentsList="documentsList" :ofoFlowWork="ofoFlowWork" :ruleForm="ruleForm" />
              </keep-alive>
              
            </div>
            <!-- 凭证 -->
            <div class="nav-content" v-show="isActive == 4">
              <keep-alive>
                <Voucher :ojdtacctList="ojdtacctList"/>
              </keep-alive>
            </div>
            <!-- 附件 -->
            <div class="nav-content" v-if="isActive == 5">
              <keep-alive>
                <Annex :fileList="fileList" :disabled="disabled" />
              </keep-alive>
            </div>
            <!-- 审批 -->
            <div class="nav-content" v-if="isActive == 6">
              <keep-alive>
                <Approval :approvalList="approvalList" @refreshGetwork="refreshGetwork"/>
              </keep-alive>
            </div>
            <!-- 核销 -->
            <div class="nav-content" v-if="isActive == 7">
              <keep-alive>
                <Check :ofoWorkId="ruleForm.ofoWorkId" :workCheckData="workCheckData" :ofoFlowWork="ofoFlowWork" :documentstatus="documentstatus" @getWorkCheckList="getWorkCheckList" />
              </keep-alive>
            </div>
            <template v-if="!disabled">
              <!-- 单位 部门 -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="单位" prop="selforgId">
                    <el-select
                      v-model="ruleForm.selforgId"
                      placeholder="请选择单位"
                      style="width: 100%"
                      filterable
                    >
                      <el-option v-for="(item, index) in orgList" :key="index" :label="item.resourceName" :value="item.ofrResourceId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="部门" prop="selfowhId">
                    <el-select
                      v-model="ruleForm.selfowhId"
                      placeholder="请选择部门"
                      style="width: 100%"
                      @change="getDeptChange"
                      filterable
                    >
                      <el-option v-for="(item, index) in departmentList" :key="index" :label="item.name" :value="item.cbOwhId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 单据负责人 注释/操作人 -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="单据负责人">
                    <el-select
                      v-model="ruleForm.selfownId"
                      placeholder="请选择单据负责人"
                      style="width: 100%"
                      clearable
                      filterable
                    >
                      <el-option v-for="(item, index) in leaderList" :key="index" :label="item.ofrthingName" :value="item.ofrthingId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="注释">
                    <el-input type="textarea" v-model="ruleForm.remarks" style="width: 100%" clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
            <template v-if="disabled">
              <el-descriptions :column="3" :contentStyle="{'padding-right': '16px'}">
                <el-descriptions-item label="单位">{{ruleForm.selforgName}}</el-descriptions-item>
                <el-descriptions-item label="部门">{{ruleForm.selfowhName}}</el-descriptions-item>
                <el-descriptions-item label="单据负责人">{{ruleForm.selfownName}}</el-descriptions-item>
                <el-descriptions-item label="操作人">{{ruleForm.userName}}</el-descriptions-item>
                <el-descriptions-item label="注释">{{ruleForm.remarks}}</el-descriptions-item>
              </el-descriptions>
            </template>
          </el-form>
        </div>
      </div>
      
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" v-show="!disabled" :disabled="isDisabled">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 新增资源 -->
    <el-dialog
      :title="'新增' + ofoFlowDeal.restype1Name"
      :visible.sync="dialogResourceVisible"
      append-to-body class="res-dialog"
      top="59px" >
      <el-form
        :model="resourceForm"
        :rules="rulesReource"
        ref="resourceForm"
        class="demo-ruleForm"
        label-position="right"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="名称" prop="resourceName">
              <el-input
                v-model="resourceForm.resourceName"
                :placeholder="'请输入' + ofoFlowDeal.restype1Name + '名称'"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="描述" prop="remarks">
              <el-input
                v-model="resourceForm.remarks"
                placeholder="请输入描述"
                style="width:97%"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <Form :formList="resourceFormList" :gutter="20" :ruleForm="resourceForm"/>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogResourceVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitResourceForm('resourceForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新增内容 -->
    <el-dialog
      :title="'新增内容'"
      :visible.sync="dialogThingVisible"
      append-to-body class="res-dialog"
      top="59px" >
      <el-form
        :model="thingForm"
        :rules="rulesThing"
        ref="thingForm"
        class="demo-ruleForm"
        label-position="right"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input
                v-model="thingForm.name"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="描述" prop="remarks">
              <el-input
                v-model="thingForm.remarks"
                placeholder="请输入描述"
                style="width:97%"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <Form :formList="thingFormList" :gutter="20" :ruleForm="thingForm"/>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogThingVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitThingForm('thingForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分享给谁 -->
    <el-dialog title="分享人" :visible.sync="dialogShareVisible"
      :append-to-body="true" width="30%" top="59px" class="res-dialog" >
      <el-form :model="form" label-width="60px">
        <el-form-item label="分享人" >
          <el-row>
            <el-col :span="16">
              <el-select v-model="form.toUserIds" placeholder="请选择分享人" style="width:97%" filterable multiple @change="changeSelect" collapse-tags>
                <el-option-group
                  v-for="group in userList"
                  :key="group.ofrResourceId">
                  <div style="padding: 0 20px">
                    <el-checkbox v-model="group.checked" @change="selectGroupAll($event,group.ofrResourceId)">{{group.resourceName}}</el-checkbox>
                  </div>
                  <el-option
                    v-for="item in group.ofrThingList"
                    :key="item.ofrThingId"
                    :label="item.name"
                    :value="item.ofrThingId + ''">
                  </el-option>
                </el-option-group>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-button @click="clickGroupBtn">组管理</el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <el-table
        :data="shareList"
        style="width: 100%"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
        v-loading="loading">
        <el-table-column
          prop="toUserName"
          label="已分享">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="分享时间"
          width="160">
          <template slot-scope="scope">
            {{parseTime(scope.row.createTime)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="70">
          <template slot-scope="scope">
            {{scope.row.status == '1' ? '已读' : '未读'}}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="70">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-circle-close" @click.native.stop="handleCancel(scope.row, scope.$index)">取消</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogShareVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitShare">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 选择打印内容 -->
    <el-dialog
      :title="'选择打印内容'"
      :visible.sync="dialogPrintVisible"
      append-to-body class="res-dialog"
      top="59px"
      width="30%" >
      <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>

      <!-- <el-checkbox size="mini" :indeterminate="isIndeterminate"  v-model="checkAll" @change="handleCheckAllChange" style="padding:0px;margin-right:5px;">全选</el-checkbox> -->
      <!-- <div style="margin: 15px 0;"></div> -->
      <!-- <el-checkbox-group v-model="checkedPrint" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="(item, index) in printList" :key="index" :label="index">{{item.text}}</el-checkbox>
      </el-checkbox-group> -->
      <el-tree
        :data="printList"
        show-checkbox
        default-expand-all
        node-key="id"
        :props="{children: 'children', label: 'text'}"
        @check="handleCheck"
        ref="treePrint">
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPrintVisible = false">取 消</el-button>
        <el-button type="primary" v-print="print" @click="dialogPrintVisible = false">确 定</el-button>
        <!-- <el-button type="primary" @click="comfirmPrint">确 定</el-button> -->
      </span>
    </el-dialog>
    <div v-show="false">
      <div id="print">
      <!-- <div ref="printArea"> -->
        <h2 style="text-align:center;margin-bottom:10px">{{loginUser.orgName}}</h2>
        <h3 style="text-align:center;margin-bottom:10px">{{ofoFlowWork.name}}</h3>
        <el-descriptions :column="3" :contentStyle="{'padding-right': '16px','width': '33%'}">
          <el-descriptions-item label="名称">{{ruleForm.name}}</el-descriptions-item>
          <el-descriptions-item label="编号">{{ruleForm.code}}</el-descriptions-item>
          <el-descriptions-item label="活动时间">{{ruleForm.workDate}}</el-descriptions-item>
          <!-- 交易组件有甲方资源类型并且资源种类不为‘管理’ -->
          <el-descriptions-item :label="ofoFlowDeal.restype1Name" v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind != '6' && ofoFlowDeal.restype1Name">{{ruleForm.dealName}}</el-descriptions-item>
          <el-descriptions-item v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind != '6' && ofoFlowDeal.restype1Name">
            <div slot="label">
              <template v-if="ofoFlowWork.thingDimeType">
                <DictTag :options="dropDown.thingDimeType" :value="ofoFlowWork.thingDimeType" style="margin-right:3px"/>
              </template>
              <template v-if="!ofoFlowWork.thingDimeType">
                内容
              </template>
            </div>
            {{ruleForm.dealownName}}
          </el-descriptions-item>
          <!-- 交易组件有甲方资源类型并且资源种类为‘管理’ -->
          <el-descriptions-item :label="ofoFlowDeal.restype1Name" v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind == '6' && ofoFlowDeal.restype1Name">{{ruleForm.dealowhName}}</el-descriptions-item>
          <el-descriptions-item label="联系人" v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind == '6' && ofoFlowDeal.restype1Name">
            {{ruleForm.dealownName}}
          </el-descriptions-item>
          <!-- 打印甲方资源维度 -->
          <el-descriptions-item v-for="(item, index) in dimePrint" :key="index" :label="item.dimeName + '*'">
              <template v-if="item.editType != '8'">{{item.dimeText}}</template>
              <template v-if="item.editType == '8' && item.dimeText">
                <div>
                  <div v-for="(val, id) in JSON.parse(item.dimeText)" :key="id">
                    <img v-if="val.fileName.includes('png') || val.fileName.includes('jpg') || val.fileName.includes('pneg') || val.fileName.includes('jpeg')" :src="baseUrl + val.fileUrl" alt="" style="width:98%">
                    <div v-else>{{val.fileName}}</div>
                  </div>
                </div>
              </template>
              
            </el-descriptions-item>
        </el-descriptions>
        <!-- <el-descriptions :column="1" :contentStyle="{'padding-right': '16px'}">
          
        </el-descriptions> -->
        <!-- <div v-for="val in checkedPrint" :key="val" ></div> -->
        <!-- 明细 -->
        <template v-if="printCheckedList.includes('1')">
        <!-- <template v-if="isPrint.isPrint0"> -->
          <div style="font-size:18px">{{ofoFlowWork.thingtypeName + '（明细）'}}</div>
          <el-table
            :data="newThingsList"
            :header-cell-style="{background:'#F9F9FB',color:'#606266',borderColor:'#000',padding: '3px 0'}"
            :cell-style="{borderColor:'#000',padding: '3px 0'}"
            style="width:100%;border: solid 1px #000"
            key="table1"
            ref="printTable1"
            border
            row-key="ofoWorkThingId"
            class="print-table">
            <el-table-column
              type="index"
              label="行号"
              width="50">
            </el-table-column>
            <el-table-column
              prop="cbowhName"
              label="位置"
              type=""
              v-if="printCheckedList.includes('1-cbowhName') && ofoFlowWork.chooseOwh == '0'">
            </el-table-column>
            <el-table-column
              prop="ofrresourceName"
              :label="ofoFlowWork.kindtypeName"
              v-if="printCheckedList.includes('1-ofrresourceName')">
              <template slot-scope="scope">
                {{scope.row.ofrresourceName + ' - ' + scope.row.ofrthingName}}
              </template>
            </el-table-column>
            <template v-if="ofoFlowWork.costmanagetype == '8'">
              <el-table-column
                prop="beginValue"
                label="起始数量"
                v-if="printCheckedList.includes('1-beginValue')">
              </el-table-column>
              <el-table-column
                prop="endValue"
                label="终止数量"
                v-if="printCheckedList.includes('1-endValue')">
              </el-table-column>
              <el-table-column
                prop="quantity"
                label="数量"
                v-if="printCheckedList.includes('1-quantity')">
                <template slot-scope="scope">{{scope.row.quantity}}
                </template>
              </el-table-column>
            </template>
            <el-table-column
              prop="quantity"
              align="right"
              v-for="(item, index) in workConfig.workConfigList1"
              :key="item.ofoFlowWorkConfigId"
              :label="item.name"
              v-if="printCheckedList.includes('1-quantity' + item.ofoFlowWorkConfigId) && ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'">
              <template slot-scope="scope">
                <DictTag :options="JSON.parse(scope.row.workConfigList1[index].options)" :value="scope.row.workConfigList1[index].configValue" v-if="scope.row.workConfigList1[index].showType == '2'"/>
                <div v-else>
                  {{scope.row.workConfigList1[index].configValue}}
                  <span v-if="scope.row.workConfigList1[index].showType == '1' && scope.row.workConfigList1[index].configValue">%</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="quantity"
              label="数量"
              align="right"
              v-if="printCheckedList.includes('1-quantity') && ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'">
              <template slot-scope="scope">{{scope.row.quantity}}</template>
            </el-table-column>
            <el-table-column
              prop="quantityKz"
              label="(控制)数量"
              align="right"
              v-if="printCheckedList.includes('1-quantityKz') && limit.quantity && limit.quantity.limitRate">
              <template slot-scope="scope">
                {{scope.row.ofoFlowWorkLimitShow.quantity}}
              </template>
            </el-table-column>
            <el-table-column
              prop="quantityQz"
              label="(前置)数量"
              align="right"
              v-if="printCheckedList.includes('1-quantityQz') && limit.quantity">
              <template slot-scope="scope">
                {{scope.row.ofoFlowWorkLimitShow.preQuantity}}
              </template>
            </el-table-column>
            <template v-if="ofoFlowWork.costmanagetype == '7'">
              <el-table-column
                prop="beginValue"
                label="开始时间"
                align="center"
                v-if="printCheckedList.includes('1-beginValue')">
              </el-table-column>
              <el-table-column
                prop="endValue"
                label="结束时间"
                align="center"
                v-if="printCheckedList.includes('1-endValue')">
              </el-table-column>
              <el-table-column
                prop="quantity"
                label="时间期间"
                v-if="printCheckedList.includes('1-quantity')">
                <template slot-scope="scope">{{scope.row.quantity | filter_handleTimeRange}}
                </template>
              </el-table-column>
            </template>
            <template v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
              <el-table-column
                prop="price"
                align="right"
                v-for="(item, index) in workConfig.workConfigList2"
                :key="item.ofoFlowWorkConfigId"
                :label="item.name"
                v-if="printCheckedList.includes('1-price' + item.ofoFlowWorkConfigId)">
                <template slot-scope="scope">
                  <DictTag :options="JSON.parse(scope.row.workConfigList2[index].options)" :value="scope.row.workConfigList2[index].configValue" v-if="scope.row.workConfigList2[index].showType == '2'"/>
                  <div v-else>
                    {{scope.row.workConfigList2[index].configValue | comdify('no')}}
                    <span v-if="scope.row.workConfigList2[index].showType == '1' && scope.row.workConfigList2[index].configValue">%</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="price"
                label="单价（含税）"
                align="right"
                v-if="printCheckedList.includes('1-price')">
                <template slot-scope="scope">{{comdifyPrams(scope.row.price)}}</template>
              </el-table-column>
            </template>
            <el-table-column
              prop="priceKz"
              label="(控制)单价"
              align="right"
              v-if="printCheckedList.includes('1-priceKz') && limit.price && limit.price.limitRate">
              <template slot-scope="scope">
                {{scope.row.ofoFlowWorkLimitShow.price | comdify}}
              </template>
            </el-table-column>
            <el-table-column
              prop="priceQz"
              label="(前置)单价"
              align="right"
              v-if="printCheckedList.includes('1-priceQz') && limit.price">
              <template slot-scope="scope">
                {{scope.row.ofoFlowWorkLimitShow.prePrice | comdify}}
              </template>
            </el-table-column>
            <el-table-column
              prop="total"
              align="right"
              v-for="(item, index) in workConfig.workConfigList3"
              :key="item.ofoFlowWorkConfigId"
              :label="item.name"
              v-if="printCheckedList.includes('1-total' + item.ofoFlowWorkConfigId) && (ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '4' || ofoFlowWork.costmanagetype == '5')">
              <template slot-scope="scope">
                <DictTag :options="JSON.parse(scope.row.workConfigList3[index].options)" :value="scope.row.workConfigList3[index].configValue" v-if="scope.row.workConfigList3[index].showType == '2'"/>
                <div v-else>
                  {{scope.row.workConfigList3[index].configValue | comdify('no')}}
                  <span v-if="scope.row.workConfigList3[index].showType == '1' && scope.row.workConfigList3[index].configValue">%</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="total"
              label="金额（含税）"
              align="right"
              v-if="printCheckedList.includes('1-total') && (ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5')">
              <template slot-scope="scope">{{scope.row.total | comdify}}</template>
            </el-table-column>
            <el-table-column
              prop="lineTotalKz"
              label="(控制)金额"
              align="right"
              v-if="printCheckedList.includes('1-lineTotalKz') && limit.lineTotal && limit.price.limitRate">
              <template slot-scope="scope">
                {{scope.row.ofoFlowWorkLimitShow.lineTotal | comdify}}
              </template>
            </el-table-column>
            <el-table-column
              prop="lineTotalQz"
              label="(前置)金额"
              align="right"
              v-if="printCheckedList.includes('1-lineTotalQz') && limit.lineTotal">
              <template slot-scope="scope">
                {{scope.row.ofoFlowWorkLimitShow.preLineTotal | comdify}}
              </template>
            </el-table-column>
            <el-table-column
              prop="total"
              label="费用金额"
              align="right"
              v-if="printCheckedList.includes('1-total') && ofoFlowWork.costmanagetype == '4'">
              <template slot-scope="scope">{{scope.row.total | comdify}}</template>
            </el-table-column>
            <template v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
              <el-table-column
                prop="vatgroup"
                label="税率"
                align="center"
                v-if="printCheckedList.includes('1-vatgroup')">
                <template slot-scope="scope">
                  <DictTag :options="dropDown.invoiceVat" :value="scope.row.vatgroup"/>
                </template>
              </el-table-column>
              <el-table-column
                prop="totalVat"
                label="税额"
                align="right"
                v-if="printCheckedList.includes('1-totalVat')">
                <template slot-scope="scope">{{scope.row.totalVat | comdify}}</template>
              </el-table-column>
              <el-table-column
                prop="invoicetype"
                label="票种"
                align="center"
                v-if="printCheckedList.includes('1-invoicetype')">
                <template slot-scope="scope">
                  <DictTag :options="dropDown.invoiceType" :value="scope.row.invoicetype"/>
                </template>
              </el-table-column>
            </template>
            <template v-if="ofoFlowWork.costmanagetype == '2'">
              <el-table-column
                prop="cost"
                align="right"
                label="成本单价（含税）"
                v-if="printCheckedList.includes('1-cost')">
                <template slot-scope="scope">{{comdifyPrams(scope.row.cost)}}</template>
              </el-table-column>
              <el-table-column
                prop="costTotal"
                align="right"
                label="成本金额（含税）"
                v-if="printCheckedList.includes('1-costTotal')">
                <template slot-scope="scope">{{scope.row.costTotal | comdify}}</template>
              </el-table-column>
              <el-table-column
                prop="costTotalVat"
                label="成本税额"
                align="right"
                v-if="printCheckedList.includes('1-costTotalVat')">
                <template slot-scope="scope">{{scope.row.costTotalVat | comdify}}</template>
              </el-table-column>
            </template>
            <el-table-column
              prop="customize"
              align="center"
              v-for="(item, index) in workConfig.workConfigList4"
              :key="item.ofoFlowWorkConfigId"
              :label="item.name"
              v-if="printCheckedList.includes('1-customize' + item.ofoFlowWorkConfigId)">
              <template slot-scope="scope">
                {{scope.row.workConfigList4[index].configValue}}
              </template>
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              v-if="printCheckedList.includes('1-remark')">
            </el-table-column>
          </el-table>
          <!-- <table border="1" cellspacing="0" style="width:100%">
            <thead>
              <tr style="height: 50px;text-align:center">
                <td>行号</td>
                <td v-if="ofoFlowWork.chooseOwh == '0'">仓库</td>
                <td>{{ofoFlowWork.thingtypeName + '（明细）'}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '8'">起始数量</td>
                <td v-if="ofoFlowWork.costmanagetype == '8'">终止数量</td>
                <td v-if="ofoFlowWork.costmanagetype == '8'">数量</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="item in workConfig.workConfigList1" :key="item.ofoFlowWorkConfigId">{{item.name}}</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8'">数量</td>
                <td v-if="ofoFlowWork.costmanagetype == '7'">开始时间</td>
                <td v-if="ofoFlowWork.costmanagetype == '7'">结束时间</td>
                <td v-if="ofoFlowWork.costmanagetype == '7'">时间期间</td>
                <td v-if="ofoFlowWork.costmanagetype == '4'">费用金额</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="item in workConfig.workConfigList2" :key="item.ofoFlowWorkConfigId">{{item.name}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">单价（含税）</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="item in workConfig.workConfigList3" :key="item.ofoFlowWorkConfigId">{{item.name}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">金额（含税）</td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">税率</td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">税额</td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">票种</td>
                <td v-if="ofoFlowWork.costmanagetype == '2'">成本单价（含税）</td>
                <td v-if="ofoFlowWork.costmanagetype == '2'">成本金额（含税）</td>
                <td v-if="ofoFlowWork.costmanagetype == '2'">成本税额</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="item in workConfig.workConfigList4" :key="item.ofoFlowWorkConfigId">{{item.name}}</td>
                <td>备注</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in newThingsList" :key="item.ofoWorkResourceId" style="height:30px">
                <td style="text-align:center">{{index + 1}}</td>
                <td v-if="ofoFlowWork.chooseOwh == '0'">{{item.cbowhName}}</td>
                <td>{{item.ofrresourceName + ' - ' + item.ofrthingName}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '8'" style="text-align:right">{{item.beginValue}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '8'" style="text-align:right">{{item.endValue}}</td>
                <td  v-if="ofoFlowWork.costmanagetype == '8'" style="text-align:right">{{item.quantity}}</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="val in item.workConfigList1" :key="val.ofoFlowWorkConfigId">
                  <DictTag :options="JSON.parse(val.options)" :value="val.configValue" v-if="val.showType == '2'"/>
                  <div v-else>
                    {{val.configValue}}
                    <span v-if="val.showType == '1' && val.configValue">%</span>
                  </div>
                </td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8'" style="text-align:right">{{item.quantity}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '7'">{{item.beginValue}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '7'">{{item.endValue}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '7'">{{item.quantity | filter_handleTimeRange}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '4'" style="text-align:right">{{item.total | comdify}}</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="val in item.workConfigList2" :key="val.ofoFlowWorkConfigId">
                  <DictTag :options="JSON.parse(val.options)" :value="val.configValue" v-if="val.showType == '2'"/>
                  <div v-else>
                    {{val.configValue}}
                    <span v-if="val.showType == '1' && val.configValue">%</span>
                  </div>
                </td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'" style="text-align:right">{{item.price | comdify}}</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="val in item.workConfigList3" :key="val.ofoFlowWorkConfigId">
                  <DictTag :options="JSON.parse(val.options)" :value="val.configValue" v-if="val.showType == '2'"/>
                  <div v-else>
                    {{val.configValue}}
                    <span v-if="val.showType == '1' && val.configValue">%</span>
                  </div>
                </td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'" style="text-align:right">{{item.total | comdify}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'" style="text-align:center">
                  <DictTag :options="dropDown.invoiceVat" :value="item.vatgroup"/>
                </td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'" style="text-align:right">{{item.totalVat | comdify}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
                  <DictTag :options="dropDown.invoiceType" :value="item.invoicetype"/>
                </td>
                <td v-if="ofoFlowWork.costmanagetype == '2'" style="text-align:right">{{item.cost | comdify}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '2'" style="text-align:right">{{item.costTotal | comdify}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '2'" style="text-align:right">{{item.costTotalVat | comdify}}</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="val in item.workConfigList4" :key="val.ofoFlowWorkConfigId">
                  <DictTag :options="JSON.parse(val.options)" :value="val.configValue" v-if="val.showType == '2'"/>
                  <div v-else>
                    {{val.configValue}}
                    <span v-if="val.showType == '1' && val.configValue">%</span>
                  </div>
                </td>
                <td>{{item.remark}}</td>
              </tr>
            </tbody>
            
          </table> -->
          <table style="width: 100%" v-if="ofoFlowWork.costmanagetype == '4'">
            <tr style="height: 30px">
              <td style="width: 150px">合计</td>
              <td>金额：{{(thingsList.length ? ruleForm.ptotalNovat : 0) | comdify}}</td>
            </tr>
          </table>
          <table style="width: 100%" v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
            <tr style="height: 30px">
              <td style="width: 150px">合计</td>
              <td>金额：{{subTotal.ptotalNovat | comdify}}</td>
              <td>税额：{{subTotal.ptotalVat | comdify}}</td>
            </tr>
            <tr>
              <td style="width: 150px"></td>
              <td>价税合计: {{subTotal.ptotal | comdify}}</td>
            </tr>
          </table>
          <table style="width: 100%; margin-top:10px;border-top: 1px solid #eeeeee" v-if="ofoFlowWork.costmanagetype == '2'">
            <tr style="height: 30px">
              <td style="width: 150px">成本合计</td>
              <td>成本金额：{{subTotal.ctotalNovat | comdify}}</td>
              <td>成本税额：{{subTotal.ctotalVat | comdify}}</td>
            </tr>
            <tr>
              <td style="width: 150px"></td>
              <td>成本价税合计: {{subTotal.ctotal | comdify}}</td>
            </tr>
          </table>
        </template>
        <!-- 产品 -->
        <template v-if="printCheckedList.includes('2')">
          <div style="font-size:18px;">{{ofoFlowWork.kindtypeName}}</div>
          <el-table
            :data="newProductsList"
            :header-cell-style="{background:'#F9F9FB',color:'#606266',borderColor:'#000',padding: '3px 0'}"
            :cell-style="{borderColor:'#000',padding: '3px 0'}"
            key="table2"
            ref="printTable2"
            border
            row-key="ofoWorkResourceId"
            default-expand-all
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            style="width:100%;border: solid 1px #000;overflow-x:hidden"
            class="print-table">
            <el-table-column
              type="index"
              label="行号"
              width="50">
            </el-table-column>
            <el-table-column
              prop="cbowhName"
              label="位置"
              type=""
              v-if="printCheckedList.includes('2-cbowhName') && ofoFlowWork.chooseOwh == '0'">
            </el-table-column>
            <el-table-column
              prop="ofrresourceName"
              :label="ofoFlowWork.kindtypeName"
              v-if="printCheckedList.includes('2-ofrresourceName')">
            </el-table-column>
            <template v-if="ofoFlowWork.costmanagetype == '8'">
              <el-table-column
                prop="beginValue"
                label="起始数量"
                v-if="printCheckedList.includes('2-beginValue')">
              </el-table-column>
              <el-table-column
                prop="endValue"
                label="终止数量"
                v-if="printCheckedList.includes('2-endValue')">
              </el-table-column>
              <el-table-column
                prop="quantity"
                label="数量"
                v-if="printCheckedList.includes('2-quantity')">
                <template slot-scope="scope">{{scope.row.quantity}}
                </template>
              </el-table-column>
            </template>
            <el-table-column
              prop="quantity"
              align="right"
              v-for="(item, index) in workConfig.workConfigList1"
              :key="item.ofoFlowWorkConfigId"
              :label="item.name"
              v-if="printCheckedList.includes('2-quantity' + item.ofoFlowWorkConfigId) && ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4' && !ofoFlowWork.thingtype">
              <template slot-scope="scope">
                <DictTag :options="JSON.parse(scope.row.workConfigList1[index].options)" :value="scope.row.workConfigList1[index].configValue" v-if="scope.row.workConfigList1[index].showType == '2'"/>
                <div v-else>
                  {{scope.row.workConfigList1[index].configValue}}
                  <span v-if="scope.row.workConfigList1[index].showType == '1' && scope.row.workConfigList1[index].configValue">%</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="quantity"
              label="数量"
              align="right"
              v-if="printCheckedList.includes('2-quantity') && ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'">
              <template slot-scope="scope">{{scope.row.quantity}}</template>
            </el-table-column>
            <el-table-column
              prop="quantityKz"
              label="(控制)数量"
              align="right"
              v-if="printCheckedList.includes('2-quantityKz') && limit.quantity && limit.quantity.limitRate && !ofoFlowWork.thingtype">
              <template slot-scope="scope">
                {{scope.row.ofoFlowWorkLimitShow.quantity}}
              </template>
            </el-table-column>
            <el-table-column
              prop="quantityQz"
              label="(前置)数量"
              align="right"
              v-if="printCheckedList.includes('2-quantityQz') && limit.quantity && !ofoFlowWork.thingtype">
              <template slot-scope="scope">
                {{scope.row.ofoFlowWorkLimitShow.preQuantity}}
              </template>
            </el-table-column>
            <template v-if="ofoFlowWork.costmanagetype == '7'">
              <el-table-column
                prop="beginValue"
                label="开始时间"
                align="center"
                v-if="printCheckedList.includes('2-beginValue')">
              </el-table-column>
              <el-table-column
                prop="endValue"
                label="结束时间"
                align="center"
                v-if="printCheckedList.includes('2-endValue')">
              </el-table-column>
              <el-table-column
                prop="quantity"
                label="时间期间"
                v-if="printCheckedList.includes('2-quantity')">
                <template slot-scope="scope">{{scope.row.quantity | filter_handleTimeRange}}
                </template>
              </el-table-column>
            </template>
            <template v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
              <el-table-column
                prop="price"
                align="right"
                v-for="(item, index) in workConfig.workConfigList2"
                :key="item.ofoFlowWorkConfigId"
                :label="item.name"
                v-if="printCheckedList.includes('2-price' + item.ofoFlowWorkConfigId) && !ofoFlowWork.thingtype">
                <template slot-scope="scope">
                  <DictTag :options="JSON.parse(scope.row.workConfigList2[index].options)" :value="scope.row.workConfigList2[index].configValue" v-if="scope.row.workConfigList2[index].showType == '2'"/>
                  <div v-else>
                    {{scope.row.workConfigList2[index].configValue | comdify('no')}}
                    <span v-if="scope.row.workConfigList2[index].showType == '1' && scope.row.workConfigList2[index].configValue">%</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="price"
                label="单价（含税）"
                align="right"
                v-if="printCheckedList.includes('2-price')">
                <template slot-scope="scope">{{comdifyPrams(scope.row.price)}}</template>
              </el-table-column>
            </template>
            <el-table-column
              prop="priceKz"
              label="(控制)单价"
              align="right"
              v-if="printCheckedList.includes('2-priceKz') && limit.price && limit.price.limitRate && !ofoFlowWork.thingtype">
              <template slot-scope="scope">
                {{scope.row.ofoFlowWorkLimitShow.price | comdify}}
              </template>
            </el-table-column>
            <el-table-column
              prop="priceQz"
              label="(前置)单价"
              align="right"
              v-if="printCheckedList.includes('2-priceQz') && limit.price && !ofoFlowWork.thingtype">
              <template slot-scope="scope">
                {{scope.row.ofoFlowWorkLimitShow.prePrice | comdify}}
              </template>
            </el-table-column>
            <el-table-column
              prop="total"
              align="right"
              v-for="(item, index) in workConfig.workConfigList3"
              :key="item.ofoFlowWorkConfigId"
              :label="item.name"
              v-if="printCheckedList.includes('2-total' + item.ofoFlowWorkConfigId) && (ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '4' || ofoFlowWork.costmanagetype == '5') && !ofoFlowWork.thingtype">
              <template slot-scope="scope">
                <DictTag :options="JSON.parse(scope.row.workConfigList3[index].options)" :value="scope.row.workConfigList3[index].configValue" v-if="scope.row.workConfigList3[index].showType == '2'"/>
                <div v-else>
                  {{scope.row.workConfigList3[index].configValue | comdify('no')}}
                  <span v-if="scope.row.workConfigList3[index].showType == '1' && scope.row.workConfigList3[index].configValue">%</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="total"
              label="金额（含税）"
              align="right"
              v-if="printCheckedList.includes('2-total') && (ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5')">
              <template slot-scope="scope">{{scope.row.total | comdify}}</template>
            </el-table-column>
            <el-table-column
              prop="lineTotalKz"
              label="(控制)金额"
              align="right"
              v-if="printCheckedList.includes('2-lineTotalKz') && limit.lineTotal && limit.price.limitRate && !ofoFlowWork.thingtype">
              <template slot-scope="scope">
                {{scope.row.ofoFlowWorkLimitShow.lineTotal | comdify}}
              </template>
            </el-table-column>
            <el-table-column
              prop="lineTotalQz"
              label="(前置)金额"
              align="right"
              v-if="printCheckedList.includes('2-lineTotalQz') && limit.lineTotal && !ofoFlowWork.thingtype">
              <template slot-scope="scope">
                {{scope.row.ofoFlowWorkLimitShow.preLineTotal | comdify}}
              </template>
            </el-table-column>
            <el-table-column
              prop="total"
              label="费用金额"
              align="right"
              v-if="printCheckedList.includes('2-total') && ofoFlowWork.costmanagetype == '4'">
              <template slot-scope="scope">{{scope.row.total | comdify}}</template>
            </el-table-column>
            <template v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
              <el-table-column
                prop="vatgroup"
                label="税率"
                align="center"
                v-if="printCheckedList.includes('2-vatgroup')">
                <template slot-scope="scope">
                  <DictTag :options="dropDown.invoiceVat" :value="scope.row.vatgroup"/>
                </template>
              </el-table-column>
              <el-table-column
                prop="totalVat"
                label="税额"
                align="right"
                v-if="printCheckedList.includes('2-totalVat')">
                <template slot-scope="scope">{{scope.row.totalVat | comdify}}</template>
              </el-table-column>
              <el-table-column
                prop="invoicetype"
                label="票种"
                align="center"
                v-if="printCheckedList.includes('2-invoicetype')">
                <template slot-scope="scope">
                  <DictTag :options="dropDown.invoiceType" :value="scope.row.invoicetype"/>
                </template>
              </el-table-column>
            </template>
            <template v-if="ofoFlowWork.costmanagetype == '2'">
              <el-table-column
                prop="cost"
                align="right"
                label="成本单价（含税）"
                v-if="printCheckedList.includes('2-cost')">
                <template slot-scope="scope">{{comdifyPrams(scope.row.cost)}}</template>
              </el-table-column>
              <el-table-column
                prop="costTotal"
                align="right"
                label="成本金额（含税）"
                v-if="printCheckedList.includes('2-costTotal')">
                <template slot-scope="scope">{{scope.row.costTotal | comdify}}</template>
              </el-table-column>
              <el-table-column
                prop="costTotalVat"
                label="成本税额"
                align="right"
                v-if="printCheckedList.includes('2-costTotalVat')">
                <template slot-scope="scope">{{scope.row.costTotalVat | comdify}}</template>
              </el-table-column>
            </template>
            <el-table-column
              prop="customize"
              align="center"
              v-for="(item, index) in workConfig.workConfigList4"
              :key="item.ofoFlowWorkConfigId"
              :label="item.name"
              v-if="printCheckedList.includes('2-customize' + item.ofoFlowWorkConfigId) && !ofoFlowWork.thingtype">
              <template slot-scope="scope">
                {{scope.row.workConfigList4[index].configValue}}
              </template>
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              v-if="printCheckedList.includes('2-remark')">
            </el-table-column>
          </el-table>
          <!-- <table border="1" cellspacing="0" style="width:100%">
            <thead>
              <tr style="height: 50px;text-align:center">
                <td>行号</td>
                <td v-if="ofoFlowWork.chooseOwh == '0'">仓库</td>
                <td>{{ofoFlowWork.kindtypeName}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '8'">起始数量</td>
                <td v-if="ofoFlowWork.costmanagetype == '8'">终止数量</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="item in workConfig.workConfigList1" :key="item.ofoFlowWorkConfigId">{{item.name}}</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8'">数量</td>
                <td v-if="ofoFlowWork.costmanagetype == '8'">数量</td>
                <td v-if="ofoFlowWork.costmanagetype == '7'">开始时间</td>
                <td v-if="ofoFlowWork.costmanagetype == '7'">结束时间</td>
                <td v-if="ofoFlowWork.costmanagetype == '7'">时间期间</td>
                <td v-if="ofoFlowWork.costmanagetype == '4'">费用金额</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="item in workConfig.workConfigList2" :key="item.ofoFlowWorkConfigId">{{item.name}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">单价（含税）</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="item in workConfig.workConfigList3" :key="item.ofoFlowWorkConfigId">{{item.name}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">金额（含税）</td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">税率</td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">税额</td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">票种</td>
                <td v-if="ofoFlowWork.costmanagetype == '2'">成本单价（含税）</td>
                <td v-if="ofoFlowWork.costmanagetype == '2'">成本金额（含税）</td>
                <td v-if="ofoFlowWork.costmanagetype == '2'">成本税额</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="item in workConfig.workConfigList4" :key="item.ofoFlowWorkConfigId">{{item.name}}</td>
                <td>备注</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in newProductsList" :key="item.ofoWorkResourceId" style="height:30px">
                <td style="text-align:center">{{index + 1}}</td>
                <td v-if="ofoFlowWork.chooseOwh == '0'">{{item.cbowhName}}</td>
                <td>{{item.ofrresourceName}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '8'" style="text-align:right">{{item.beginValue}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '8'" style="text-align:right">{{item.endValue}}</td>
                <td  v-if="ofoFlowWork.costmanagetype == '8'" style="text-align:right">{{item.quantity}}</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="val in item.workConfigList1" :key="val.ofoFlowWorkConfigId">
                  <DictTag :options="JSON.parse(val.options)" :value="val.configValue" v-if="val.showType == '2'"/>
                  <div v-else>
                    {{val.configValue}}
                    <span v-if="val.showType == '1' && val.configValue">%</span>
                  </div>
                </td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8'" style="text-align:right">{{item.quantity}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '7'">{{item.beginValue}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '7'">{{item.endValue}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '7'">{{item.quantity | filter_handleTimeRange}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '4'" style="text-align:right">{{item.total | comdify}}</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="val in item.workConfigList2" :key="val.ofoFlowWorkConfigId">
                  <DictTag :options="JSON.parse(val.options)" :value="val.configValue" v-if="val.showType == '2'"/>
                  <div v-else>
                    {{val.configValue}}
                    <span v-if="val.showType == '1' && val.configValue">%</span>
                  </div>
                </td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'" style="text-align:right">{{item.price | comdify}}</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="val in item.workConfigList3" :key="val.ofoFlowWorkConfigId">
                  <DictTag :options="JSON.parse(val.options)" :value="val.configValue" v-if="val.showType == '2'"/>
                  <div v-else>
                    {{val.configValue}}
                    <span v-if="val.showType == '1' && val.configValue">%</span>
                  </div>
                </td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'" style="text-align:right">{{item.total | comdify}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'" style="text-align:center">
                  <DictTag :options="dropDown.invoiceVat" :value="item.vatgroup"/>
                </td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'" style="text-align:right">{{item.totalVat | comdify}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
                  <DictTag :options="dropDown.invoiceType" :value="item.invoicetype"/>
                </td>
                <td v-if="ofoFlowWork.costmanagetype == '2'" style="text-align:right">{{item.cost | comdify}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '2'" style="text-align:right">{{item.costTotal | comdify}}</td>
                <td v-if="ofoFlowWork.costmanagetype == '2'" style="text-align:right">{{item.costTotalVat | comdify}}</td>
                <td v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'" v-for="val in item.workConfigList4" :key="val.ofoFlowWorkConfigId">
                  <DictTag :options="JSON.parse(val.options)" :value="val.configValue" v-if="val.showType == '2'"/>
                  <div v-else>
                    {{val.configValue}}
                    <span v-if="val.showType == '1' && val.configValue">%</span>
                  </div>
                </td>
                <td>{{item.remark}}</td>
              </tr>
            </tbody>
            
          </table> -->
          <table style="width: 100%" v-if="ofoFlowWork.costmanagetype == '4'">
            <tr style="height: 30px">
              <td style="width: 150px">合计</td>
              <td>金额：{{ruleForm.ptotalNovat | comdify}}</td>
            </tr>
          </table>
          <table style="width: 100%" v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
            <tr style="height: 30px">
              <td style="width: 150px">合计</td>
              <td>金额：{{ruleForm.ptotalNovat | comdify}}</td>
              <td>税额：{{ruleForm.ptotalVat | comdify}}</td>
            </tr>
            <tr>
              <td style="width: 150px"></td>
              <td>价税合计: {{ruleForm.ptotal | comdify}}</td>
            </tr>
          </table>
          <table style="width: 100%; margin-top:10px;border-top: 1px solid #eeeeee" v-if="ofoFlowWork.costmanagetype == '2'">
            <tr style="height: 30px">
              <td style="width: 150px">成本合计</td>
              <td>成本金额：{{ruleForm.ctotalNovat | comdify}}</td>
              <td>成本税额：{{ruleForm.ctotalVat | comdify}}</td>
            </tr>
            <tr>
              <td style="width: 150px"></td>
              <td>成本价税合计: {{ruleForm.ctotal | comdify}}</td>
            </tr>
          </table>
        </template>
        <!-- 凭证 -->
        <template v-if="printCheckedList.includes('5')">
          <div style="font-size:18px;margin:16px 0">凭证</div>
          <table border="1" cellspacing="0" class="table">
            <tr class="tr-header">
              <td style="width:200px">摘要</td>
              <td style="width:200px">会计科目</td>
              <td>借方金额</td>
              <td>贷方金额</td>
            </tr>
            <tr class="tr-body" v-for="(item, index) in ojdtacctList" :key="index">
              <td>{{item.remarks}}</td>
              <td>{{item.acctName}}</td>
              <td style="text-align:right">{{item.debit | comdify('no')}}</td>
              <td style="text-align:right">{{item.credit | comdify('no')}}</td>
            </tr>
          </table>
        </template>
        <!-- 活动内容 -->
        <template v-if="printCheckedList.includes('3')">
          <div style="font-size:18px;margin:16px 0">{{ofoFlowWork.kindtypeName}}</div>
          <el-descriptions :column="3">
            <el-descriptions-item v-for="(item, index) in resourceDimes" :key="index" :label="item.dimeName + '*'">
              <template v-if="item.editType != '8'">{{item.dimeText}}</template>
              <template v-if="item.editType == '8' && item.dimeText">
                <div>
                  <div v-for="(val, id) in JSON.parse(item.dimeText)" :key="id">{{val.fileName}}</div>
                </div>
              </template>
              
            </el-descriptions-item>
          </el-descriptions>
        </template>
        <!-- 文档 -->
        <template v-if="printCheckedList.includes('4')">
          <div style="font-size:18px;margin:16px 0">{{ofoFlowWork.kinddocutypeName}}</div>
          <el-descriptions :column="3">
            <el-descriptions-item v-for="(item, index) in documentsList" :key="index" :label="item.dimeName + '*'">
              <template v-if="item.editType != '8'">{{item.dimeText}}</template>
              <template v-if="item.editType == '8' && item.dimeText">
                <div>
                  <div v-for="(val, id) in JSON.parse(item.dimeText)" :key="id">
                    <img v-if="val.fileName.includes('png') || val.fileName.includes('jpg') || val.fileName.includes('pneg') || val.fileName.includes('jpeg')" :src="baseUrl + val.fileUrl" alt="" style="width:98%">
                    <div v-else>{{val.fileName}}</div>
                  </div>
                </div>
              </template>
              
            </el-descriptions-item>
          </el-descriptions>
        </template>
        <!-- 附件 -->
        <template v-if="printCheckedList.includes('6')">
          <div style="font-size:18px;marginp:16px 0">附件</div>
          <table border="1" cellspacing="0" class="table">
            <thead>
              <tr class="tr-header">
                <td style="width:50px">行号</td>
                <td>附件类型</td>
                <td>上传要求及说明</td>
                <td>附件上传</td>
              </tr>
            </thead>
            <tbody>
              <tr class="tr-body" v-for="(item, index) in fileList" :key="index">
                <td>{{index+1}}</td>
                <td style="text-align:left">{{item.name}}</td>
                <td style="text-align:left">{{item.remarks}}</td>
                <td style="text-align:left">
                  <div v-for="(val, id) in item.fileList" :key="id">{{val.fileName}}</div>
                </td>
              </tr>
            </tbody>
            
          </table>
        </template>
        <!-- 审批 -->
        <template v-if="printCheckedList.includes('7')">
          <div style="margin:16px 0;font-size:18px">审批</div>
          <!-- <el-table
            class="print-table"
            border
            :data="approvalList"
            :header-cell-style="{background:'#F9F9FB',color:'#606266',borderColor:'#000',padding: '3px 0'}"
            :cell-style="{borderColor:'#000',padding: '3px 0'}"
            style="width:100%;border: solid 1px #000">
            <el-table-column
              label="序号"
              width="80"
              align="center">
              <template slot-scope="scope">
                {{scope.row.osappId}}-{{scope.row.order}}
              </template>
            </el-table-column>
            <el-table-column
              prop="ofrthingName"
              label="审批人"
              align="center"
              width="100">
            </el-table-column>
            <el-table-column
              prop="appstatus"
              label="审批状态"
              align="center"
              width="80">
              <template slot-scope="scope">
                <DictTag :options="dropDown.appStatus" :value="scope.row.appstatus" v-if="scope.row.steptype == '1'"/>
                <span v-if="scope.row.steptype == '2'">抄送</span>
              </template>
            </el-table-column>
            <el-table-column
              label="创建时间"
              align="center"
              width="100">
              <template slot-scope="scope">
                {{scope.row.createTime | filter_handleDateTime}}
              </template>
            </el-table-column>
            <el-table-column
              label="审批时间"
              align="center"
              width="100">
              <template slot-scope="scope">
                {{scope.row.updateTime | filter_handleDateTime}}
              </template>
            </el-table-column>
            <el-table-column
              prop="remarks"
              label="审批意见">
              <template slot-scope="scope">
                <div v-html="scope.row.remarks"></div>
              </template>
            </el-table-column>
          </el-table> -->
          <table border="1" cellspacing="0" class="table">
            <thead>
              <tr class="tr-header" style="">
                <td style="width:50px">序号</td>
                <td style="width:80px">审批人</td>
                <td style="width:80px">审批状态</td>
                <td style="width:100px">创建时间</td>
                <td style="width:100px">审批时间</td>
                <td style="width:80px">签名</td>
                <td>审批意见</td>
              </tr>
            </thead>
            <tbody>
              <tr class="tr-body" v-for="(item, index) in approvalList" :key="index">
                <td>{{item.osappId}}-{{item.order}}</td>
                <td>{{item.ofrthingName}}</td>
                <td>
                  <DictTag :options="dropDown.appStatus" :value="item.appstatus" v-if="item.steptype == '1'"/>
                  <span v-if="item.steptype == '2'">抄送</span>
                </td>
                <td>{{item.createTime | filter_handleDateTime}}</td>
                <td>{{item.updateTime | filter_handleDateTime}}</td>
                <td>
                  <img :src="$base_url + item.signUrl" alt="" style="width:30px;transform: rotate(-90deg)" />
                </td>
                <td style="text-align:left"><div v-html="item.remarks"></div></td>
              </tr>
            </tbody>
            
          </table>
        </template>
        <!-- 核销 -->
        <template v-if="printCheckedList.includes('8')">
          <div style="font-size:18px;margin:16px 0">核销</div>
          <table border="1" cellspacing="0" class="table">
            <thead>
              <tr class="tr-header">
                <td style="width:50px">行号</td>
                <td>活动</td>
                <td>核销活动</td>
                <td>核销金额</td>
              </tr>
            </thead>
            <tbody>
              <tr class="tr-body" v-for="(item, index) in workCheckData.list" :key="index">
                <td>{{index+1}}</td>
                <td>{{item.ofoworkName}}</td>
                <td>{{item.checkworkName}}</td>
                <td style="text-align:right">{{item.checkTotal | comdify}}</td>
              </tr>
            </tbody>
            
          </table>
          <el-descriptions :column="2" :contentStyle="{'padding-right': '16px'}">
            <el-descriptions-item label="未核销金额">{{workCheckData.openTotal | comdify}}</el-descriptions-item>
            <el-descriptions-item label="总计金额">{{workCheckData.ptotal | comdify}}</el-descriptions-item>
          </el-descriptions>
          <!-- <el-row style="margin-top:16px">
            <el-col :span="12">未核销金额</el-col>
            <el-col :span="12">{{workCheckData.openTotal | comdify}}</el-col>
          </el-row>
          <el-row style="margin:16px 0">
            <el-col :span="12">总计金额</el-col>
            <el-col :span="12">{{workCheckData.ptotal | comdify}}</el-col>
          </el-row> -->
        </template>
        
        <el-descriptions :column="3" style="margin-top:16px" :contentStyle="{'padding-right': '16px'}">
          <el-descriptions-item label="单位">{{ruleForm.selforgName}}</el-descriptions-item>
          <el-descriptions-item label="部门">{{ruleForm.selfowhName}}</el-descriptions-item>
          <el-descriptions-item label="单据负责人">{{ruleForm.selfownName}}</el-descriptions-item>
          <el-descriptions-item label="操作人">{{ruleForm.userName}}</el-descriptions-item>
          <el-descriptions-item label="注释">{{ruleForm.remarks}}</el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <el-dialog title="组管理" :visible.sync="dialogTableVisible" class="res-dialog">
      <div style="margin-bottom:16px">
        <el-button type="primary" plain size="mini" @click="handleAddGroup">新增</el-button>
        <!-- <el-button type="success" plain size="mini" :disabled="ids.length != 1" @click="handleEditGroup">修改</el-button>
        <el-button type="danger" plain size="mini" :disabled="ids.length != 1">删除</el-button> -->
      </div>
      
      <el-table ref="groupTable" :data="groupList" :header-cell-style="{background:'#F9F9FB',color:'#606266'}" @selection-change="handleSelect1" @row-click="handleRowClick">
        <el-table-column type="selection" width="50" align="center" />
        <el-table-column property="name" label="名称"></el-table-column>
        <el-table-column property="bussIdsName" label="人员"></el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button @click.native.stop="handleEditGroup(scope.row)" type="text" size="small">修改</el-button>
            <el-button @click.native.stop="handleDelGroup(scope.row)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">关 闭</el-button>
        <el-button type="primary" @click="confirmGroup" :disabled="ids.length != 1">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="组管理" :visible.sync="dialogGroupVisible" width="500px" class="res-dialog">
      <el-form :model="groupForm" :rules="groupRules" ref="groupForm" label-width="60px" class="demo-ruleForm">
        <el-form-item label="名称" prop="name">
          <el-input v-model="groupForm.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="人员" prop="bussIds">
          <el-select v-model="groupForm.bussIds" placeholder="请选择人员" style="width:100%" filterable multiple>
            <el-option-group
              v-for="group in userList"
              :key="group.ofrResourceId"
              :label="group.resourceName">
              <el-option
                v-for="item in group.ofrThingList"
                :key="item.ofrThingId"
                :label="item.name"
                :value="item.ofrThingId + ''">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogGroupVisible = false">关 闭</el-button>
        <el-button type="primary" @click="submitGroup('groupForm')" :loading="isLoading">提 交</el-button>
      </div>
    </el-dialog>
    <!-- 修改项目 -->
    <el-dialog
      :title="'修改项目'"
      :visible.sync="dialogItemVisible"
      append-to-body class="res-dialog"
      width="30%" >
      <el-select v-model="ofoitemId" placeholder="请选择项目" clearable filterable style="width:100%">
        <el-option
          v-for="item in allItemList"
          :key="item.ofoItemId"
          :label="item.name"
          :value="item.ofoItemId">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogItemVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEditItem" :disabled="ofoitemId ? false: true">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导入对话框 -->
    <el-dialog
      :title="`导入${ofoFlowWork.name}`"
      :visible.sync="dialogImportVisible"
      width="30%"
      append-to-body
      top="59px"
      class="res-dialog"
      >
      <el-upload
        class="upload-demo"
        :auto-upload="false"
        drag
        action="#"
        multiple
        accept=".xlsx, .xls"
        :show-file-list="false"
        :on-change="handleChange">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" @click="exportBtn">下载模板</el-link>
        </div>
      </el-upload>
      <el-progress v-if="progressFlag" :percentage="loadProgress"></el-progress>
      <el-checkbox v-model="done" style="margin-top:10px">是否正式</el-checkbox>
      <!-- <span style="margin-top:10px;color:red;display:inline-block">注：黄色字体表示选中多条资源</span> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogImportVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import Form from '@/components/Form.vue'
import {mapState} from 'vuex'
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
import DimeTag from '@/components/DimeTag.vue'
import SelectData from '@/components/SelectedData.vue'
// 产品及服务
import Product from '@/components/project/activity/product.vue'
// 明细
import Subsidiary from '@/components/project/activity/subsidiary.vue'
// 文档
import Document from '@/components/project/activity/document.vue'
// 活动内容
import Content from '@/components/project/activity/content.vue'
// 审批
import Approval from '@/components/project/activity/approval.vue'
// 凭证
import Voucher from '@/components/project/activity/voucher.vue'
// 附件
import Annex from '@/components/project/activity/annex.vue'
// 核销
import Check from '@/components/project/activity/check.vue'
import textRange from '@/utils/textRange.js'
import handleList from '@/utils/handleList'
import handleWorkConfigList from '@/utils/handleWorkConfigList'
  export default {
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown,
        toWork: (state) => state.toWork.jumpData
      }),
    },
    components: {
      Form,
      DictTag,
      DimeTag,
      Product,
      Approval,
      Content,
      Subsidiary,
      Voucher,
      Annex,
      Document,
      SelectData,
      Check
    },
    data() {
      let that = this
      return {
        // timer: null,
        workList: [], // 活动列表
        title: '', // 对话框标题
        dialogFormVisible: false, // 对话框
        isShowApproval: false, // 是否展示审批
        copyTo: '',
        ruleForm: {
          code: '',
          name: '',
          workDate: '',
          ifalert: '1',
          dealId: '',
          dealowhId: '',
          selforgId: '',
          selfowhId: '',
          dealownId: [],
          selfownId: '',
          dealownName: '',
          isNotice: '1',
          noticeUsers: '',
          alertUsers: '',
          noticeFlowWork: '',
          dataSourceConfig: []
        }, // 表单对象
        // 表单规则
        rules: {
          workDate: [{ required: true, message: "请选择日期", trigger: "change" }],
          // name: [{ required: true, message: "请输入名称", trigger: "change" }],
          dealId: [{ required: true, message: "请选择资源", trigger: "change" }],
          selforgId: [{ required: true, message: "请选择单位", trigger: "change" }],
          selfowhId: [{ required: true, message: "请选择部门", trigger: "change" }],
          dealowhId: [{ required: true, message: "请选择部门", trigger: "change" }],
          resourceName: [{ required: true, message: "请输入资源名称", trigger: "change" }]
        },
        disabled: false, //是否禁用表单
        // 资源类型
        resourceType: {},
        // 资源列表
        resourceList: [],
        // 资源类型内容列表
        thingList: [],
        isActive: 0,
        navList: [
          {
            icon: 'iconfont icon-lvzhou_mingxi',
            text: this.ofoFlowWork.thingtypeName + '（明细）',
            isShow: this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0',
          },
          {
            icon: 'iconfont icon-chanpin',
            text: this.ofoFlowWork.kindtypeName,
            isShow: this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0'
          },
          {
            icon: 'iconfont icon-demand',
            text: '活动内容',
            isShow: this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype == '0'
          },
          {
            icon: 'iconfont icon-wendang',
            text: '文档',
            isShow: this.ofoFlowWork.kinddocutype
          },
          {
            icon: 'iconfont icon-ziyuan-xianxing',
            text: '凭证',
            isShow: this.ofoFlowWork.ojdttype == '0',
          },
          {
            icon: 'iconfont icon-fujian',
            text: '附件',
            isShow: false
          },
          {
            icon: 'iconfont icon-tianshenpi',
            text: '审批',
            isShow: false
          },
          {
            icon: 'iconfont icon-hexiao',
            text: '核销',
            isShow: false
          },
        ],
        navArr: [],
        documentsList: [], // 文档列表
        // 单位列表
        orgList: [],
        // 部门列表
        departmentList: [],
        // 负责人列表
        leaderList: [],
        // 联系人列表
        contactList: [],
        // 提交表单时禁用确定按钮
        isDisabled: false,
        productsList: [], // 产品列表
        thingsList: [], // 明细列表
        list: [], // 产品列表
        list2: [], // 明细列表
        ptotalObj: {}, // 表格合计对象（产品）
        ptotalObj2: {}, // 表格合计对象（明细）
        ctotalObj: {}, // 表格合计对象（产品）
        ctotalObj2: {}, // 表格合计对象（明细）
        files: '', // 附件
        resource: {
          cbRestypeId: '',
          resourceName: '',
          remarks: '',
          dataSourceConfig: []
        }, // 资源对象
        resourceDimes: [], // 资源维度
        // addWork: false,
        workCopyToList: [],
        ofoFlowWorkId: '', // 活动流程id
        baseOfoWorkId: '', // 基础活动id
        // dialogNavFromVisible: false, // 活动导航对话框
        processList: [], // 流程列表
        isStartApp: '', // 发起审批状态
        approvalText: '', // 审批意见
        approvalPeople: [], // 审批人
        backText: '', // 撤回原因
        approvalStatus: '', // 审批状态
        isDisabledEdit: false, // 是否禁用编辑按钮
        isDisabledFormal: false, // 是否禁用正式按钮
        isDisabledVoid: false, // 是否禁用作废按钮
        isDisabledCopyFrom: false, // 是否禁用复制从按钮
        approvalList: [], // 审批列表
        ofoWorkId: this.$route.query.ofoWorkId,
        documentstatus: '',
        ojdtacctList: [], // 凭证列表
        loading: true,
        obussDimeList: [],
        obussDimeList2: [],
        fileList: [],
        isShowNav: false, // 是否展示活动导航
        isShowNavBtn: false,
        cbOwhData: [], // 仓库列表
        goodsList: [], // 产品列表
        dialogResourceVisible: false,
        resourceForm: {
          resourceName: '',
          remarks: '',
          documentstatus: '1',
          dataSourceConfig: []
        },
        rulesReource: {
          resourceName: [{ required: true, message: "请输入资源名称", trigger: "change" }]
        },
        resourceFormList: [],
        dialogThingVisible: false,
        thingForm: {
          name: '',
          remarks: '',
          documentstatus: '1',
          dataSourceConfig: []
        },
        rulesThing: {
          name: [{ required: true, message: "请输入内容名称", trigger: "change" }]
        },
        thingFormList: [],
        pageNum: 1,
        pageNum1: 1,
        pageNum2: 1,
        pageSize: 10,
        pageSize1: 10,
        pageSize2: 10,
        total: 0,
        total1: 0,
        total2: 0,
        dialogShareVisible: false,
        form: {
          toUserIds: []
        },
        userList: [],
        chooseList: [],
        isCollect: '',
        isShowCollect: false,
        shareList: [],
        postAndUserList: [],
        workCopyToList1: [],
        isShowBtn: true,
        isCopy: false,
        copyrestype1: '',
        copyrestype1Name: '',
        copyrestype1Kind: '',
        copyofoFlowDealName: '',
        copyofoFlowDeal: {},
        copyofoFlowWork: {},
        copyrestaurants: [],
        siftForm: {
          baseId: '',
          codename: '',
          dealId: '',
          dealowhId: ''
        },
        path: this.$route.path,
        isShowText: false,
        workCheckData: {},
        print: {
          id: 'print',
          popTitle: '',
          // beforeEntryIframe () {
          //   const cells = document.querySelectorAll('.cell')
          //   console.log(cells);
          //   console.log('----------------');
          //   const tableNode = document.querySelectorAll('.print-table .el-table__header, .print-table .el-table__body')
          //   console.log(tableNode);
          //   // console.log(tableNode);
          //   // el-table 打印不全的问题
          //   for (let j = 0; j < tableNode.length; j++) {
          //     const tableItem = tableNode[j]
          //     tableItem.style.width = '100%'
          //     const child = tableItem.childNodes
          //     for (let i = 0; i < child.length; i++) {
          //       const element = child[i]
          //       if (element.localName === 'colgroup') {
          //         element.innerHTML = ''
          //       }
          //     }
          //   }
          //   // el-table 格子里面打印超过格子的问题
          //   for (let i = 0; i < cells.length; i++) {
          //     const cell = cells[i]
          //     cell.style.width = '100%'
          //     cell.removeAttribute('style')
          //   }
          // }
        },
        newProductsList: [
          // {
          //   cbowhName: 'hhh',
          //   quantity: 10
          // }
        ],
        newThingsList: [],
        dialogPrintVisible: false,
        checkAll: false,
        isIndeterminate: false,
        checkedPrint: [],
        // cities: ['上海', '北京', '广州', '深圳'],
        
        isPrint: {
          isPrint0: false,
          isPrint1: false,
          isPrint2: false,
          isPrint3: false,
          isPrint4: false,
          isPrint5: false,
          isPrint6: false,
          isPrint7: false,
        },
        printList: [],
        resTypesList: [],
        isEdit: false,
        pickerBeginOption: {
          disabledDate: (time) => {
            return time.getTime() < Date.now()-1 * 24 * 60 * 60 * 1000
          }
        },
        sortParams: [
          {
            value1: false,
            sortValue: 'asc',
            prop: 'code'
          },
          {
            value1: false,
            sortValue: 'asc',
            prop: 'documentstatus'
          },
          {
            value1: false,
            sortValue: 'asc',
            prop: 'name'
          },
          {
            value1: false,
            sortValue: 'asc',
            prop: 'workDate'
          },
          {
            value1: false,
            sortValue: 'asc',
            prop: 'quantity'
          },
          {
            value1: false,
            sortValue: 'asc',
            prop: 'ptotal'
          }
        ],
        subTotal: {
          ctotal: 0,
          ctotalNovat: 0,
          ctotalVat: 0,
          ptotal: 0,
          ptotalNovat: 0,
          ptotalVat: 0,
        },
        workConfig: {},
        timer: '',
        dialogTableVisible: false,
        groupList: [],
        dialogGroupVisible: false,
        groupForm: {
          bussIds: [],
          name: ''
        },
        groupRules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'change' }
          ],
          bussIds: [
            { required: true, message: '请选择人员', trigger: 'change' }
          ],
        },
        isLoading: false,
        ids: [],
        isShowCard: false,
        screenWidth: null,
        currentSpan: 0,
        showList: [],
        limit: {},
        resLimit: 0,
        thingLimit: 0,
        resPreLimit: 0,
        thingPreLimit: 0,
        cfflowitemId: null,
        dialogItemVisible: false,
        allItemList: [],
        ofoitemId: '',
        dialogImportVisible: false,
        printCheckedList: [],
        done: false,
        loadProgress: 0, // 动态显示进度条
        progressFlag: false, // 关闭进度条
        proTimer: null,
        baseUrl: this.$base_url,
        loginUser: JSON.parse(window.sessionStorage.getItem('loginUser')),
        dimePrint: []
      }
    },
    props: {
      ofoFlowDeal: Object,
      ofoFlowWork: Object,
      ofoItemName: String,
      isShare: {
        type: String,
        default: '1'
      },
      restaurants: Array,
      resourceList1: Array,
      params: {
        type: Object,
        default: () => ({
          priceAccuracy: 2,
          quantityAccuracy: 8
        })
      }
    },
    async created () {
      // console.log(this.$route);
      // 发布下拉
      if(!this.dropDown.documentstatus) {
        this.$http.get(url.getDataList, {params: {type: 'buss_document_status' }}).then(res => {
          this.$store.commit('setDocumentstatus', res.data.data)
        })
      }
      this.getList()
      this.navList.forEach((item, index) => {
        if(item.isShow) {
          this.navArr.push(index)
        }
      })
      await this.getItemWorkConfig(this.ofoFlowWork)
      // 由审批中心跳过来的
      if(this.toWork.ofoWorkId) {
        this.dialogFormVisible = true
        this.title = (this.ofoItemName ? ((this.ofoItemName.length > 30 ? this.ofoItemName.substring(0,30) + '...' : this.ofoItemName) + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0') : '') + this.ofoFlowDeal.name + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0' + this.ofoFlowWork.name 
        this.isShowNavBtn = true
        this.isShowNav = true
        this.disabled = true
        if(this.$refs.ruleForm) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm.clearValidate();
          })
        }
        await this.getDetail({ofoWorkId: this.toWork.ofoWorkId})
        this.getWorkProcess(this.toWork.ofoWorkId)
        if(this.toWork.isApp) {
          this.isActive = 6
        }else {
          this.isShowNav = true
        }
        if(this.toWork.isFile) {
          this.isActive = 5
          this.getData()
        }
        if(this.toWork.turn) {
          this.isActive = this.navArr[0]
          this.getData()
        }
      }else {
        this.isActive = this.navArr[0]
      }
      if(this.toWork.isNotice) {
        this.dialogFormVisible = true
        this.title = (this.ofoItemName ? ((this.ofoItemName.length > 30 ? this.ofoItemName.substring(0,30) + '...' : this.ofoItemName) + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0') : '') + this.ofoFlowDeal.name + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0' + this.ofoFlowWork.name 
        await this.getData()
        this.isShowNavBtn = false
        this.isShowNav = false
        this.disabled = false
        this.isShowBtn = false
        this.navList[4].isShow = false
        this.navList[6].isShow = false
        this.navArr = []
        this.navList.forEach((item, index) => {
          if(item.isShow) {
            this.navArr.push(index)
          }
        })
        this.isActive = this.navArr[0]
        this.ruleForm = {
          code: '',
          name: '',
          workDate: '',
          ifalert: '1',
          dealId: '',
          dealowhId: '',
          selforgId: '',
          selfowhId: '',
          dealownId: [],
          selfownId: '',
          dealownName: ''
        }
        for(let key in this.ruleForm) {
          this.ruleForm[key] = this.toWork.work[key]
        }
        this.ruleForm.workDate = this.parseTime(this.ruleForm.workDate, '{y}-{m}-{d}')
        this.ofoFlowWorkId = this.ofoFlowWork.ofoFlowWorkId
        this.baseOfoWorkId = ''
        this.ruleForm.isNotice = '1'
        if(this.toWork.list) {
          let productsList = []
          // this.productsList = this.toWork.list
          this.productsList = handleList(this.toWork.list, this.ofoFlowDeal, this.toWork.oldOfoDealId, this.ofoFlowWork, this.$refs.product.cbOwhData, 'foWorkResourceId')
          this.productsList = handleWorkConfigList(this.ofoFlowWork.checkConfig, this.productsList, this.workConfig)
          // this.productsList.forEach((item,index) => {
          //   item.baseOfoWorkResourceId = item.ofoWorkResourceId
          //   item.ofoWorkResourceId = ''
          //   item.baseOfoWorkId = item.ofoworkId
          //   if(this.ofoFlowWork.chooseOwh == '0' && this.$refs.product.cbOwhData.length) {
          //     // console.log(222);
          //     item.cbowhId = this.$refs.product.cbOwhData[0].cbOwhId
          //     item.cbowhName = this.$refs.product.cbOwhData[0].name
          //   }else {
          //     item.cbowhId = ''
          //     item.cbowhName = ''
          //   }
          //   if(this.ofoFlowWork.costmanagetype == '4') {
          //     item.quantity = 1
          //     item.total = item.total.toFixed(2)
          //     item.totalVat = 0
          //     item.vatgroup = '0.00'
          //     item.price = item.total
          //     item.cost = 0
          //     item.costTotal = 0
          //     item.costTotalVat = 0
          //   }else {
          //     if(this.ofoFlowDeal.ofoDealId == this.toWork.oldOfoDealId) {
          //       // console.log(111);
          //       item.quantity = item.openqty
          //       // 金额
          //       item.total = item.opentotal ? item.opentotal.toFixed(2) : 0
          //     }else {
          //       // console.log(222);
          //       item.quantity = item.openqtyDeal
          //       // 金额
          //       item.total = item.opentotalDeal ? item.opentotalDeal.toFixed(2) : 0
          //     }
          //     // 单价
          //     item.price = (item.total / item.quantity).toFixed(2)
          //     // 税额
          //     item.totalVat = (item.total / (1 + Number(item.vatgroup)) * item.vatgroup).toFixed(2)
          //     // 成本金额
          //     item.costTotal = (item.cost * item.quantity).toFixed(2)
          //     // 成本税额
          //     item.costTotalVat = (item.costTotal / (1 + Number(item.vatgroup)) * item.vatgroup).toFixed(2)
              
          //   }
          //   productsList.push(item)
          // })
          // this.productsList = productsList
        }
        if(this.toWork.list2 && this.ofoFlowWork.thingtype) {
          let thingsList = []
          // this.thingsList = this.toWork.list2
          // this.thingsList = []
          this.thingsList = handleList(this.toWork.list2, this.ofoFlowDeal, this.toWork.oldOfoDealId, this.ofoFlowWork, this.$refs.subsidiary.warehouseList, 'foWorkThingId')

          this.thingsList = handleWorkConfigList(this.ofoFlowWork.checkConfig, this.thingsList, this.workConfig)
          // this.thingsList.forEach((item,index) => {
          //   item.baseOfoWorkThingId = item.ofoWorkThingId
          //   item.ofoWorkThingId = ''
          //   item.baseOfoWorkId = item.ofoworkId
          //   if(this.ofoFlowWork.chooseOwh == '0' && this.$refs.subsidiary.warehouseList.length) {
          //     // console.log(222);
          //     item.cbowhId = this.$refs.subsidiary.warehouseList[0].cbOwhId
          //     item.cbowhName = this.$refs.subsidiary.warehouseList[0].name
          //   }else {
          //     item.cbowhId = ''
          //     item.cbowhName = ''
          //   }
          //   if(this.ofoFlowWork.costmanagetype == '4') {
          //     item.quantity = 1
          //     item.total = item.total.toFixed(2)
          //     item.totalVat = 0
          //     item.vatgroup = '0.00'
          //     item.price = item.total
          //     item.cost = 0
          //     item.costTotal = 0
          //     item.costTotalVat = 0
          //   }else {
          //     if(this.ofoFlowDeal.ofoDealId == this.toWork.oldOfoDealId) {
          //       // console.log(111);
          //       item.quantity = item.openqty
          //       // 金额
          //       item.total = item.opentotal ? item.opentotal.toFixed(2) : 0
          //     }else {
          //       // console.log(222);
          //       item.quantity = item.openqtyDeal
          //       // 金额
          //       item.total = item.opentotalDeal ? item.opentotalDeal.toFixed(2) : 0
          //     }
          //     // 单价
          //     item.price = (item.total / item.quantity).toFixed(2)
          //     // 税额
          //     item.totalVat = (item.total / (1 + Number(item.vatgroup)) * item.vatgroup).toFixed(2)
          //     // 成本金额
          //     item.costTotal = (item.cost * item.quantity).toFixed(2)
          //     // 成本税额
          //     item.costTotalVat = (item.costTotal / (1 + Number(item.vatgroup)) * item.vatgroup).toFixed(2)
              
          //   }
          //   thingsList.push(item)
          // })
          // this.thingsList = thingsList
        }else {
          this.thingsList = []
        }
        this.getDeptChange(this.ruleForm.selfowhId, '1')
        if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Kind != '6' && this.ofoFlowDeal.restype1Name) {
          this.getResChange(this.ruleForm.dealId, '1')
        }else {
          this.getDealowhChange(this.ruleForm.dealowhId, '1')
        }
      }
      
    },
    mounted () {
      if(this.isShowCard) {
        this.screenWidth = document.body.clientWidth
        window.onresize = () => {
          return (() => {
            this.screenWidth = document.body.clientWidth
          })()
        }
      }
      
    },
    destroyed () {
      this.proTimer ? clearInterval(this.proTimer) : ''
    },
    methods: {
      // 添加千分位
      comdifyPrams(n) {
        if(!n) return '';
        // console.log(this.params.priceAccuracy);
        n = Number(n).toFixed(this.params.priceAccuracy)
        // console.log(n);
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        // console.log(`${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`);
        return `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
      },
      // 活动导入
      handleImport() {
        this.loadProgress = 0
        this.progressFlag = false
        this.dialogImportVisible = true
      },
      // 下载模板
      exportBtn() {
        // this.$http.get(url.importTemplate + this.ofoFlowWork.ofoFlowWorkId).then(res => {
        //   console.log(res);
        //   console.log(this.$base_url);
        //   if(res.data.code == 200) {
        //     // window.open(this.$base_url + res.data.msg, '_self')
        //   }
         
        // })
        this.$http({
          url: url.importTemplate + this.ofoFlowWork.ofoFlowWorkId,
          method: 'get',
          responseType: 'blob',
          headers: {            //注意
            'Content-Type': 'application/json; application/octet-stream'
          }
        }).then(res => {
          // console.log(res);
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', `活动导入模板${new Date().getTime()}.xls`);
          document.body.appendChild(link);
          link.click();
        })
      },
      handleChange(file) {
        // const loading = this.$loading({
        //   lock: true,
        //   text: '导入中',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.5)'
        // });
        this.progressFlag = true
        const formData = new FormData()
        formData.append('file', file.raw)
        formData.append('ofoFlowWorkId', this.ofoFlowWork.ofoFlowWorkId)

        formData.append('done', this.done)
        let time = new Date().getTime() + Math.random()
        formData.append('key', time)
        this.getProgress(time)
        this.$http.post(url.importData,
          formData
        ).then(res => {
          // this.proTimer = null
          this.proTimer ? clearInterval(this.proTimer) : ''
          this.loadProgress = 100
          // this.isDisabled = false
          // this.isLoading = false
          if(res.data.code == 200) {
            this.$message.success(res.data.msg)
            this.dialogImportVisible = false
            this.getList()
          }
        }).catch(err => {
          this.proTimer ? clearInterval(this.proTimer) : ''
        })
      },
      getProgress(key) {
        this.proTimer = setInterval(() => {
          this.$http.get(url.progress + key).then(res => {
            // console.log(res);
            this.loadProgress = res.data.data
          })
        }, 2000);
        
      },
      // uploadVideoProcess(event, file, fileList) {
      //   console.log(111);
      //   console.log(event);
      //   this.progressFlag = true; // 显示进度条
      //   this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
      //   if (this.loadProgress >= 100) {
      //     this.loadProgress = 100
      //     setTimeout( () => {this.progressFlag = false}, 1000) // 一秒后关闭进度条
      //   }
      // },
      // 点击编辑项目按钮
      handleEditItem() {
        this.ofoitemId = ''
        this.dialogItemVisible = true
        this.$http.get(url.getItemList, {params: {cfFlowItemId: this.cfflowitemId, pageSize: 1000}}).then(res => {
          if(res.data.code == 200) {
            this.allItemList = res.data.data.list
          } 
        })
      },
      // 提交切换项目
      submitEditItem() {
        let obj = {
          ofoWorkId: this.ruleForm.ofoWorkId,
          targetItemId: this.ofoitemId
        }
        this.$http.post(url.changeWorkItem, obj).then(res => {
          if(res.data.code == 200) {
            // this.allItemList = res.data.data.list
            this.$message.success('切换成功');
            this.dialogItemVisible = false
            this.dialogFormVisible = false
            this.getList()
          } 
        })
        
      },
      // 切换展示形式
      changeShow() {
        this.isShowCard = !this.isShowCard
      },
      // 点击组管理按钮
      clickGroupBtn() {
        this.dialogTableVisible = true
        this.getGroupList()
      },
      // 获取组列表
      getGroupList() {
        this.$http.get(url.getGroupList, {params: {
          groupType: 's',
          pageSize: 1000
        }}).then(res => {
          this.groupList = res.data.data.list
          this.groupList.forEach(item1 => {
            item1.bussIds = item1.bussIds.split(',')
            item1.bussIdsName = []
            this.userList.forEach(item => {
              if(item.ofrThingList.length) {
                item.ofrThingList.forEach(val => {
                  item1.bussIds.forEach(el => {
                    if(el == val.ofrThingId) {
                      item1.bussIdsName.push(val.name)
                    }
                  })
                })
              }
            })
            item1.bussIdsName = item1.bussIdsName.toString()
          })
        })
      },
      handleSelect1(rows) {
        // this.ids = rows.map(item => item.id)
        this.ids = rows
      },
      //点击行选中或取消复选框
      handleRowClick(row, column, event){
        //通过ref绑定后这里使用$refs.table来操作bom元素
        this.$refs.groupTable.toggleRowSelection(row);
      },
      // 确定组
      confirmGroup() {
        this.form.toUserIds = this.ids[0].bussIds
        this.changeSelect(this.form.toUserIds)
        this.dialogTableVisible = false
      },
      // 新增组按钮
      handleAddGroup() {
        this.groupForm.bussIds = []
        this.groupForm.name = ''
        this.groupForm.id = ''
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.groupForm.clearValidate();
        })
        // this.$refs['groupForm'].resetFields();
        this.dialogGroupVisible = true
      },
      // 修改组
      handleEditGroup(row) {
        this.groupForm = Object.assign({}, row)
        // this.groupForm.bussIds = this.groupForm.bussIds.split(',')
        this.dialogGroupVisible = true
      },
      // 删除组
      handleDelGroup(row) {
        let id = row.id || this.ids.toString()
        this.$confirm('此操作将永久删除该组, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.removeGroup, qs.stringify({id})).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getGroupList()
            }
          })
          
        }).catch(() => {        
        });
      },
      // 新增/修改组
      submitGroup(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            let obj = Object.assign({}, this.groupForm)
            obj.bussIds = obj.bussIds.toString()
            obj.groupType = 's'
            console.log(obj);
            if(!obj.id) {
              this.$http.post(url.addGroup, qs.stringify(obj)).then(res => {
                // console.log(res);
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogGroupVisible = false;
                  this.getGroupList()
                }
              })
            }else {
              this.$http.post(url.editGroup, qs.stringify(obj)).then(res => {
                // console.log(res);
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogGroupVisible = false;
                  this.getGroupList()
                }
              })
            }
          } else {
            return false;
          }
        });
      },
      selectGroupAll(val, id){
        const arr = this.userList.find(f => f.ofrResourceId === id).ofrThingList.map(m => m.ofrThingId)
        if(val){
          arr.forEach(item => {
            if(!this.form.toUserIds.includes(item)){
              this.form.toUserIds.push(item + '')
            }
          })
        }else {
          this.form.toUserIds.forEach((item,index)=>{
            if(arr.includes(item)){
              this.form.toUserIds.splice(index,1,'')
            }
          })
        }
        this.form.toUserIds = this.form.toUserIds.filter(f => f != '')
      },
      changeSelect(val){
        this.userList.forEach(item => {
          const arr = item.ofrThingList.map(m => m.ofrThingId)
          item.checked = arr.every((v) => {
            return val.some(s => s == v)
          })
        })
      },
      async getItemWorkConfig(ofoFlowWork) {
        // 获取资源行配置
        if(ofoFlowWork.checkConfig) {
         await this.$http.get(url.getItemWorkConfig, {params: {
            ofoflowworkId: ofoFlowWork.ofoFlowWorkId
          }}).then(res => {
            if(res.data.code == 200) {
              this.workConfig = res.data.data
            }
          })
        }else {
          this.workConfig = {}
        }
      },
      handleSort(value, index) {
        if(value == 'asc') {
          this.sortParams[index].sortValue = 'desc'
        }else {
          this.sortParams[index].sortValue = 'asc'
        }
        
      },
      commitSort() {
        this.inquire()
      },
      resetSort() {
        this.sortParams.forEach(item => {
          item.value1 = false
          item.sortValue = 'asc'
        })
        this.inquire()
      },
      handleCheckAllChange(val) {
        // console.log(val);
        if(val) {
          //  获取根节点
          let rootNode = this.$refs.treePrint.getNode(this.printList[0].id).parent;
          var arr = []
          travelNodes(rootNode);
          // console.log(arr);
          this.handleCheck({}, {checkedKeys: arr.filter(item => item)})
          function travelNodes(tmpRoot) {
            // 选中该节点
            tmpRoot.checked = true;
            // console.log(tmpRoot);
            arr.push(tmpRoot.data.id)
            // 叶子节点
            if (tmpRoot.childNodes.length === 0) {
              return;
            }
            // 不是叶子节点,递归遍历
            else {
              let tmpChildNoes = tmpRoot.childNodes;
              for (let i = 0; i < tmpChildNoes.length; i++) {
                travelNodes(tmpChildNoes[i]);
              }
            }
          }
        }else {
          this.$refs.treePrint.setCheckedKeys([]);
          this.handleCheck({}, {checkedKeys: []})
        }
        // if(val) {
        //   this.checkedPrint = this.navArr;
        //   this.checkedPrint.forEach(item => {
        //     this['isPrint' + item] = true
        //   })
        // }else {
        //   this.checkedPrint = []
        //   for(let i = 0; i < 8; i ++) {
        //     this['isPrint' + i] = false
        //   }
        // }
        // this.checkedPrint = val ? this.navArr : [];
        this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        // console.log(value);
        // for(let i = 0; i < 8; i ++) {
        //   this.isPrint['isPrint' + i] = false
        // }
        // value.forEach(item => {
        //   this.isPrint['isPrint' + item] = true
        // })
        // this.checkAll = checkedCount === this.navArr.length;
        // this.isIndeterminate = checkedCount > 0 && checkedCount < this.navArr.length;
      },
      // 点击打印按钮
      clickPrint() {
        this.checkedPrint = []
        this.isIndeterminate = false
        this.checkAll = false
        for(let i = 0; i < 8; i ++) {
          this.isPrint['isPrint' + i] = false
        }
        this.navList.forEach((item, index) => {
          item.id = String(index + 1)
        })
        this.printCheckedList = []
        this.printList = JSON.parse(JSON.stringify(this.navList)).filter(item => item.isShow)
        this.printList.forEach(item => {
          if(item.icon == "iconfont icon-lvzhou_mingxi") {
            item.children = []
            this.$refs.subsidiary.$refs.noEditTable.$children.forEach((val, id) => {
              if(val.prop != undefined) {
                item.children.push({
                  text: val.label,
                  id: item.id + '-' + (id + 1),
                  prop: item.id + '-' + val.prop
                })
              }
            })
          }else if(item.icon == "iconfont icon-chanpin") {
            item.children = []
            this.$refs.product.$refs.noEditTable.$children.forEach((val, id) => {
              if(val.prop != undefined) {
                item.children.push({
                  text: val.label,
                  id: item.id + '-' + (id + 1),
                  prop: item.id + '-' + val.prop
                })
              }
            })
          }
        })
        // console.log(JSON.parse(JSON.stringify(this.printList)));
        this.dialogPrintVisible = true
        this.getWorkResList({ofoWorkId: this.ruleForm.ofoWorkId, pageNum: 1,pageSize: 2000, isPrint: true})
        this.getWorkThingList({ofoWorkId: this.ruleForm.ofoWorkId, pageNum: 1,pageSize: 2000, isPrint: true})
      },
      // handleRowClickPrint(data, node) {
      //   node.checked = !(node.checked)
      // },
      comfirmPrint() {
        // console.log(this.$refs);
        // console.log(this.printCheckedList);
        this.$pluginName.print(this.$refs.printArea);
        // this.$pluginName.init();
      },
      handleCheck(node, data) {
        // console.log(data.checkedKeys);
        this.printCheckedList = []
        let arr = []
        function allData(list) {
          list.forEach(item => {
            arr.push(item.id)
            if(item.children) {
              allData(item.children)
            }
          })
        }
        allData(this.printList)
        this.isIndeterminate = data.checkedKeys.length ? (data.checkedKeys.length == arr.length ? false : arr.some(item => data.checkedKeys.includes(item))) : false
        this.checkAll = data.checkedKeys.length == arr.length ? true : false
        data.checkedKeys.forEach(item => {
          if(item.includes('-')) {
            let index = this.printList.findIndex(val => val.id == item.split('-')[0])
            this.printCheckedList.push(this.printList[index].children.find(val => val.id == item).prop)
          }else {
            this.printCheckedList.push(item)
          }
        })
        this.printCheckedList.some(item => item.includes('1-')) ? this.printCheckedList.push('1') : true
        this.printCheckedList.some(item => item.includes('2-')) ? this.printCheckedList.push('2') : true
        // console.log(this.printCheckedList);
      },
      format(percentage) {
        return percentage === 100 ? '' : `${percentage}%`;
      },
      isShowToltip (e) {
        const bool = textRange(e.target)
        this.isShowText = bool
      },
      hideTip () {
        // console.log(11);
        this.isShowText = false
      },
      // 切换供应商内容
      getDealownChange(e) {
        // console.log(e);
        // let index = this.thingList.findIndex(item => item.ofrThingId == e)
        // this.ruleForm.dealownName = this.thingList[index].name
      },
      // 提交分享
      submitShare() {
        this.$http.post(url.shareCollect, qs.stringify({
          bussType: 's',
          flowType: 'w',
          obussId: this.form.obussId,
          toUserIds: this.form.toUserIds.toString()
        })).then(res => {
          if(res.data.code == 200) {
            this.$message.success('分享成功')
            this.dialogShareVisible = false
          }
        })
      },
      // 取消分享
      handleCancel(row, index) {
        this.$confirm('此操作将取消分享?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.cancelShareCollect, qs.stringify({
            bussType: 's',
            flowType: 'w',
            obussId: row.obussId,
            toUserId: row.toUserId
          })).then(res => {
            if(res.data.code == 200) {
              this.$message.success('取消分享')
              this.shareList.splice(index, 1)
            }
          })
        }).catch(() => {
                  
        });
        
      },
      // 新增资源
      addResource() {
        this.resourceForm.resourceName = ''
        this.resourceForm.remarks = ''
        this.resourceForm.dataSourceConfig = []
        this.dialogResourceVisible = true
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.resourceForm.clearValidate();
        })
        // 获取资源维度列表
        this.$http.get(url.getCbussDimeList, {params: {cbussId: this.ofoFlowDeal.restype1, dimetype: '1'}}).then(res => {
          if(res.data.code == 200) {
            this.resourceFormList = this.handleFormList(res.data.data)
            this.resourceForm.dataSourceConfig = this.resourceFormList
          }
        })
      },
      // 提交资源
      submitResourceForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isDisabled = true
            let dimes = []
            if(this.resourceFormList.length) {
              dimes = this.handleDimes(this.resourceFormList)
            }
            delete this.resourceForm.dataSourceConfig
            this.$http.post(url.addOfrResource, qs.stringify({
              ...this.resourceForm,
              dimes: JSON.stringify(dimes),
              cbRestypeId: this.ofoFlowDeal.restype1
            })).then(res => {
              this.isDisabled = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg)
                this.dialogResourceVisible = false
                this.ruleForm.dealId = res.data.data
                this.$http.get(url.getResTypeAndList, {params:
                  {
                    cbRestypeId: this.ofoFlowDeal.restype1, 
                    thingDimeType: this.ofoFlowWork.thingDimeType
                  }
                }).then(res => {
                  this.resourceType = res.data.data.cbResourceType
                  this.resourceList = res.data.data.list
                  this.thingList = []
                  this.ruleForm.dealownId = []
                })
              }
            })
          }
        })
      },
      // 新增内容
      addThing() {
        this.thingForm.name = ''
        this.thingForm.remarks = ''
        this.thingForm.dataSourceConfig = []
        this.dialogThingVisible = true
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.thingForm.clearValidate();
        })
        this.$http.get(url.getCbussDimeList, {params: {cbussId: this.ofoFlowDeal.restype1, dimetype: '2', thingDimeType: this.ofoFlowWork.thingDimeType ? this.ofoFlowWork.thingDimeType : '9'}}).then(res => {
          this.thingFormList = this.handleFormList(res.data.data)
          this.thingForm.dataSourceConfig = this.thingFormList
        })
      },
      // 提交内容
      submitThingForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isDisabled = true
            let dimes = []
            if(this.thingFormList.length) {
              dimes = this.handleDimes(this.thingFormList)
            }
            delete this.thingForm.dataSourceConfig
            this.$http.post(url.addOfrThing, qs.stringify({
              ...this.thingForm,
              dimes: JSON.stringify(dimes),
              cbRestypeId: this.ofoFlowDeal.restype1,
              ofrResourceId: this.ruleForm.dealId,
              thingDimeType: this.ofoFlowWork.thingDimeType ? this.ofoFlowWork.thingDimeType : '9'
            })).then(res => {
              this.isDisabled = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg)
                this.dialogThingVisible = false
                this.ruleForm.dealownId.push(res.data.data)
                this.$http.get(url.getResTypeAndList, {params:
                  {
                    cbRestypeId: this.ofoFlowDeal.restype1, 
                    thingDimeType: this.ofoFlowWork.thingDimeType
                  }
                }).then(res => {
                  this.resourceType = res.data.data.cbResourceType
                  this.resourceList = res.data.data.list
                  this.resourceList.forEach(item => {
                    if(item.ofrResourceId == this.ruleForm.dealId) {
                      this.thingList = item.ofrThingList
                    }
                  })
                })
              }
            })
          }
        })
      },
      refreshGetwork(val) {
        if(val) {
          let item = {ofoWorkId: this.ruleForm.ofoWorkId}
          this.getDetail(item)
          this.getWorkProcess(item.ofoWorkId)
        }
      },
      // 获取审批人列表
      selectAppPeople() {
        // if(!this.userList.length) {
        //   // 获取关联人员列表
        //   this.$http.get(url.getPostAndUserList).then(res => {
        //     if(res.data.code == 200) {
        //       this.userList = res.data.data
        //       this.userList.forEach(item => {
        //         if(item.ofrThingList.length) {
        //           item.checked = false
        //         }else {
        //           item.checked = true
        //         }
        //       })
        //     }
        //   })
        // }
      },
      // 提交审批意见
      submitAppRemarks() {
        if(this.$refs.popover) {
          this.$refs.popover.showPopper = false
        }
        this.$http.post(url.startOsApp, qs.stringify({
          ofoWorkId: this.ruleForm.ofoWorkId,
          remarks: this.approvalText,
          sprs: this.approvalPeople.toString()
        })).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.approvalPeople = []
            this.approvalText = ''
            let item = {ofoWorkId: this.ruleForm.ofoWorkId}
            this.getDetail(item)
          }   
        })
      },
      // 提交撤回审批
      backOsApp() {
        this.$http.post(url.backOsApp, qs.stringify({ofoWorkId: this.ruleForm.ofoWorkId, remarks: this.backText})).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.backText = ''
            let item = {ofoWorkId: this.ruleForm.ofoWorkId}
            this.getDetail(item)
          }   
        })
      },
      // 查看活动导航
      handleProcess(item) {
        // console.log(item);
        // clearTimeout(this.timer);   //清除定时器，然后在后面直接写双击事件的逻辑
        // this.dialogNavFromVisible = true
        // this.$http.get(url.getWorkProcess, {params: {ofoWorkId: item.ofoWorkId}}).then(res => {
        //   // console.log(res);
        //   this.processList = res.data.data
        // })
      },
      // 查看活动详情
      async handleView(item) {
        // this.isActive = this.navArr[0]
        //单击事件代码
        // this.ruleForm.
        this.pageSize1 = this.pageSize2 = 10
        this.pageNum1 = this.pageNum2 = 1
        this.isShowNavBtn = true
        this.isShowBtn = true
        this.isShowNav = true
        this.isCopy = false
        for(let key in this.ruleForm) {
          this.ruleForm[key] = ''
        }
        this.ruleForm.dataSourceConfig = []
        this.productsList = []
        this.thingsList = []
        // this.resource = {}
        for(let key in this.resource) {
          this.resource[key] = ''
        }
        this.approvalList = [] // 审批列表
        this.processList = [] // 导航流程列表
        this.documentsList = [] // 文档列表
        this.fileList = [] // 附件列表
        this.ojdtacctList = [] // 凭证列表
        this.approvalText = ''
        this.dialogFormVisible = true
        this.disabled = true
        this.title = (this.ofoItemName ? ((this.ofoItemName.length > 30 ? this.ofoItemName.substring(0,30) + '...' : this.ofoItemName) + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0') : '') + this.ofoFlowDeal.name + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0' + this.ofoFlowWork.name 
        // console.log(item);
        this.documentstatus = item.documentstatus
        if(this.$refs.ruleForm) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm.clearValidate();
          })
        }
        this.navList[4].isShow = this.navList[5].isShow = this.navList[6].isShow = this.navList[7].isShow = false
        this.navList.some((item, index) => {
          if(item.isShow) {
            this.isActive = index
            return true
          }
        })
        this.getWorkProcess(item.ofoWorkId)
        // if(item.documentstatus == '0' && this.isShare == '1') {
          // this.getData1()
          await this.getData()
        // }
        this.getDetail(item)
        
        
        this.approvalText = ''
        this.copyTo = ''
        
      },
      getWorkProcess(ofoWorkId) {
        this.$http.get(url.getWorkProcess, {params: {ofoWorkId}}).then(res => {
          // console.log(res);
          this.processList = res.data.data
          this.processList.forEach(val => {
            val.forEach(el => {
              if(el.ofoWorkId == ofoWorkId) { 
                val.color = "#409eff"
                // val.color = "#666"
              }
            })
          })
        })
      },
      // 删除活动
      handleDel() {
        this.$confirm('此操作将删除该活动, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.removeWork, qs.stringify({
            ofoWorkId: this.ruleForm.ofoWorkId
          })).then(res => {
            if(res.data.code == 200) {
              this.dialogFormVisible = false
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.loading = true
              this.pageNum = 1
              this.getList()
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消'
          // }); 
        });
      },
      // 新增活动
      async handleAdd() {
        this.isShowCollect = false
        this.isShowNav = false
        this.isShowNavBtn = false
        this.isShowBtn = false
        this.isEdit = false
        this.timer = new Date().getTime()
        for(let key in this.ruleForm) {
          this.ruleForm[key] = ''
        }
        this.ruleForm.dataSourceConfig = []
        this.productsList = []
        this.thingsList = []
        // this.resource = {}
        for(let key in this.resource) {
          this.resource[key] = ''
        }
        this.list = []
        this.list2 = []
        this.approvalList = []
        this.approvalText = ''
        this.dialogFormVisible = true
        this.title = '新增活动'
        this.documentstatus = ''
        // 表单禁用关闭
        this.disabled = false
        this.getNavList()
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
        // 获取活动附件列表
        // if(!this.fileList.length && this.isShowBtn) {
          await this.$http.get(url.getOfoFlowWorkfileList, {params: {ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId}}).then(res => {
            // console.log(res);
            if(res.data.code == 200) {
              this.fileList = res.data.data
            }
          })
        // }
        if(this.fileList.length) {
          this.navList[5].isShow = true
        }else {
          this.navList[5].isShow = false
        }
        this.navArr = []
        this.navList.forEach((item, index) => {
          if(item.isShow) {
            this.navArr.push(index)
          }
        })
        this.isActive = this.navArr[0]
        // this.ruleForm.dataSourceConfig = []
        await this.getData()
        for(let key in this.ruleForm) {
          if(key == 'workDate') {
            this.ruleForm[key] = this.parseTime(Date.now(), '{y}-{m}-{d}')
          }else if(key == 'ifalert') {
            // 设置是否预警（否）
            this.ruleForm[key] = '1'
          }else if(key == 'isNotice') {
            // 设置是否协同（否）
            this.ruleForm[key] = '1'
          }else if(key == 'dealId') { // 资源
            if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Kind != '6' && this.ofoFlowDeal.restype1Name) {
              this.ruleForm.dealId = this.resourceList.length ? this.resourceList[0].ofrResourceId : ''
            }
            
          }else if(key == 'dealowhId') { 
            if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Kind == '6' && this.ofoFlowDeal.restype1Name) {
              this.ruleForm.dealowhId = this.departmentList.length ? this.departmentList[0].cbOwhId : ''
            }
            
          }else if(key == 'selforgId') { 
            this.orgList.forEach(item => {
              if(item.resourceName == JSON.parse(window.sessionStorage.getItem('loginUser')).orgName) {
                this.ruleForm.selforgId = item.ofrResourceId
              }
            })
            if(!this.ruleForm.selforgId) {
              this.ruleForm.selforgId = this.orgList.length ? this.orgList[0].ofrResourceId : ''
            }
            // this.orgList[0].ofrResourceId
          }else if(key == 'selfowhId') {
            // this.ruleForm.selfowhId = this.departmentList[0].cbOwhId
            this.ruleForm.selfowhId = JSON.parse(window.sessionStorage.getItem('loginUser')).deptId ? JSON.parse(window.sessionStorage.getItem('loginUser')).deptId : this.departmentList[0].cbOwhId
          }else if(key == 'name') {
            this.ruleForm[key] = this.ofoFlowWork.name
          }else if(key == 'dataSourceConfig') {
            // this.ruleForm[key] = []
          }else if(key == 'dealownId') {
            this.ruleForm[key] = []
          }else {
            this.ruleForm[key] = null
          }
        }
        if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Kind != '6' && this.ofoFlowDeal.restype1Name) {
          this.getResChange(this.ruleForm.dealId)
        }else {
          this.getDealowhChange(this.ruleForm.dealowhId)
        }
        // this.getResChange(this.ruleForm.dealId)
        this.getDeptChange(this.ruleForm.selfowhId)
        this.ruleForm.selfownId = JSON.parse(window.sessionStorage.getItem('loginUser')).bussId
        this.ofoFlowWorkId = this.ofoFlowWork.ofoFlowWorkId
        this.baseOfoWorkId = ''
        
      },
      // 复用活动
      handleReuse() {
        this.$confirm('此操作将复用该活动, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.ruleForm.code = ''
          this.ruleForm.ofoWorkId = ''
          this.ruleForm.workDate = this.parseTime(Date.now(), '{y}-{m}-{d}')
          this.ofoFlowWorkId = this.ofoFlowWork.ofoFlowWorkId
          this.submitForm('ruleForm')
        }).catch(() => {         
        });
        
      },
      // 编辑活动
      handleEditBtn() {
        this.isShowNavBtn = false
        this.isShowNav = false
        this.isShowBtn = false
        this.isEdit = true
        // this.documentstatus = ''
        this.title = (this.ofoItemName ? ((this.ofoItemName.length > 30 ? this.ofoItemName.substring(0,30) + '...' : this.ofoItemName) + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0') : '') + this.ofoFlowDeal.name + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0' + this.ofoFlowWork.name 
        this.disabled = false
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
        if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Kind != '6' && this.ofoFlowDeal.restype1Name) {
          this.getResChange(this.ruleForm.dealId, '1')
        }else {
          this.getDealowhChange(this.ruleForm.dealowhId, '1')
        }
        this.getDeptChange(this.ruleForm.selfowhId, '1')
        this.navList[6].isShow = false
        this.navList[4].isShow = false
        this.navList[7].isShow = false
        this.navArr = []
        this.navList.forEach((item, index) => {
          if(item.isShow) {
            this.navArr.push(index)
          }
        })
        if(this.isActive == 2 || this.isActive == 6 || this.isActive == 7) {
          this.isActive = this.navArr[0]
        }
        this.getWorkResList({ofoWorkId: this.ruleForm.ofoWorkId, pageNum: 1, pageSize: 2000})
        this.getWorkThingList({ofoWorkId: this.ruleForm.ofoWorkId, pageNum: 1, pageSize: 2000})
        // this.isActive = this.navArr[0]
      },
      // 将活动状态修改为正式
      handleFormal() {
        this.$confirm('此操作将活动状态修改为正式, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.editWorkStatus, qs.stringify({
            ofoWorkId: this.ruleForm.ofoWorkId,
            documentstatus: '1'
          })).then(res => {
            if(res.data.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.documentstatus = '1'
              let item = {ofoWorkId: this.ruleForm.ofoWorkId}
              this.getDetail(item)
              // this.pageNum = 1
              this.getList()
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消修改'
          // });          
        });
      },
      // 将活动状态修改为作废
      handleVoid() {
        this.$confirm('此操作将活动状态修改为作废, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.editWorkStatus, qs.stringify({
            ofoWorkId: this.ruleForm.ofoWorkId,
            documentstatus: '3'
          })).then(res => {
            if(res.data.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.documentstatus = '3'
              // this.pageNum = 1
              this.getList()
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消修改'
          // });          
        });
      },
      // 将活动状态修改为结算
      handleSettlement() {
        this.$confirm('此操作将活动状态修改为结算, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.editWorkStatus, qs.stringify({
            ofoWorkId: this.ruleForm.ofoWorkId,
            documentstatus: '2'
          })).then(res => {
            if(res.data.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.documentstatus = '2'
              // this.pageNum = 1
              this.getList()
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消修改'
          // });          
        });
      },
      // 确认复制从列表
      copyFromConfirm(val) {
        this.ruleForm.code = this.ruleForm.code ? this.ruleForm.code : ((val.obj.code.indexOf('OFW') == 0 ) ? '' : val.obj.code)
        this.ruleForm.dealId = val.obj.dealId
        this.ruleForm.dealowhId = val.obj.dealowhId
        this.ruleForm.dealownId = val.obj.dealownId ? val.obj.dealownId.split(',').map(Number) : []
        this.ruleForm.selforgId = val.obj.selforgId
        if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Kind != '6' && this.ofoFlowDeal.restype1Name) {
          this.getResChange(this.ruleForm.dealId, '1')
        }else {
          this.getDealowhChange(this.ruleForm.dealowhId, '1')
        }
        this.getDeptChange(this.ruleForm.selfowhId, '1')
        // this.ruleForm.code = 
        val.productsList.forEach(item => {
          this.productsList.push(item)
          // 
        })
        val.thingsList.forEach(item => {
          this.thingsList.push(item)
        })
      },
      getNavList() {
        this.navList = [
          {
            icon: 'iconfont icon-lvzhou_mingxi',
            text: this.ofoFlowWork.thingtypeName + '（明细）',
            isShow: this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0',
          },
          {
            icon: 'iconfont icon-chanpin',
            text: this.ofoFlowWork.kindtypeName,
            isShow: this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0'
          },
          {
            icon: 'iconfont icon-demand',
            text: '活动内容',
            isShow: this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype == '0'
          },
          {
            icon: 'iconfont icon-wendang',
            text: '文档',
            isShow: this.ofoFlowWork.kinddocutype
          },
          {
            icon: 'iconfont icon-ziyuan-xianxing',
            text: '凭证',
            isShow: false,
          },
          {
            icon: 'iconfont icon-fujian',
            text: '附件',
            isShow: false
          },
          {
            icon: 'iconfont icon-tianshenpi',
            text: '审批',
            isShow: false
          },
          {
            icon: 'iconfont icon-hexiao',
            text: '核销',
            isShow: false
          },
        ]
      },
      // 复制到
      async copyToAdd() {
        // console.log(this.productsList);
        // let productsList = this.productsList
        this.isCopy = true 
        this.documentstatus = ''
        this.isShowNavBtn = false
        this.isShowNav = false
        this.isShowBtn = false
        this.disabled = false
        this.isShowCollect = false
        this.ruleForm.isNotice = '1'
        this.ruleForm.noticeUsers = ''
        this.ruleForm.alertUsers = ''
        this.ruleForm.noticeFlowWork = ''
        
        this.thingsList = []

        // this.copyrestype1 = this.restype1
        // this.copyrestype1Name = this.restype1Name
        // this.copyrestype1Kind = this.restype1Kind
        // this.copyofoFlowDealName = this.name
        this.copyofoFlowDeal = this.ofoFlowDeal
        this.copyofoFlowWork = this.ofoFlowWork
        // this.copyrestaurants = this.restaurants
        
        await this.$http.get(url.getFlowDealAndWork, {params: {ofoflowworkId: this.copyTo}}).then(res => {
          if(res.data.code == 200) {
            this.$emit('changeCopy', res.data.data.ofoFlowDeal, {...res.data.data.ofoFlowWork, copyTo: true})
            // this.ofoFlowDeal = res.data.data.ofoFlowDeal
            // this.ofoFlowWork = {...res.data.data.ofoFlowWork, copyTo: true}
          }
        })
        // await this.$http.get(url.getWorkCodeList, {params: {
        //   ofoflowworkId: this.copyTo
        // }}).then(res => {
        //   if(res.data.code == 200) {
        //     // this.restaurants = res.data.data
        //     this.restaurants = res.data.data.map(item => {
        //       return {
        //         value: `${item.name} - ${item.code}`,
        //         code: item.code
        //       }
        //     })
        //   }
        // })
        await this.getItemWorkConfig(this.ofoFlowWork)
        
        this.getWorkResList({ofoWorkId: this.ruleForm.ofoWorkId, pageNum: 1, pageSize: 2000})
        this.getWorkThingList({ofoWorkId: this.ruleForm.ofoWorkId, pageNum: 1, pageSize: 2000})
        this.ruleForm.name = this.ofoFlowWork.name
        this.ruleForm.code = (this.ruleForm.code.indexOf('OFW') == 0) ? '' : this.ruleForm.code
        this.ruleForm.workDate = this.parseTime(Date.now(), '{y}-{m}-{d}')
        this.ruleForm.selfowhId = JSON.parse(window.sessionStorage.getItem('loginUser')).deptId ? JSON.parse(window.sessionStorage.getItem('loginUser')).deptId : this.departmentList[0].cbOwhId
        this.getDeptChange(this.ruleForm.selfowhId)
        this.ruleForm.selfownId = JSON.parse(window.sessionStorage.getItem('loginUser')).bussId
        // console.log(this.ruleForm.code.indexOf('OFW'));
        this.getNavList()
        
        this.navArr = []
        this.navList.forEach((item, index) => {
          if(item.isShow) {
            this.navArr.push(index)
          }
        })
        this.isActive = this.navArr[0]
        this.title = (this.ofoItemName ? ((this.ofoItemName.length > 30 ? this.ofoItemName.substring(0,30) + '...' : this.ofoItemName) + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0') : '') + this.ofoFlowDeal.name + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0' + this.ofoFlowWork.name 
        await this.getData()
        // 获取活动附件列表
        await this.$http.get(url.getOfoFlowWorkfileList, {params: {ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId}}).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.fileList = res.data.data
          }
        })
        if(this.fileList.length) {
          this.navList[5].isShow = true
        }else {
          this.navList[5].isShow = false
        }
        // 资源
        this.resourceList.forEach(item => {
          if(item.ofrResourceId == this.ruleForm.dealId) {
            // console.log(item);
            if(item.ofrThingList.length) {
              this.thingList = item.ofrThingList
            }else {
              this.thingList = []
              // this.ruleForm.dealownId = ''
            }
          }
        })
        // 部门
        this.departmentList.forEach(item => {
          if(item.cbOwhId == this.ruleForm.selfowhId) {
            if(item.owhThingList) {
              this.leaderList = item.owhThingList
            }else {
              this.leaderList = []
            }
          }
        })
        await this.$refs.subsidiary.getList()
        // console.log(this.$refs);
        await this.$refs.product.getList()
        if(this.ofoFlowWork.thingtype) {
          // console.log(this.thingsList);
          this.thingsList = handleList(this.thingsList, this.ofoFlowDeal, this.copyofoFlowDeal.ofoDealId, this.ofoFlowWork, this.$refs.subsidiary.warehouseList, 'foWorkThingId')
        }else {
          this.thingsList = []
        }
        
        this.productsList = handleList(this.productsList, this.ofoFlowDeal, this.copyofoFlowDeal.ofoDealId, this.ofoFlowWork, this.$refs.product.cbOwhData, 'foWorkResourceId')
        
        this.baseOfoWorkId = this.ruleForm.ofoWorkId
        this.ruleForm.ofoWorkId = null
        this.ofoFlowWorkId = this.copyTo
        
        this.copyTo = ''
      },
      // 资源选择框发生改变时内容也改变
      getResChange(value, string) {
        // // this.thingList = []
        // this.ruleForm.dealownId = []
        this.resourceList.forEach(item => {
          if(item.ofrResourceId == value) {
            // console.log(item);
            if(item.ofrThingList.length) {
              this.thingList = item.ofrThingList
              // console.log(item.ofrThingList[0]);
              if(!string) {
                this.ruleForm.dealownId = []
                this.ruleForm.dealownId.push(item.ofrThingList[0].ofrThingId)
                // this.ruleForm.dealownName = item.ofrThingList[0].name
              }
            }else {
              this.thingList = []
              this.ruleForm.dealownId = []
              // this.ruleForm.dealownName = ''
            }
          }
        })
      },
      // 部门选择框发生改变时，负责人也发生改变
      getDeptChange(value, string) {
        this.departmentList.forEach(item => {
          if(item.cbOwhId == value) {
            if(item.owhThingList) {
              this.leaderList = item.owhThingList
              if(!string) {
                this.ruleForm.selfownId = item.owhThingList[0].ofrthingId
              }
            }else {
              this.leaderList = []
              this.ruleForm.selfownId = ''
            }
          }
        })
      },
      // 交易组件有甲方资源类型并且资源种类为‘管理’ 选择框发生改变时，联系人也发生改变
      getDealowhChange(value, string) {
        this.departmentList.forEach(item => {
          if(item.cbOwhId == value) {
            if(item.owhThingList) {
              this.contactList = item.owhThingList
              if(!string) {
                this.ruleForm.dealownId.push(item.owhThingList[0].ofrthingId)
              }
              // this.ruleForm.dealownId = this.ruleForm.dealownId ? this.ruleForm.dealownId : item.owhThingList[0].ofrthingId
            }else {
              this.contactList = []
              this.ruleForm.dealownId = []
            }
          }
        })
      },
      checked(index) {
        this.isActive = index
      },
      // 获取活动列表
      getList() {
        let list = []
        this.loading = true
        this.sortParams.forEach(item => {
          if(item.value1) {
            list.push(item)
          }
        })
        // console.log(this.ofoFlowWork.ofoflowworkId);
        this.$http.get(url.getWorkList, {params: 
          {
            ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId,
            ...this.siftForm,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            isShare: this.isShare,
            sortParams: list.length ? JSON.stringify(list) : ''
          },
          paramsSerializer: function (params) {  
            return qs.stringify(params, { arrayFormat: "repeat" }); 
          }
        }).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.workList = res.data.data.list
            this.showList = res.data.data.show
            this.total = res.data.data.total
            this.workList.forEach(item => {
              if(item.openTotal != null && item.ptotal) {
                item.percentage = Math.floor((Number((item.ptotal - item.openTotal) / item.ptotal) * 100))
              }else {
                item.percentage = 0
              }
              // console.log(item.percentage);
            })
          }
        })

      },
      // 提交表单
      submitForm(formName) {
        let location1 = true
        let location2 = true
        let res = true
        let fileRequired = true
        let resRequired = true
        // 活动为对象管理
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype == '0') {
          this.$refs.children.$refs.resource.validate((resValid, resErr) => {
            if(resValid) {
              resRequired = true
            }else {
              resRequired = false
            }
          })
        }
        // // console.log(this.list);
        // // 仓库
        // 产品位置
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.chooseOwh == '0' && this.list.length) { 
          this.list.some((item, index) => {
            if(!item.cbowhId) {
              this.$message.error(`请选择第 ${index + 1} 行仓库`)
              location1 = false
              return true
            }else {
              location1 = true
            }
          })
        }else {
          location1 = true
        }
        // 明细位置
        if(this.ofoFlowWork.thingtype && this.ofoFlowWork.chooseOwh == '0' && this.list2.length) { 
          this.list2.some((item, index) => {
            if(!item.cbowhId) {
              this.$message.error(`请选择第 ${index + 1} 行仓库`)
              location2 = false
              return true
            }else {
              location2 = true
            }
          })
        }else {
          location2 = true
        }
        // 判断资源行是否选中多个资源
        this.list.some((item, index) => {
          item.ofrresourceId = item.ofrresourceId + ''
          if(item.ofrresourceId.includes(',')) {
            this.$message.error(`第 ${index + 1} 行资源选中多条，请选择一条`)
            res = false
            return true
          }else {
            res = true
          }
        })
        // 判断附件必填
        if(this.fileList.length) {
          this.fileList.some((item, index) => {
            if(item.isRequired == '1' && !item.fileList.length) {
              this.$message.error(`请上传${item.name}`)
              fileRequired = false
              return true
            }else {
              fileRequired = true
            }
          })
        }
        this.$refs[formName].validate((valid, err) => {
          if (valid && location1 && location2 && res && fileRequired && resRequired) {
            // 格式化文档列表
            let documentsList = []
            if(this.documentsList) {
              documentsList = this.handleDimes(this.documentsList)
            }
            let resourceDimes = []
            if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype == '0' && this.resourceDimes) {
              resourceDimes = this.handleDimes(this.resourceDimes)
            }
            // 表格价税合计
            let ptotalObj = {}
            if(this.list2.length) {
              ptotalObj = this.ptotalObj2
            }else {
              ptotalObj = this.ptotalObj
            }
            
            // 表格成本价税合计
            let ctotalObj = {}
            if(this.list2.length) {
              ctotalObj = this.ctotalObj2
            }else {
              ctotalObj = this.ctotalObj
            }
            this.ruleForm.alertUsers = this.ruleForm.alertUsers ? this.ruleForm.alertUsers.toString() : ''
            // 协同
            if(this.ruleForm.isNotice == '0') {
              this.ruleForm.noticeUsers = this.ruleForm.noticeUsers.toString()
            }
            if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Name) {
              let name = []
              if(this.ofoFlowDeal.restype1Kind != '6') {
                this.ruleForm.dealownId.forEach(item => {
                  name.push(this.thingList.find(arr => arr.ofrThingId == item).name)
                })
                this.ruleForm.dealownName = name.toString()
              }else {
                this.ruleForm.dealownId.forEach(item => {
                  name.push(this.contactList.find(arr => arr.ofrthingId == item).name)
                })
                this.ruleForm.dealownName = name.toString()
              }
              this.ruleForm.dealownId = this.ruleForm.dealownId.toString()
            }
            delete this.ruleForm.dataSourceConfig
            // 禁用提交按钮
            this.isDisabled = true
            let query = {
              ...this.ruleForm,
              adddocument: this.files,
              documents: JSON.stringify(documentsList),
              list: JSON.stringify(this.list),
              list2: JSON.stringify(this.list2),
              ...ptotalObj,
              ...ctotalObj,
              resourceName: this.$refs.children.resource.resourceName,
              resourceDimes: JSON.stringify(resourceDimes),
              resourceRemarks: this.$refs.children.resource.remarks,
              cbRestypeId: this.$refs.children.resource.cbRestypeId,
              files: JSON.stringify(this.fileList)
            }
            // 不是对象管理
            // console.log({...query, ofoWorkId: this.ruleForm.ofoWorkId, resourceId: this.resource.ofrResourceId});
            if(!this.ruleForm.ofoWorkId) { // 新增活动
              // console.log(222222222);
              this.$http.post(url.addWork, qs.stringify({...query, ofoflowworkId: this.ofoFlowWorkId})).then(res => {
                // console.log(res);
                this.isDisabled = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.title = (this.ofoItemName ? ((this.ofoItemName.length > 30 ? this.ofoItemName.substring(0,30) + '...' : this.ofoItemName) + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0') : '') + this.ofoFlowDeal.name + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0' + this.ofoFlowWork.name 
                  this.disabled = true
                  this.isShowBtn = true
                  this.ruleForm.ofoWorkId = res.data.data
                  let item = {ofoWorkId: res.data.data}
                  this.getDetail(item)
                  this.getWorkProcess(item.ofoWorkId)
                  this.pageNum = 1
                  this.isShowNavBtn = true
                  // if(!this.baseOfoWorkId) {
                    this.getList()
                  // }
                }
              })
            }else {
              this.$http.post(url.editWork, qs.stringify({...query, ofoWorkId: this.ruleForm.ofoWorkId, resourceId: this.resource.ofrResourceId})).then(res => {
                // console.log(res);
                this.isDisabled = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  // this.getList()
                  this.title = (this.ofoItemName ? ((this.ofoItemName.length > 30 ? this.ofoItemName.substring(0,30) + '...' : this.ofoItemName) + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0') : '') + this.ofoFlowDeal.name + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0' + this.ofoFlowWork.name 
                  this.disabled = true
                  this.isShowBtn = true
                  let item = {ofoWorkId: this.ruleForm.ofoWorkId}
                  this.getDetail(item)
                  this.getWorkProcess(item.ofoWorkId)
                  this.pageNum = 1
                  this.isShowNavBtn = true
                  this.getList()
                }
              })
            }
          } else {
            for(let key in err) {
              if(key.includes('dataSourceConfig')) {
                 this.$message.error(`${this.documentsList[key.split('.')[1]].dimeName}不能为空`)
                 return false;
              }
            }
            return false;
          }
        });
      },
      // getOneArr (data) {
      //   let newData = []
      //   const callback = (item) => {
      //     (item.children || (item.children = [])).map(v => {
      //       callback(v)
      //     })
      //     delete item.children
      //     // console.log(item.quantity);
      //     newData.push(item)
      //   }
      //   data.map(v => callback(v))
      //   return newData.reverse()
      // },
      // 获取产品列表
      getWorkResList(item) {
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0') {
          this.$http.get(url.getWorkResAndThingList, {params: {
            ofoWorkId: item.ofoWorkId,
            pageNum: item.pageNum ? item.pageNum : 1,
            pageSize: item.pageSize ? item.pageSize : (this.ofoFlowWork.isCalcBom == '0' ? 2000 : 10),
            type: '1'
          }}).then(res => {
            if(res.data.code == 200) {
              if(item.isPrint) {
                this.newProductsList = handleWorkConfigList(this.ofoFlowWork.checkConfig, res.data.data.list, this.workConfig)
                // this.newProductsList.forEach(item => {
                //   item.bomId = item.bomId ? Number(item.bomId) : null
                // })
              }else {
                this.productsList = handleWorkConfigList(this.ofoFlowWork.checkConfig, res.data.data.list, this.workConfig)
                this.productsList.forEach(item => {
                  item.bomId = item.bomId ? Number(item.bomId) : null
                })
                // console.log(this.$refs);
                // console.log(Math.min.apply(Math, this.productsList.map(item => { return item.bomId })))
                this.$refs.product.index = Math.abs(Math.min.apply(Math, this.productsList.map(item => { return item.bomId })))
                this.total1 = res.data.data.total
                this.pageNum1 = item.pageNum ? item.pageNum : 1
                this.pageSize1 = item.pageSize ? item.pageSize : 10
                this.resLimit = res.data.data.limit
                this.resPreLimit = res.data.data.preLimit
              }
            }
          })
        }
      },
      // 获取明细列表
      getWorkThingList(item) {
        if(this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0') {
          this.$http.get(url.getWorkResAndThingList, {params: {
            ofoWorkId: item.ofoWorkId,
            pageNum: item.pageNum ? item.pageNum : 1,
            pageSize: item.pageSize ? item.pageSize : 10,
            type: '2'
          }}).then(res => {
            if(res.data.code == 200) {
              if(item.isPrint) {
                this.newThingsList = handleWorkConfigList(this.ofoFlowWork.checkConfig, res.data.data.list, this.workConfig)
                // this.newThingsList.forEach(item => {
                //   item.bomId = item.bomId ? Number(item.bomId) : null
                // })
              }else {
                this.total2 = res.data.data.total
                this.pageNum2 = item.pageNum ? item.pageNum : 1
                this.pageSize2 = item.pageSize ? item.pageSize : 10
                this.thingLimit = res.data.data.limit
                this.thingPreLimit = res.data.data.preLimit
                for(let key in this.subTotal) {
                  this.subTotal[key] = res.data.data[key]
                }
                // console.log(this.subTotal);
                this.thingsList = handleWorkConfigList(this.ofoFlowWork.checkConfig, res.data.data.list, this.workConfig)
                // console.log(JSON.parse(JSON.stringify(this.thingsList)));
              }
            }
          })
        }
      },
      getWorkCheckList(item) {
        this.$http.get(url.getWorkCheckList, {params: {
          ofoWorkId: item.ofoWorkId
        }}).then(res => {
          if(res.data.code == 200) {
            this.workCheckData = res.data.data
          }
        })
        if(item.isRefresh) {
          this.loading = true
          this.getList()
        }
      },
      // 获取活动详情
      async getDetail(item) {
        this.isShowCollect = true
        this.getWorkResList(item)
        this.getWorkThingList(item)
        this.getNavList()
        await this.$http.get(url.getWork, {params: {ofoWorkId: item.ofoWorkId, isShare: this.isShare}}).then(res => {
          if(res.data.code == 200) {
            this.ruleForm = res.data.data.work
            this.dimePrint = res.data.data.dimePrint ? res.data.data.dimePrint : []
            this.ruleForm.dealownId = this.ruleForm.dealownId ? String(this.ruleForm.dealownId).split(',').map(Number) : []
            this.limit = res.data.data.limit
            this.cfflowitemId = res.data.data.cfflowitemId
            if(this.ruleForm.noticeUsers) {
              this.ruleForm.noticeUsersName = []
              this.ruleForm.noticeUsers = this.ruleForm.noticeUsers.split(',')
              this.postAndUserList.forEach(item => {
                if(item.ofrThingList.length) {
                  item.ofrThingList.forEach(val => {
                    this.ruleForm.noticeUsers.forEach(el => {
                      if(el == val.ofrThingId) {
                        this.ruleForm.noticeUsersName.push(val.name)
                      }
                    })
                  })
                }
              })
              this.ruleForm.noticeUsersName = this.ruleForm.noticeUsersName.toString()
            }
            if(this.ruleForm.alertUsers) {
              this.ruleForm.alertUsersName = []
              this.ruleForm.alertUsers = this.ruleForm.alertUsers.split(',')
              this.postAndUserList.forEach(item => {
                if(item.ofrThingList.length) {
                  item.ofrThingList.forEach(val => {
                    this.ruleForm.alertUsers.forEach(el => {
                      if(el == val.ofrThingId) {
                        this.ruleForm.alertUsersName.push(val.name)
                      }
                    })
                  })
                }
              })
              this.ruleForm.alertUsersName = this.ruleForm.alertUsersName.toString()
            }
            if(this.ruleForm.noticeFlowWork) {
              this.workCopyToList1.forEach(item => {
                if(item.ofoFlowWorkId == this.ruleForm.noticeFlowWork) {
                  this.ruleForm.noticeFlowWorkName = item.name
                }
              })
            }
            this.isCollect = res.data.data.isCollect
            // 初始化活动附件列表
            if(res.data.data.files) {
              this.fileList = res.data.data.files
              this.navList[5].isShow = true
            }else {
              this.fileList = []
              this.navList[5].isShow = false
            }
            this.documentstatus = this.ruleForm.documentstatus
            // 是否有凭证
            if(this.ofoFlowWork.ojdttype == '' || this.ofoFlowWork.ojdttype == null || this.ofoFlowWork.ojdttype == '1') {
                this.navList[4].isShow = false
            }else {
              if(this.ruleForm.documentstatus == '0') {
                this.navList[4].isShow = false
              }else {
                this.navList[4].isShow = true
              }
            }
            // 凭证列表
            if(res.data.data.ojdtacctList) {
              this.ojdtacctList = res.data.data.ojdtacctList
            }else {
              this.ojdtacctList = []
            }
            // 是否展示审批
            if(this.ruleForm.appstatus == null || this.ruleForm.appstatus == '') {
              this.navList[6].isShow = false
            }else {
              this.navList[6].isShow = true
            }
            // 是否展示核销
            if((this.ruleForm.checkStatus != null || this.ofoFlowWork.checkType == '1' || this.ofoFlowWork.checkType == '2') && this.documentstatus != '0' ) {
              this.navList[7].isShow = true
              this.getWorkCheckList(item)
            }else {
              this.navList[7].isShow = false
            }
            // 是否发起审批
            this.isStartApp = res.data.data.isStartApp
            this.chooseList = res.data.data.chooseList ? res.data.data.chooseList : []
            // 是否展示审批(审批状态)
            this.approvalStatus = res.data.data.work.appstatus
            // 初始化审批列表
            if(res.data.data.appStepList) {
              this.approvalList = res.data.data.appStepList
            }else {
              this.approvalList = []
            }
            // 初始化项目时间
            this.ruleForm.workDate = this.parseTime(this.ruleForm.workDate, '{y}-{m}-{d}')
            // 初始化文档列表
            if(res.data.data.documents) {
              this.documentsList = this.handleFormList(res.data.data.documents)
              this.ruleForm.dataSourceConfig = this.documentsList
            }else {
              this.documentsList = []
            }
            // 初始化资源（活动为对象管理）
            if(res.data.data.resource) {
              this.resource = res.data.data.resource
            }else {
              for(let key in this.resource) {
                this.resource[key] = ''
              }
              // this.resource = {}
            }
            // 获取资源维度列表
            if(res.data.data.resourceDimes) {
              this.resourceDimes = this.handleFormList(res.data.data.resourceDimes)
              this.resource.dataSourceConfig = this.resourceDimes
            }else {
              this.resourceDimes = []
              this.resource.dataSourceConfig = []
            }
            // 初始化复制到列表
            if(res.data.data.workCopyToList) {
              this.workCopyToList = res.data.data.workCopyToList
            }else {
              this.workCopyToList = []
            }
          }
        })
        this.navArr = []
        this.navList.forEach((item, index) => {
          if(item.isShow) {
            this.navArr.push(index)
          }
        })
        this.isActive = this.navArr[0]
      },
      // 接收产品列表
      receiveProductsList(val) {
        this.productsList.forEach((item, index) => {
          item.index = index
        })
        this.list = val[0]
        this.ptotalObj = val[1]
        this.ctotalObj = val[2]
        // console.log(val);
      },
      // 接收明细列表
      receiveThingsList(val) {
        this.thingsList.forEach((item, index) => {
          item.index = index
        })
        this.list2 = val[0]
        this.ptotalObj2 = val[1]
        this.ctotalObj2 = val[2]
      },
      getCbussDimeList(val) {
        // this.resource.cbRestypeId = val.cbRestypeId
        // console.log(this.resource.cbRestypeId);
        this.$http.get(url.getCbussDimeList, {params: {cbussId: this.resource.cbRestypeId, dimetype: '1'}}).then(res => {
          if(res.data.code == 200) {
            this.resourceDimes = this.handleFormList(res.data.data)
          }
        })
      },
      // 获取数据
      async getData() {
        // // 获取资源行配置
        // if(this.ofoFlowWork.checkConfig) {
        //   this.$http.get(url.getItemWorkConfig, {params: {
        //     ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId
        //   }}).then(res => {
        //     if(res.data.code == 200) {
        //       this.workConfig = res.data.data
        //     }
        //   })
        // }
        // 获取关联人员列表
        this.$http.get(url.getPostAndUserList).then(res => {
          if(res.data.code == 200) {
            this.postAndUserList = res.data.data
          }
        })
        if(this.ofoFlowWork.isNotice == '0') {
          // 获取活动复制到列表（协同用到）
          this.$http.get(url.getWorkCopyToList, {params: {
            ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId
          }}).then(res => {
            if(res.data.code == 200) {
              this.workCopyToList1 = res.data.data
            }
          })
        }
        // 获取资源以及内容
        if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Name) {
          // console.log(111);
          // console.log(this.ofoFlowWork.thingDimeType);
          await this.$http.get(url.getResTypeAndList, {params: {cbRestypeId: this.ofoFlowDeal.restype1, thingDimeType: this.ofoFlowWork.thingDimeType}}).then(res => {
            if(res.data.code == 200) {
              this.resourceType = res.data.data.cbResourceType
              this.resourceList = res.data.data.list
              this.resourceList.forEach(item => {
                if(item.ofrThingList.length) {
                  item.ofrThingList = item.ofrThingList
                  // item.ofrThingList =  this.handleDimes(item.ofrThingList, 'name', 'name')
                }
              })
            }
          })
        }
        // 获取资源维度，活动核算对象为对象管理
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype == '0') {
          await this.$http.get(url.getResTypes, {params: {cbRestypeIds: this.ofoFlowWork.kindtype}}).then(res => {
            if(res.data.code == 200) {
              this.resTypesList = res.data.data
              this.resource.cbRestypeId = this.resource.cbRestypeId ? this.resource.cbRestypeId : this.resTypesList[0].cbRestypeId
            }
          })
          await this.$http.get(url.getCbussDimeList, {params: {cbussId: this.resource.cbRestypeId, dimetype: '1'}}).then(res => {
            if(res.data.code == 200) {
              this.resourceDimes = this.handleFormList(res.data.data)
              this.resource.dataSourceConfig = this.resourceDimes
            }
          })
        }
        // 获取单位列表
        await this.$http.get(url.getOrgList, {params: {restype2: this.ofoFlowDeal.restype2}}).then(res => {
          if(res.data.code == 200) {
            this.orgList = res.data.data
          }
        })
        // 获取部门及人员列表
        await this.$http.get(url.getDeptAndUser).then(res => {
          if(res.data.code == 200) {
            this.departmentList = res.data.data
          }
        })
        // 获取文档维度列表
        // console.log(this.documentstatus!= '0');
        if(this.ofoFlowWork.kinddocutype && this.documentstatus != '0') {
          // console.log(111);
          await this.$http.get(url.getCbussDimeList, {params: {cbussId: this.ofoFlowWork.kinddocutype, dimetype: '2'}}).then(res => {
            if(res.data.code == 200) {
              this.documentsList = this.handleFormList(res.data.data)
              this.ruleForm.dataSourceConfig = this.documentsList
            }
          })
        }
        // 获取审批状态下拉框
        if(!this.dropDown.appStatus) {
          this.$http.get(url.getDataList, {params: {type: 'buss_app_status' }}).then(res => {
            this.$store.commit('setAppStatus', res.data.data)
          })
        }
        // 获取内容维度类型下拉框
        if(!this.dropDown.thingDimeType) {
          this.$http.get(url.getDataList, {params: {type: 'buss_thing_dimeType' }}).then(res => {
            this.$store.commit('setThingDimeType', res.data.data)
          })
        }
        
        // // 获取仓库列表 （核算方式为数量-前台计算，核算对象不是对象管理）
        // if(this.ofoFlowWork.costmanagetype == '6' && this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0') {
        //   this.$http.get(url.getCbOwhByCbResType, {params: {cbRestypeId: this.ofoFlowWork.kindtype}}).then(res => {
        //     this.cbOwhData = res.data.data
        //   })
        // }
        // // 获取产品列表
        // if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0') {
        //   this.$http.get(url.getResTypeAndList, {params: {cbRestypeId: this.ofoFlowWork.kindtype}}).then(res => {
        //     this.goodsList = res.data.data.list
        //     if(res.data.data.list[0].obussDimeList) {
        //       this.obussDimeList = res.data.data.list[0].obussDimeList
        //     }
        //   })
        // }
      },
      changeNav() {
        this.isShowNav = !this.isShowNav
      },
      jumpWork(row) {
        this.$http.get(url.getWorkJumpId, {params: {ofoWorkId: row.ofoWorkId}}).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            if(res.data.data.ofoWorkId == this.$route.params.ofoItemId) {
              this.$store.commit("setJumpData", {...res.data.data, isApp: false})
            }else {
              this.$router.push({
                path: '/project/' + res.data.data.ofoItemId,
              });
              this.$store.commit("setJumpData", res.data.data)
              let obj = {
                path: "/project/" + res.data.data.ofoItemId,
                name: "proDetail",
                label: "项目详情",
                ofoItemId: res.data.data.ofoItemId,
                children: []
              };
              // this.menuList.forEach(el => {
              //   if(obj.path.indexOf(el.path) != -1) {
              //     this.$store.commit('setSecondMenu', el.children)
              //   }
              // });
              this.$store.commit('setSecondMenu', [])
              this.$store.commit("selectMenu", obj);
            }
            
          }
          
        })
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.pageNum = 1
        this.loading = true
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageNum = val;
        this.loading = true
        this.getList()
      },
      tableRowClassName({row, rowIndex}) {
        if (row.ofoWorkId == this.ruleForm.ofoWorkId) {
          return 'success-row';
        }
        return '';
      },
      handleCollection(item, type) {
        if(type == 's') {
          this.dialogShareVisible = true
          this.form.toUserIds = []
          this.form.obussId = item.ofoWorkId
          if(!this.userList.length) {
            // 获取关联人员列表
            this.$http.get(url.getPostAndUserList).then(res => {
              if(res.data.code == 200) {
                this.userList = res.data.data
                this.userList.forEach(item => {
                  if(item.ofrThingList.length) {
                    item.checked = false
                  }else {
                    item.checked = true
                  }
                })
              }
            })
          }else {
            this.userList.forEach(item => {
              if(item.ofrThingList.length) {
                item.checked = false
              }else {
                item.checked = true
              }
            })
          }
          this.$http.get(url.getShareToList, {params: {flowType: 'w', obussId: item.ofoWorkId}}).then(res => {
            if(res.data.code == 200) {
              this.loading = false
              this.shareList = res.data.data
            }
          })
        }else {
          if(this.isCollect == '1') {
            this.$http.post(url.shareCollect, qs.stringify({
              bussType: 'c',
              flowType: 'w',
              obussId: item.ofoWorkId
            })).then(res => {
              if(res.data.code == 200) {
                this.$message.success('收藏成功')
                this.isCollect = '0'
              }
            })
          }else {
            this.$http.post(url.cancelShareCollect, qs.stringify({
              bussType: 'c',
              flowType: 'w',
              obussId: item.ofoWorkId
            })).then(res => {
              if(res.data.code == 200) {
                this.$message.success('取消收藏')
                this.isCollect = '1'
              }
            })
          }
          
        }
      },
      querySearch(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().includes(queryString.toLowerCase()));
        };
      },
      handleSelect(e) {
        // console.log(item.value.split(' - '));
        this.ruleForm.code = e.code
      },
      inquire() {
        this.loading = true
        this.pageNum = 1
        if(this.ofoFlowDeal.restype1Kind == '6') {
          this.siftForm.dealId = ''
        }else {
          this.siftForm.dealowhId = ''
        }
        this.getList()
      },
      reset() {
        this.siftForm.baseId = ''
        this.siftForm.codename = ''
        this.siftForm.dealId = ''
        this.siftForm.dealowhId = ''
        this.loading = true
        this.pageNum = 1
        this.getList()
      },
      
    },
    watch: {
      screenWidth: {
        handler: function (val) {
          if(val >= 1920) { 
            this.currentSpan = 4
          }else if(val >= 1200) {
            this.currentSpan = 5
          }else if(val >= 992) {
            this.currentSpan = 6
          }else if(val >= 768) {
            this.currentSpan = 8
          }else {
            this.currentSpan = 12
          }
        },
        immediate: true,
        deep:true
      },
      currentSpan: {
        handler: function (val) {
          // if(val == 4) {
          //   this.handleSizeChange(12)
          // }else if(val == 5) {
          //   this.handleSizeChange(10)
          // }else if(val == 6) {
          //   this.handleSizeChange(8)
          // }else if(val == 8) {
          //   this.handleSizeChange(12)
          // }else {
          //   this.handleSizeChange(10)
          // }
        },
        immediate: true,
        deep:true
      },
      toWork(newValue, oldValue) {
        if(newValue.ofoWorkId) {
          // console.log(this.toWork);
          this.isShowNavBtn = true
          this.isShowNav = false
          this.dialogFormVisible = true
          // this.navList[6].isShow = true
          this.disabled = true
          this.title = (this.ofoItemName ? ((this.ofoItemName.length > 30 ? this.ofoItemName.substring(0,30) + '...' : this.ofoItemName) + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0') : '') + this.ofoFlowDeal.name + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0' + this.ofoFlowWork.name 
          // console.log(item);
          if(this.$refs.ruleForm) {
            this.$nextTick(() => {
              // 清除表单校验效果
              this.$refs.ruleForm.clearValidate();
            })
          }
          // await this.handleView({ofoWorkId: this.toWork.ofoWorkId})
          this.getDetail({ofoWorkId: this.toWork.ofoWorkId}).then(res => {
            // console.log(1111);
            if(this.toWork.isApp) {
              this.isActive = 6
            }else {
              this.isShowNav = true
            }
            if(this.toWork.isFile) {
              this.isActive = 5
              this.getData()
            }
            if(this.toWork.turn) {
              this.isActive = this.navArr[0]
              this.getData()
            }
          })
          this.getWorkProcess(this.toWork.ofoWorkId)
        }
        if(newValue.isNotice) {
          this.dialogFormVisible = true
          this.title = (this.ofoItemName ? ((this.ofoItemName.length > 30 ? this.ofoItemName.substring(0,30) + '...' : this.ofoItemName) + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0') : '') + this.ofoFlowDeal.name + '\xa0\xa0\xa0' + ' > ' + '\xa0\xa0\xa0' + this.ofoFlowWork.name 
          this.isShowNavBtn = false
          this.isShowNav = false
          this.disabled = false
          this.isShowBtn = false
          this.navList[4].isShow = false
          this.navList[6].isShow = false
          this.navArr = []
          this.navList.forEach((item, index) => {
            if(item.isShow) {
              this.navArr.push(index)
            }
          })
          this.isActive = this.navArr[0]
          this.ruleForm = {
            code: '',
            name: '',
            workDate: '',
            ifalert: '1',
            dealId: '',
            dealowhId: '',
            selforgId: '',
            selfowhId: '',
            dealownId: [],
            selfownId: '',
            dealownName: ''
          }
          for(let key in this.ruleForm) {
            this.ruleForm[key] = this.toWork.work[key]
          }
          this.ruleForm.workDate = this.parseTime(this.ruleForm.workDate, '{y}-{m}-{d}')
          this.ofoFlowWorkId = this.ofoFlowWork.ofoFlowWorkId
          this.baseOfoWorkId = ''
          this.ruleForm.isNotice = '1'
          
          if(this.toWork.list) {
            let productsList = []
            // this.productsList = this.toWork.list
            this.productsList = handleList(this.toWork.list, this.ofoFlowDeal, this.toWork.oldOfoDealId, this.ofoFlowWork, this.$refs.product.cbOwhData, 'foWorkResourceId')
            this.productsList = handleWorkConfigList(this.ofoFlowWork.checkConfig, this.productsList, this.workConfig)
            // this.productsList = this.handleDimes(this.toWork.list, 'ofrresourceName', 'ofrresourceName')
            // this.thingsList = []
            // this.productsList.forEach((item,index) => {
            //   item.baseOfoWorkResourceId = item.ofoWorkResourceId
            //   item.ofoWorkResourceId = ''
            //   item.baseOfoWorkId = item.ofoworkId
            //   if(this.ofoFlowWork.chooseOwh == '0' && this.$refs.product.cbOwhData.length) {
            //     // console.log(222);
            //     item.cbowhId = this.$refs.product.cbOwhData[0].cbOwhId
            //     item.cbowhName = this.$refs.product.cbOwhData[0].name
            //   }else {
            //     item.cbowhId = ''
            //     item.cbowhName = ''
            //   }
            //   if(this.ofoFlowWork.costmanagetype == '4') {
            //     item.quantity = 1
            //     item.total = item.total.toFixed(2)
            //     item.totalVat = 0
            //     item.vatgroup = '0.00'
            //     item.price = item.total
            //     item.cost = 0
            //     item.costTotal = 0
            //     item.costTotalVat = 0
            //   }else {
            //     if(this.ofoFlowDeal.ofoDealId == this.toWork.oldOfoDealId) {
            //       // console.log(111);
            //       item.quantity = item.openqty
            //       // 金额
            //       item.total = item.opentotal ? item.opentotal.toFixed(2) : 0
            //     }else {
            //       // console.log(222);
            //       item.quantity = item.openqtyDeal
            //       // 金额
            //       item.total = item.opentotalDeal ? item.opentotalDeal.toFixed(2) : 0
            //     }
            //     // 单价
            //     item.price = (item.total / item.quantity).toFixed(2)
            //     // 税额
            //     item.totalVat = (item.total / (1 + Number(item.vatgroup)) * item.vatgroup).toFixed(2)
            //     // 成本金额
            //     item.costTotal = (item.cost * item.quantity).toFixed(2)
            //     // 成本税额
            //     item.costTotalVat = (item.costTotal / (1 + Number(item.vatgroup)) * item.vatgroup).toFixed(2)
                
            //   }
            //   productsList.push(item)
            // })
            // this.productsList = productsList
          }
          if(this.toWork.list2 && this.ofoFlowWork.thingtype) {
            let thingsList = []
            // this.thingsList = this.toWork.list2
            // this.thingsList = []
            this.thingsList = handleList(this.toWork.list2, this.ofoFlowDeal, this.toWork.oldOfoDealId, this.ofoFlowWork, this.$refs.subsidiary.warehouseList, 'foWorkThingId')

            this.thingsList = handleWorkConfigList(this.ofoFlowWork.checkConfig, this.thingsList, this.workConfig)
            // this.thingsList.forEach((item,index) => {
            //   item.baseOfoWorkThingId = item.ofoWorkThingId
            //   item.ofoWorkThingId = ''
            //   item.baseOfoWorkId = item.ofoworkId
            //   if(this.ofoFlowWork.chooseOwh == '0' && this.$refs.subsidiary.warehouseList.length) {
            //     // console.log(222);
            //     item.cbowhId = this.$refs.subsidiary.warehouseList[0].cbOwhId
            //     item.cbowhName = this.$refs.subsidiary.warehouseList[0].name
            //   }else {
            //     item.cbowhId = ''
            //     item.cbowhName = ''
            //   }
            //   if(this.ofoFlowWork.costmanagetype == '4') {
            //     item.quantity = 1
            //     item.total = item.total.toFixed(2)
            //     item.totalVat = 0
            //     item.vatgroup = '0.00'
            //     item.price = item.total
            //     item.cost = 0
            //     item.costTotal = 0
            //     item.costTotalVat = 0
            //   }else {
            //     if(this.ofoFlowDeal.ofoDealId == this.toWork.oldOfoDealId) {
            //       // console.log(111);
            //       item.quantity = item.openqty
            //       // 金额
            //       item.total = item.opentotal ? item.opentotal.toFixed(2) : 0
            //     }else {
            //       // console.log(222);
            //       item.quantity = item.openqtyDeal
            //       // 金额
            //       item.total = item.opentotalDeal ? item.opentotalDeal.toFixed(2) : 0
            //     }
            //     // 单价
            //     item.price = (item.total / item.quantity).toFixed(2)
            //     // 税额
            //     item.totalVat = (item.total / (1 + Number(item.vatgroup)) * item.vatgroup).toFixed(2)
            //     // 成本金额
            //     item.costTotal = (item.cost * item.quantity).toFixed(2)
            //     // 成本税额
            //     item.costTotalVat = (item.costTotal / (1 + Number(item.vatgroup)) * item.vatgroup).toFixed(2)
                
            //   }
            //   thingsList.push(item)
            // })
            // this.thingsList = thingsList
          }else {
            this.thingsList = []
          }
          this.getData().then(res => {
            this.getDeptChange(this.ruleForm.selfowhId, '1')
            if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Kind != '6' && this.ofoFlowDeal.restype1Name) {
              this.getResChange(this.ruleForm.dealId, '1')
            }else {
              this.getDealowhChange(this.ruleForm.dealowhId, '1')
            }
          })
          
        }
      },
      ofoFlowWork(newValue, oldValue) {
        // console.log('66666666', newValue);
        if(!newValue.copyTo) {
          this.getList()
        }
      },
      dialogFormVisible(newValue, oldValue) {
        // console.log(newValue);
        if(!newValue) {
          // console.log(this.isCopy);
          if(this.isCopy) {
            // console.log('哈哈哈哈哈哈哈哈哈');
            // this.restype1 = this.copyrestype1
            // this.restype1Name = this.copyrestype1Name
            // this.restype1Kind = this.copyrestype1Kind
            // this.name = this.copyofoFlowDealName
            // this.ofoFlowDeal = this.copyofoFlowDeal
            // this.ofoFlowWork = this.copyofoFlowWork
            this.$emit('changeCopy', this.copyofoFlowDeal, this.copyofoFlowWork)
            this.getItemWorkConfig(this.copyofoFlowWork)
            // this.restaurants = this.copyrestaurants
            this.getNavList()
            
            this.navArr = []
            this.navList.forEach((item, index) => {
              if(item.isShow) {
                this.navArr.push(index)
              }
            })
            this.isActive = this.navArr[0]
          }
        }
      }
      // ruleForm: {
      //   handler(newValue,oldValue) {
      //     console.log(oldValue.ifalert);
      //     console.log(newValue.ifalert);
      //     // if(newValue.ifalert )
      //   },
      //   // deep: true
      // }
    },
    updated () {
      // console.log(111);;
      // this.getList()
    },
  }
</script>

<style scoped lang="scss">
@media print {
  /* ::v-deep .el-table__header col:nth-col {
    width: 100px !important;
  } */
  ::v-deep .el-table {
    table-layout: auto !important;
  }
  ::v-deep .el-table__header-wrapper .el-table__header {
    width: 100% !important;
  }
  ::v-deep .el-table__body-wrapper .el-table__body {
    width: 100% !important;
  }
  ::v-deep #print .el-table {
    table-layout: fixed !important;
  }

  ::v-deep .el-table__fixed {
    display: none;
  }
  ::v-deep .el-table .el-table__cell.is-hidden > * {
    visibility: visible;
    font-size: 12px;
  }
  .no-print {
    display: none;
  }
}
#print {
  /* ::v-deep .el-table {
    margin-top: 10px;
    table{
      width: 100%!important;
    }
    
  }
  ::v-deep .el-table__body, .el-table__header {
    width: 100% !important;
  } */
  .table {
    width: 100%;
    font-size: 14px;
    color: #606266;
    .tr-header {
      height: 40px;
      text-align: center;
      font-weight: bold;
    }
    .tr-body {
      height: 30px;
      padding: 5px;
      text-align: center;
    }
  }

}
::v-deep .el-dialog {
  margin: 0 auto;
  /* max-height: calc(100% - 72px); */
  height: calc(100% - 72px);
}
::v-deep .el-dialog__body {
  padding: 10px 20px 10px 20px;
  /* max-height: calc(100vh - 206px); */
  height: calc(100vh - 206px);
  overflow: auto;
}
.res-dialog {
  ::v-deep .el-dialog {
    margin: 0 auto;
    max-height: calc(100% - 72px);
    height: auto;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px 10px 20px;
    max-height: calc(100vh - 206px);
    height: auto;
    overflow: auto;
  }
}
.dialog-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-icon-s-fold, .el-icon-s-unfold {
  cursor: pointer;
  margin-right: 5px;
}
.el-icon-s-fold:hover {
  color: #0C75E5;
}
.el-icon-s-unfold:hover {
  color: #0C75E5;
}
::v-deep .my-content {
  /* background: #FDE2E2; */ 
  /* width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
}
.dialog-content {
  display: flex;
  /* height: 100%; */
  .content-left {
    width: 300px;
    margin-right: 16px;
    ::v-deep .el-descriptions__body {
      background: rgba(0,0,0,0);
    }
    ::v-deep .el-table .success-row {
      background: #ecf5ff;
    }
  }
  .content-right {
    flex: 1;
    overflow: hidden;
  }
}
.purchase1 {
  overflow: hidden;
  .bg-purple {
    background: #d3dce6;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  /* #print {
::v-deep .noPadding .cell {
    padding: 0;
  }
  } */
  
  .noright {
    margin-right: 0;
  }
  .el-col-5 {
    width: 20%;
  }
  .el-card {
    background: #FFFFFF;
    box-shadow: 0px 0px 3px 0px rgba(208,208,208,1);
    border-radius: 9px;
    /* width: 100%; */
    width: calc(20% - 16px);
    /* height: 93px; */
    position: relative;
    padding: 0;
    margin-right: 16px;
    float: left;
    margin-bottom: 16px;
    box-sizing: border-box;
    .edit, .delete {
      position: absolute;
      right: 27px;
      top: 8px;
      border: 0;
      padding: 0;
      color: #8E8E8E;
    }
    .delete {
      right: 8px;
    }
    .iconfont {
      position: absolute;
      right: -5px;
      bottom: -5px;
      color: #0C75E5;
      font-size: 45px;
    }
    .status {
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
      height: 21px;
      font-size: 12px;
      line-height: 21px;
      border-radius: 0px 9px 0px 9px;
      display: flex;
      padding: 2px 5px;
      /* flex-wrap: nowrap; */
    }
    .code {
      position: absolute;
      left: 0; 
      top: 0;
      border: 0;
      padding: 0;
      color: #282828;
      font-size: 12px;
      background-color: #F2F2F2;
      padding: 3px 5px;
      border-radius: 9px 0px 9px 0px;
      max-width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .text {
      font-size: 13px;
      line-height: 22px;
      padding: 0 10px;
      /* height: 22px; */
      display: flex;
      .text-left {
        /* display: inline-block;
        width: 45px; */
        color: #8E8E8E;
        letter-spacing: 4px;
      }
      .text-right {
        /* display: inline-block;
        width: calc(100% - 45px); */
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .header {
      margin-top: 24px;
    }
  }
  .create {
    color: #8E8E8E;
    ::v-deep .el-card__body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 47px;
      p {
        height: 13px;
        font-size: 13px;
        font-family: STHeitiSC-Medium, STHeitiSC;
        font-weight: 500;
        line-height: 13px;
      }
    }
  }
}
.exporttable {
    border: solid 1px #c0c0c0;
  }
.nav-tabs {
  display: flex;
  /* overflow: hidden; */
  /* height: 50px; */
  /* background-color: yellow; */
  margin-bottom: 16px;
  margin-right: 20px;
  border-bottom: 1px solid #eeeeee;
  .nav-title {
    display: flex;
    padding-bottom: 16px;
    /* border-bottom: #409eff 2px solid; */
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    .nav-icon {
      width: 40px;
      height: 40px;
      background-color: #FFFFFF;
      border-radius: 50%;
      margin-right: 8px;
      text-align: center;
      line-height: 40px;
      border: #409eff 1px solid;
      color: #409eff;
      .iconfont {
        font-size: 18px;
      }
    }
  }
  .isActive {
    border-bottom: #409eff 3px solid;
    color: #409eff;
    .nav-icon {
      background-color: #409eff;
      color: #FFFFFF;
    }
  }
  }
  .nav-content {
    margin-bottom: 16px;
    margin-right: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid #eeeeee;
  }
</style>
