<template>
  <div class="detail">
    <div class="des">
      <div class="title">{{resData.restypeName}}</div>
      <el-descriptions :colon=false>
        <el-descriptions-item label="代码">
          <span class="colon">:</span>
          {{resData.restypeNumber}}
        </el-descriptions-item>
        <el-descriptions-item label="资源类型">
          <span class="colon">:</span>
          <DictTag :options="dropDown.kind" :value="resData.kind"/>
        </el-descriptions-item>
        <el-descriptions-item label="资源大类交易类型">
          <span class="colon">:</span>
          <DictTag :options="dropDown.kindDealType" :value="resData.kindDealType"/>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="list">
      <div class="title">资源详情</div>
      <el-tabs v-model="activeName" type="card" class="tabs1">
        <el-tab-pane :label="resData.restypeName + '-列表'" name="1">
          <template v-if="activeName == '1'">
            <el-form :inline="true" :model="resFormInline" class="demo-form-inline" size="small" @submit.native.prevent>
              <el-form-item>
                <strong slot="label">资源名称</strong>
                <el-input v-model="resFormInline.resourceName" placeholder="请输入资源名称" clearable @keyup.enter.native="inquire" @clear="inquire"></el-input>
              </el-form-item>
              <el-form-item>
                <strong slot="label">位置</strong>
                <treeselect style="width:240px" v-model="resFormInline.cbowhId" :options="cbOwhTree" :normalizer="normalizer" placeholder="请选择位置" />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
                <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
              </el-form-item>
            </el-form>
            <el-button type="primary" plain size="small" icon="el-icon-plus" @click="addResource" v-if="judgeButton('add')">新增</el-button>
            <el-button type="success" plain size="small" icon="el-icon-edit" @click="editResource" :disabled="!isEditResource" v-if="judgeButton('edit')">编辑</el-button>
            <el-button type="danger" plain size="small" icon="el-icon-delete" @click="deleteResource" :disabled="!isDelResource" v-if="judgeButton('delete')">删除</el-button>
            <el-button type="primary" plain size="small" icon="el-icon-plus" @click="handleBatch" :disabled="!isDelResource" v-if="resData.isPrivate == '0'">批量分配</el-button>
            <el-table
              :data="resourceList"
              tooltip-effect="dark"
              style="width: 100%;margin-top:16px"
              @selection-change="handleSelectionChange"
              :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
              v-loading="loadingResource"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column prop="resourceName" label="资源名称">
                <template slot-scope="scope">
                  <a @click="handleContent(scope.row)" class="link">{{scope.row.resourceName}}</a>
                </template>
              </el-table-column>
              <el-table-column prop="belongName" label="归属人" v-if="resData.isPrivate == '0'"></el-table-column>
              <el-table-column prop="remarks" label="描述" show-overflow-tooltip></el-table-column>
              <el-table-column label="操作" fixed="right" width="120">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    icon="el-icon-edit"
                    @click.native.stop="editResource(scope.row)"
                    type="text"
                    v-if="judgeButton('edit')"
                  >编辑
                  </el-button>
                  <el-button
                    size="mini"
                    icon="el-icon-delete"
                    @click.native.stop="deleteResource(scope.row)"
                    type="text"
                    v-if="judgeButton('delete')"
                  >删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNumResource"
              :page-sizes="[10, 20, 50, 100, 200]"
              :page-size="pageSizeResource"
              layout="->, total, sizes, prev, pager, next, jumper"
              :total="totalReource">
            </el-pagination>
          </template>
        </el-tab-pane>
        <el-tab-pane :label="resData.restypeName + '-资源维度'" name="2">
          <Resource v-if="activeName === '2'" :cbRestypeId="resData.cbRestypeId"/>
        </el-tab-pane>
        <el-tab-pane :label="resData.restypeName + '-内容维度'" name="3">
          <Content v-if="activeName === '3'" :cbRestypeId="resData.cbRestypeId"/>
        </el-tab-pane>
        <el-tab-pane :label="resData.restypeName + '-别名'" name="4">
          <Alias v-if="activeName === '4'" :resData="resData"/>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 新增修改资源对话框 -->
    <el-dialog
      :title="resourceTitle"
      :visible.sync="resourceDialog"
      :append-to-body="true"
    >
      <el-form
        :model="ruleFormRes"
        :rules="rules"
        ref="ruleFormRes"
        class="demo-ruleFormRes"
        style="max-height: 50vh; overflow-y: auto;overflow-x: hidden"
        label-position="right"
      >
        <el-row :gutter="20">
          <!-- 归属人 -->
          <template v-if="resData.isPrivate == '0' && resData.isShowPrice != '0'">
            <el-col :span="12">
              <el-form-item label="名称" prop="resourceName">
                <el-input
                  v-model="ruleFormRes.resourceName"
                  :placeholder="'请输入' + resData.restypeName + '名称'"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="归属人" prop="userId">
                <el-select v-model="ruleFormRes.userId" placeholder="请选择归属人" style="width:97%" filterable>
                  <el-option-group
                    v-for="group in userList"
                    :key="group.ofrResourceId"
                    :label="group.resourceName">
                    <el-option
                      v-for="item in group.ofrThingList"
                      :key="item.ofrThingId"
                      :label="item.name"
                      :value="item.ofrThingId">
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
          </template>
          <!-- 价格 -->
          <template v-else-if="resData.isShowPrice == '0' && resData.isPrivate != '0'">
            <el-col :span="12">
              <el-form-item label="名称" prop="resourceName">
                <el-input
                  v-model="ruleFormRes.resourceName"
                  :placeholder="'请输入' + resData.restypeName + '名称'"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="价格" prop="rprice">
                <el-input
                  v-model="ruleFormRes.rprice"
                  placeholder="请输入价格"
                  style="width: 97%"
                ></el-input>
              </el-form-item>
            </el-col>
          </template>
          <!-- 归属人和价格 -->
          <template v-else>
            <el-col :span="24">
              <el-form-item label="名称" prop="resourceName">
                <el-input
                  v-model="ruleFormRes.resourceName"
                  :placeholder="'请输入' + resData.restypeName + '名称'"
                  style="width: 98%"
                ></el-input>
              </el-form-item>
            </el-col>
            <template v-if="resData.isPrivate == '0' && resData.isShowPrice == '0'">
              <el-col :span="12">
                <el-form-item label="归属人" prop="userId">
                  <el-select v-model="ruleFormRes.userId" placeholder="请选择归属人" style="width:100%" filterable>
                    <el-option-group
                      v-for="group in userList"
                      :key="group.ofrResourceId"
                      :label="group.resourceName">
                      <el-option
                        v-for="item in group.ofrThingList"
                        :key="item.ofrThingId"
                        :label="item.name"
                        :value="item.ofrThingId">
                      </el-option>
                    </el-option-group>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="价格" prop="rprice">
                  <el-input
                    v-model="ruleFormRes.rprice"
                    placeholder="请输入价格"
                    style="width: 97%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </template>
          </template>
          <!-- <el-col :span="resData.isPrivate == '0' ? 12 : 24">
            <el-form-item label="名称" prop="resourceName">
              <el-input
                v-model="ruleFormRes.resourceName"
                :placeholder="'请输入' + resData.restypeName + '名称'"
                :style="{
                  width: resData.isPrivate == '0' ? '100%' : '98%'}"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="resData.isPrivate == '0'">
            <el-form-item label="归属人" prop="userId">
              <el-select v-model="ruleFormRes.userId" placeholder="请选择归属人" style="width:97%" filterable>
                <el-option-group
                  v-for="group in userList"
                  :key="group.ofrResourceId"
                  :label="group.resourceName">
                  <el-option
                    v-for="item in group.ofrThingList"
                    :key="item.ofrThingId"
                    :label="item.name"
                    :value="item.ofrThingId">
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col> -->
        </el-row>
        <Form :formList="formList" :gutter="20" :ruleForm="ruleFormRes"/>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resourceDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleFormRes')" :loading="isLoadingRes">确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 新增修改资源对话框 -->
    <el-dialog
      title="修改归属人"
      :visible.sync="belongDialog"
      :append-to-body="true"
      width="500px"
    >
      <el-form
        :model="ruleFormRes"
        ref="ruleBelongForm"
        style="max-height: 50vh; overflow-y: auto;overflow-x: hidden"
        label-position="right"
      >
        <el-form-item label="归属人" prop="userId">
          <el-select v-model="ruleFormRes.userId" placeholder="请选择归属人" style="width:97%" filterable>
            <el-option-group
              v-for="group in userList"
              :key="group.ofrResourceId"
              :label="group.resourceName">
              <el-option
                v-for="item in group.ofrThingList"
                :key="item.ofrThingId"
                :label="item.name"
                :value="item.ofrThingId">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="belongDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitBelong" :loading="isLoadingRes">确 定</el-button>
      </div>
    </el-dialog>
    <el-drawer
      :title="contentData.resourceName"
      :visible.sync="drawer"
      size="80%">
      <div style="padding:16px">
        <!-- <el-descriptions>
          <el-descriptions-item label="代码">{{contentData.resourceNumber}}</el-descriptions-item>
          <el-descriptions-item label="类型">{{resData.restypeName}}
          </el-descriptions-item>
          <el-descriptions-item label="状态">
            <DictTag :options="dropDown.documentstatus" :value="contentData.documentstatus"/>
          </el-descriptions-item>
        </el-descriptions> -->
        <!-- 内部 -->
        <template v-if="resData.kindDealType == '0' || resData.kindDealType == null">
          <el-tabs v-model="thingName" type="border-card" class="tabs1">
            <el-tab-pane label="BOM" name="7" v-if="resData.isBom == '0'">
              <Bom :contentData="contentData" />
            </el-tab-pane>
            <el-tab-pane label="内容" name="1">
              <InContent v-if="thingName == '1'" :resourceName="contentData.resourceName" :ofrResourceId="contentData.ofrResourceId" ref="inContent" :resData="resData"/>
            </el-tab-pane>
          </el-tabs>
        </template>
        <!-- 外部 -->
        <template v-if="resData.kindDealType == '1'">
          <el-tabs v-model="thingName" type="border-card" class="tabs1">
            <el-tab-pane label="BOM" name="8" v-if="resData.isBom == '0'">
              <Bom :contentData="contentData" />
            </el-tab-pane>
            <el-tab-pane label="联系信息" name="2">
              <OutContent v-if="thingName == '2'" :thingDimeType="'1'" :resourceName="contentData.resourceName" :resId="contentData.ofrResourceId" :cbRestypeId="resData.cbRestypeId" :resData="resData"/>
            </el-tab-pane>
            <el-tab-pane label="发票信息" name="3">
              <OutContent v-if="thingName == '3'" :thingDimeType="'2'" :resourceName="contentData.resourceName" :resId="contentData.ofrResourceId" :cbRestypeId="resData.cbRestypeId" :resData="resData"/>
            </el-tab-pane>
            <el-tab-pane label="账户信息" name="4">
              <OutContent v-if="thingName == '4'" :thingDimeType="'3'" :resourceName="contentData.resourceName" :resId="contentData.ofrResourceId" :cbRestypeId="resData.cbRestypeId" :resData="resData"/>
            </el-tab-pane>
            <el-tab-pane label="物流信息" name="5">
              <OutContent v-if="thingName == '5'" :thingDimeType="'4'" :resourceName="contentData.resourceName" :resId="contentData.ofrResourceId" :cbRestypeId="resData.cbRestypeId" :resData="resData"/>
            </el-tab-pane>
            <el-tab-pane label="其他信息" name="6">
              <OutContent v-if="thingName == '6'" :thingDimeType="'9'" :resourceName="contentData.resourceName" :resId="contentData.ofrResourceId" :cbRestypeId="resData.cbRestypeId" :resData="resData"/>
            </el-tab-pane>
          </el-tabs>
        </template>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import url from '@/config/url'
import Resource from '@/components/resource/kind/resource.vue'
import Content from '@/components/resource/kind/content.vue'
import Alias from '@/components/resource/kind/alias.vue'
import { mapState } from 'vuex'
import DictTag from '@/components/DictTag.vue'
import Form from '@/components/Form.vue'
import OutContent from '@/components/resource/kind/content/OutIndex.vue'
import InContent from '@/components/resource/kind/content/InIndex.vue'
import qs from 'qs'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Bom from '@/components/resource/kind/bom.vue'
  export default {
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown,
        currentMenu: (state) => state.tags.currentMenu,
      })
    },
    components: {
      Resource,
      Content,
      DictTag,
      Form,
      OutContent,
      InContent,
      Treeselect,
      Bom,
      Alias
    },
    data() {
      var validateName = async (rule, value, callback) => {
        if (!value) {
          callback(new Error("请输入名称"));
        } else{
          await this.$http.get(url.getOfrResourceByName, {params: {
            cbRestypeId: this.cbRestypeId,
            resourceName: value,
            ofrResourceId: this.ruleForm.ofrResourceId
          }}).then(res => {
            this.checkCode = res.data.data
          })
          if(this.checkCode == '1') {
            callback(new Error("名称已存在，请重新输入！"));
          }else {
            callback()
          }
        }
      };
      return {
        cbRestypeId: this.$route.params.cbRestypeId,
        resData: {},
        activeName: '1',
        resourceList: [],
        resFormInline: {
          resourceName: '',
          cbowhId: null,
        },
        isEditResource: false,
        isDelResource: false,
        pageNumResource: 1,
        pageSizeResource: 10,
        totalReource: 0,
        loadingResource: true,
        resourceDialog: false,
        resourceTitle: '',
        ruleFormRes: {
          resourceName: "",
          documentstatus: "",
          remarks: "",
          userId: '',
          dataSourceConfig: [],
          rprice: ""
        },
        rules: {
          // resourceName: [{ required: true, validator: validateName, trigger: "blur" }],
          resourceName: [{ required: true, message: "请输入名称", trigger: "blur" }],
          documentstatus: [ { required: true, message: "请选择状态", trigger: "submit" }],
        },
        // ruleBelongForm: {},
        formList: [],
        isLoadingRes: false,
        checkCode: '',
        resourceIds: [],
        drawer: false,
        contentData: {},
        thingName: '',
        userList: [],
        belongDialog: false,
        cbOwhTree: [],
        name: ''
      }
    },
    created () {
      // console.log(this.$route);
      this.getData()
      this.getResList()
    },
    methods: {
      judgeButton(type) {
        return this.currentMenu.perms.some(item => item.menuType == 'F' && item.perms == `${this.currentMenu.kind}:${type}`)
      },
      // 请求数据kindDealType
      getData() {
        this.$http.get(url.getResType, {params: {cbRestypeId: this.cbRestypeId}}).then(res => {
          this.resData = res.data.data
          // this.resData.isPrivate = '0'
          // this.resData.isShowPrice = '0'
          if(this.resData.isPrivate == '0') {
            // 获取归属人列表
            this.$http.get(url.getPostAndUserList).then(res => {
              if(res.data.code == 200) {
                this.userList = res.data.data
              }
            })
          }
        })
        // 获取位置树
        this.$http.get(url.getCbOwhByCbResType, {params: {
          cbRestypeIds: this.cbRestypeId
        }}).then(res => {
          if(res.data.code == 200) {
            let arr = res.data.data
            if(arr.length) {
              arr.push({
                cbOwhId: -1,
                name: '其它',
                pid: 0,
                children: []
              })
            }
            this.cbOwhTree = this.$handleTree(arr, 'cbOwhId' ,'pid')
          }
        })
      },
      /** 转换数据结构 */
      normalizer(node) {
        // this.$nextTick(() => {
          if (node.children && !node.children.length) {
            delete node.children;
          }
          return {
            id: node.cbOwhId,
            label: node.name,
            children: node.children
          };
        // })
      },
      getResList() {
        this.loadingResource = true
        // // 获取资源列表
        // this.$http.get(url.getOfrResourceList, {params: {
        //   cbRestypeId: this.cbRestypeId,
        //   pageNum: this.pageNumResource,
        //   pageSize: this.pageSizeResource,
        //   resourceName: this.resFormInline.resourceName
        // }}).then(res => {
        //   if(res.data.code == 200) {
        //     this.resourceList = res.data.data.list
        //     this.totalReource = res.data.data.total
        //     this.loadingResource = false
        //   }
        // })
        // 获取资源列表
        this.$http.get(url.getCbowhOfrResourceList, {params: {
          cbRestypeId: this.cbRestypeId,
          pageNum: this.pageNumResource,
          pageSize: this.pageSizeResource,
          resourceName: this.resFormInline.resourceName,
          cbowhId: this.resFormInline.cbowhId
        }}).then(res => {
          if(res.data.code == 200) {
            this.resourceList = res.data.data.list
            this.totalReource = res.data.data.total
            this.loadingResource = false
          }
        })
      },
      inquire() {
        this.pageNumResource = 1
        this.getResList()
      },
      reset() {
        for(let key in this.resFormInline) {
          this.resFormInline[key] = ""
        }
        this.resFormInline.cbowhId = null
        this.pageNumResource = 1
        this.getResList()
      },
      // 新增资源
      addResource() {
        this.resourceTitle = '新增' + this.resData.restypeName
        this.resourceDialog = true;
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleFormRes.clearValidate();
        })
        // 初始化表单内容
        for(let key in this.ruleFormRes) {
          if(key == 'documentstatus') {
            this.ruleFormRes[key] = "1"
          }else if(key == 'userId') {
            // console.log(JSON.stringify(sessionStorage.getItem('loginUser')));
            this.ruleFormRes[key] = JSON.parse(sessionStorage.getItem('loginUser')).bussId
          }else {
            this.ruleFormRes[key] = ""
          }
        }
        this.$http.get(url.getCbussDimeList, {params: {cbussId: this.cbRestypeId, dimetype: '1'}}).then(res => {
          if(res.data.code == 200) {
            this.formList = this.handleFormList(res.data.data)
            this.ruleFormRes.dataSourceConfig = this.formList
          }
        })
      },
      // 修改资源
      editResource(row) {
        if(this.$refs.ruleFormRes) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleFormRes.clearValidate();
          })
        }
        this.resourceTitle = '编辑' + this.resData.restypeName
        this.resourceDialog = true
        const id = row.ofrResourceId || this.resourceIds[0]
        this.$http.get(url.getOfrResource, {params: {ofrResourceId: id}}).then(res => {
          // console.log(res.data.data);
          let data = res.data.data.ofrResource
          for(let key in data) {
            this.ruleFormRes[key] = data[key]
          }
          this.formList = this.handleFormList(res.data.data.dimensionList)
          this.ruleFormRes.dataSourceConfig = this.formList
        })
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoadingRes = true
            // console.log();
            let dimes = this.handleDimes(this.formList)
            // this.formList.forEach(item => {
            //   let obj = {}
            //   obj.cbdId = item.cbdId
            //   obj.dimeValue = item.dimeValue
            //   dimes.push(obj)
            // })
            // console.log(dimes);
            let obj = {
              cbRestypeId: this.cbRestypeId,
              resourceName: this.ruleFormRes.resourceName,
              documentstatus: '1',
              dimes: JSON.stringify(dimes),
              remarks: this.ruleFormRes.remarks,
              userId: this.ruleFormRes.userId,
              rprice: this.ruleFormRes.rprice,
            }
            if(!this.ruleFormRes.ofrResourceId) {
              // console.log(JSON.stringify(dimes));
              this.$http.post(url.addOfrResource, qs.stringify(obj)).then(res => {
                this.isLoadingRes = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg)
                  this.resourceDialog = false
                  this.pageNumResource = 1
                  this.getResList()
                }
              })
            }else {
              this.$http.post(url.editOfrResource, qs.stringify({
                ofrResourceId: this.ruleFormRes.ofrResourceId,
                resourceName: this.ruleFormRes.resourceName,
                documentstatus: this.ruleFormRes.documentstatus,
                dimes: JSON.stringify(dimes),
                remarks: this.ruleFormRes.remarks,
                userId: this.ruleFormRes.userId,
                rprice: this.ruleFormRes.rprice,
              })).then(res => { 
                this.isLoadingRes = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg)
                  this.resourceDialog = false
                  this.pageNumResource = 1
                  this.getResList()
                }
              })
            }
          } else {
            return false;
          }
        });
      },
      // 删除资源
      deleteResource(row) {
        const resourceIds = row.ofrResourceId || this.resourceIds.toString()
        this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.removeOfrResource, qs.stringify({ofrResourceIds: resourceIds})).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getResList()
            }
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
      handleBatch() {
        this.ruleFormRes.userId = JSON.parse(sessionStorage.getItem('loginUser')).bussId
        this.belongDialog = true
      },
      submitBelong() {
        let obj = {
          ofrResourceIds: this.resourceIds.toString(),
          userId: this.ruleFormRes.userId
        }
        this.isLoadingRes = true
        this.$http.post(url.editOfrResourceBelong, qs.stringify(obj)).then(res => {
          this.isLoadingRes = false
          if(res.data.code === 200) {
            this.$message.success(res.data.msg)
            this.belongDialog = false
            this.getResList()
          }
        })
      },
      // 查看内容
      async handleContent(row) {
        await this.$http.get(url.getOfrResource, {params: {ofrResourceId: row.ofrResourceId}}).then(res => {
          if(res.data.code == 200) {
            this.contentData = res.data.data.ofrResource
          }
        })
        this.drawer = true
        // this.$http.get(url.infoThingalias + this.resData.cbRestypeId + '/' + 0).then(res => {
        //   if(res.data.code == 200 && res.data.data) {
        //     this.name = res.data.data[this.thing] ? res.data.data[this.thing] : '名称'
        //   }else {
        //     this.name = '名称'
        //   }
        // })
        if(this.resData.kindDealType == '1') {
          this.thingName = this.resData.isBom == '0' ? '8' : '2'
        }else {
          this.thingName = this.resData.isBom == '0' ? '7' : '1'
          // console.log(this.$refs.inContent);
          if(this.$refs.inContent) {
            this.$refs.inContent.getList()
          }
        }
      },
      handleSelectionChange(rows) {
        this.resourceIds = rows.map(item => item.ofrResourceId)
        this.isEditResource = rows.length === 1
        this.isDelResource = rows.length
      },
      handleSizeChange(val) {
        this.pageSizeResource = val
        this.pageNumResource = 1
        this.getResList()
      },
      handleCurrentChange(val) {
        this.pageNumResource = val
        this.getResList()
      },
    },
    inject: ["reload"], //注入   和methods同级
    watch: {
      $route(to,from) {
        this.reload()
      }
    },
  }
</script>

<style lang="scss" scoped>
.detail {
  /* padding: 16px; */
  .des, .list {
    background: #FFFFFF;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,0.5);
    border-radius: 11px;
    padding: 16px;
    /* text-align: center; */
    padding-bottom: 0;
    margin-bottom: 16px;
    box-sizing: border-box;
  }
  .des {
    ::v-deep .el-descriptions-item__label:not(.is-bordered-label) {
      /* width: 52px; */
      text-align: right;
      text-align-last: justify;
      display: block;
      margin-right: 0;
    }
    .colon {
      margin-right: 8px;
    }
  }
  .list{
    margin-bottom: 0;
    overflow: hidden;
    .el-table {
      margin-bottom: 16px;
    }
    .tabs1 {
      margin-bottom: 16px;
      ::v-deep >.el-tabs__header {
        border: 0;
        padding-bottom: 30px;
        margin: 0;
        border-bottom: 1px solid #E8E8E8;
        margin-bottom: 16px;
      }
      ::v-deep .el-tabs__item {
        border: 0;
        padding: 6px 16px 6px 32px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #282828;
        line-height: 20px;
        box-sizing: content-box;
        background-color: #F2F2F2;
        position: relative;
        margin-right: 16px;
      }
      ::v-deep .el-tabs__item::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border: 16px solid rgba($color: #000000, $alpha: 0);
        border-left: 16px solid #fff;
      }
      ::v-deep .el-tabs__item::after {
        content: '';
        position: absolute;
        top: 0;
        right: -32px;
        border: 16px solid rgba($color: #000000, $alpha: 0);
        border-left: 16px solid #F2F2F2;
      }
      ::v-deep .el-tabs__nav {
        border: 0;
      }
      ::v-deep .el-tabs__item.is-active {
        color: #fff;
        background-color: #0C75E5;
      }
      ::v-deep .el-tabs__item.is-active::after {
        border-left: 16px solid #0C75E5;
      }
    }
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 15px;
    padding-left: 8px;
    border-left: #0C75E5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
}
::v-deep .el-dialog__body {
  padding: 30px 0 30px 20px;
}
</style>