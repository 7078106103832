<template>
  <div class="resource">
    <el-button type="primary" plain size="small" icon="el-icon-plus" @click="handleAdd" v-if="judgeButton('add')">新增</el-button>
    <el-button type="success" plain size="small" icon="el-icon-edit" :disabled="!edit" @click="handleEdit" v-if="judgeButton('edit')">编辑</el-button>
    <el-button type="danger" plain size="small" icon="el-icon-delete" :disabled="!del" @click="handleDel" v-if="judgeButton('delete')">删除</el-button>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%;margin-top:16px"
      @selection-change="handleSelectionChange"
      :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      v-loading="loading">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        prop="dimeName"
        label="维度">
      </el-table-column>
      <el-table-column
        label="编辑类型"
        prop="editType">
        <template slot-scope="scope">
          <DictTag :options="dropDown.editType" :value="scope.row.editType" :isStyle="true"/>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="thingDimeType" label="资源维度类型">
        <template slot-scope="scope">
          <DictTag :options="dropDown.thingDimeType" :value="scope.row.thingDimeType"/>
        </template>
      </el-table-column> -->
      <el-table-column prop="dimeRemarksType" label="维度描述类型">
        <template slot-scope="scope">
          <DictTag :options="dropDown.dimeRemarksType" :value="scope.row.dimeRemarksType"/>
        </template>
      </el-table-column>
      <el-table-column
        label="排序"
        prop="sort">
      </el-table-column>
      <el-table-column 
        label="操作"
        fixed="right"
        width="120">
        <template slot-scope="scope">
          <el-button
            size="mini" icon="el-icon-edit"
            @click="handleEdit(scope.row)" v-if="judgeButton('edit')">编辑</el-button>
          <el-button
            size="mini" icon="el-icon-delete"
            @click="handleDel(scope.row)" v-if="judgeButton('delete')">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        
        <el-form-item label="维度" prop="cbDimeId">
          <el-select v-model="ruleForm.cbDimeId" filterable placeholder="请选择维度" style="width:100%">
            <el-option-group
              v-for="group in options"
              :key="group.id"
              :label="group.label">
              <el-option
                v-for="item in group.children"
                :key="item.id"
                :label="item.label"
                :value="item.id">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="ruleForm.sort" controls-position="right" :min="0" :max="100" style="width:100%"></el-input-number>
        </el-form-item>
        <!-- <el-form-item label="维度类型" prop="thingDimeType">
          <el-select v-model="ruleForm.thingDimeType" placeholder="请选择维度类型" style="width:100%" clearable>
            <el-option v-for="(item, index) in dropDown.thingDimeType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item> -->
        
        <el-form-item label="维度描述类型" prop="dimeRemarksType">
          <el-select v-model="ruleForm.dimeRemarksType" placeholder="请选择维度描述类型" style="width:100%" clearable>
            <el-option v-for="(item, index) in dropDown.dimeRemarksType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="打印" prop="isPrint">
          <el-checkbox v-model="ruleForm.isPrint" true-label="0" false-label="1">活动打印</el-checkbox>
        </el-form-item>
        <el-form-item v-if="ruleForm.isPrint == '0'" label="选择活动" prop="workFlowIds">
          <treeselect :multiple="true" v-model="ruleForm.workFlowIds" :options="tree" :normalizer="normalizer" placeholder="请选择活动" :disable-branch-nodes="true" :flat="true"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :disabled="isDisabled">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import qs from 'qs'
import {mapState} from 'vuex'
import DictTag from '@/components/DictTag.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  export default {
    components: {
      DictTag,
      Treeselect
    },
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown,
        currentMenu: (state) => state.tags.currentMenu,
      })
    },
    props: {
      cbRestypeId: {
        type: Number,
      },
    },
    data() {
      return {
        tableData: [],
        dialogFormVisible: false,
        ruleForm: {
          cbDimeId: null,
          sort: 0,
          dimeRemarksType: "",
          thingDimeType: "",
          isPrint: '',
          workFlowIds: null
        },
        rules: {
          cbDimeId: [{ required: true, message: "请选择维度", trigger: "submit" }],
          sort: [{ required: true, message: "请选择排序", trigger: "submit" }]
        },
        // 维度
        options: [],
        // 弹出层标题
        title: '',
        edit: false,
        del: false,
        multipleSelection: [],
        ids: [],
        loading: true,
        isDisabled: false,
        tree: []
      }
    },
    created() {
      this.$http.get(url.getCflowItemTree).then(res => {
        if(res.data.code == 200) {
          this.tree = res.data.data
          this.tree.forEach(process => {
            process.isDisabled = true
            if(process.children) {
              process.children.forEach(deal => {
                deal.isDisabled = true
              })
            }
          })
        }
      })
      this.getList()
    },
    methods: {
      judgeButton(type) {
        return this.currentMenu.perms.some(item => item.menuType == 'F' && item.perms == `${this.currentMenu.kind}:${type}`)
      },
      // 拿资源维度列表
      getList() {
        this.$http.get(url.getCbussDimeList, {params: {cbussId: this.cbRestypeId, dimetype: '1'}}).then(res => {
          if(res.data.code == 200) {
            this.tableData = res.data.data
            this.loading = false
          }
        })
        this.$http.get(url.getCbDimeTree).then(res => {
          if(res.data.code == 200) {
            this.options = res.data.data
          }
        })
        if(!this.dropDown.editType) {
          this.$http.get(url.getDataList, {params: {type: 'buss_edit_type'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setEditType', res.data.data)
            }
          })
        }
        // 获取内容维度类型下拉框
        // if(!this.dropDown.thingDimeType) {
        //   this.$http.get(url.getDataList, {params: {type: 'buss_thing_dimeType'}}).then(res => {
        //     if(res.data.code == 200) {
        //       this.$store.commit('setThingDimeType', res.data.data)
        //     }
        //   })
        // }
        // 获取维度描述类型下拉框
        if(!this.dropDown.dimeRemarksType) {
          this.$http.get(url.getDataList, {params: {type: 'buss_dime_remarksType'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setDimeRemarksType', res.data.data)
            }
          })
        }
      },
      // 表格选中框事件
      handleSelectionChange(rows) {
        // this.multipleSelection = rows
        this.ids = rows.map(item => item.cbdId)
        this.edit = rows.length === 1
        this.del = rows.length
      },
      // 表格的修改按钮
      handleEdit(row) {
        if(this.$refs.ruleForm) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm.clearValidate();
          })
        }
        const id = row.cbdId || this.ids[0]
        this.title = '编辑资源维度'
        this.dialogFormVisible = true
        // this.selected = row
        this.$http.get(url.getCbussDime, {params: {cbdId: id}}).then(res => {
          if(res.data.code === 200) {
            let data = res.data.data
            for(let key in data) {
              if(key == 'workFlowIds') {
                this.ruleForm[key] = data[key] ? data[key].split(',') : []
              }else {
                this.ruleForm[key] = data[key]
              }
            }
          }
        })
      },
      // 表格的删除按钮
      handleDel(row) {
        // this.selected = row
        const ids = row.cbdId || this.ids.toString()
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.removeCbussDime, qs.stringify({cbdIds: ids})).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getList()
            }
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isDisabled = true
            let query = JSON.parse(JSON.stringify(this.ruleForm))
            query.workFlowIds = query.workFlowIds.toString()
            if(!this.ruleForm.cbdId) {
              query.dimetype = '1'
              query.cbussId = this.cbRestypeId
              this.$http.post(url.addCbussDime, qs.stringify(query)).then(res => {
                // console.log(res);
                this.isDisabled = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg)
                  this.dialogFormVisible = false
                  this.getList()
                }
              })
            }else {
              this.$http.post(url.editCbussDime, qs.stringify(query)).then(res => { 
                this.isDisabled = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg)
                  this.dialogFormVisible = false
                  this.getList()
                }
              })
            }
          } else {
            return false;
          }
        });
      },
      handleAdd() {
        this.title = '新增资源维度'
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
        this.dialogFormVisible = true
        // 初始化表单内容
        for(let key in this.ruleForm) {
          if(key == 'sort') {
            this.ruleForm[key] = this.tableData.length + 1
          }else if(key == 'workFlowIds') {
            this.ruleForm[key] = []
          }else {
            this.ruleForm[key] = ""
          }
        }
      },
      /** 转换数据结构 */
      normalizer(node) {
        // this.$nextTick(() => {
          if (node.children == null || node.children.length == 0) {
            delete node.children;
          }
          // console.log(node.typeLevel);
          // return {
          //   id: node.cbOwhId,
          //   label: node.name,
          //   children: node.children
          // };
        // })
      },
    }
  }
</script>

<style lang="scss" scoped>
.resource {
  .el-table {
    .el-button {
      color: #0c75e5;
      border: 0;
      padding: 0;
    }
  }
}
</style>