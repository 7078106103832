<template>
  <div>
    <!-- <el-button @click="addList" plain type="primary" style="margin-bottom: 16px" v-show="!disabled" size="small">增行</el-button> -->
    <el-button plain type="primary" size="small" v-if="!disabled && ofoFlowWork.isMultiple == '0'" @click="selectMore(false)" style="margin-bottom: 16px">多选</el-button>
    <el-button size="small" type="info" plain @click="copyFromAdd" v-if="(ofoFlowWork.beforework || ofoFlowWork.cfbeforework) && !disabled" style="margin-bottom: 16px">复制从</el-button>
    <el-button plain type="danger" size="small" v-if="!disabled" @click="deleteMore" style="margin-bottom: 16px" :disabled="!selectedId.length">删除已选</el-button>
    <template v-if="!disabled && ofoFlowWork.kindtype && !ofoFlowWork.kindtype.includes(',')">
      <el-button size="small" type="warning" icon="el-icon-download" @click="importBtn">导入</el-button>
    </template>
    <el-switch v-model="isTax" inactive-text="含税" style="float: right" v-show="!disabled && (ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5')" ></el-switch>
    <!-- 新增时可编辑的表格 -->
    <template v-if="!disabled">
      <el-table
        style="width: 100%"
        :data="productsList"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
        @selection-change="handleRowChange"
        ref="multipleTable"
        key="table1"
        border
        :row-key="getRowKey"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          type="index"
          label="行号"
          width="50">
        </el-table-column>
        <!-- 位置 -->
        <el-table-column
          type=""
          prop="cbowhId"
          label="位置"
          width="200"
          v-if="ofoFlowWork.chooseOwh == '0'">
          <template slot="header">
            <a class="vatgroup" @click="changeVatgroup('all', 'cbowhId')">位置
              <i class="el-icon-edit-outline"></i>
            </a>
          </template>
          <template slot-scope="scope">
            <a class="vatgroup" @click="changeVatgroup('single', 'cbowhId', scope.row.cbowhId, scope.$index, scope.row)">
              {{scope.row.cbowhName}}
            </a>
            
          </template>
        </el-table-column>
        <!-- 货物或应税劳务、服务名称 -->
        <el-table-column
          prop="ofrresourceName"
          :label="ofoFlowWork.kindtypeName"
          min-width="220">
          <template slot="header">
            {{ofoFlowWork.kindtypeName}}
            <el-button type="text" @click="addResource" v-if="!disabled && isAdd == '0'" icon="el-icon-circle-plus-outline"></el-button>
          </template>
          <template slot-scope="scope">
            <a class="vatgroup" @click="selectMore(true, scope.row, scope.$index)" :style="{color: scope.row.isMultiRes > 1 ? '#e6a23c' : (scope.row.isMultiRes == 0 ? 'red' : '#000')}" v-if="scope.row.bomId < 0 && ofoFlowWork.isMultiple == '0'">
              {{scope.row.ofrresourceName}}
            </a>
            <a v-else>{{scope.row.ofrresourceName}}</a>
          </template>
        </el-table-column>
        <!-- 起始数量 -->
        <el-table-column
          prop="beginValue"
          label="起始数量"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '8'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.beginValue" clearable @blur="handleCount1(scope.row)" class="right-input"/>
          </template>
        </el-table-column>
        
        <!-- 终止数量 -->
        <el-table-column
          prop="endValue"
          label="终止数量"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '8'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.endValue" clearable @blur="handleCount2(scope.row)" class="right-input"/>
          </template>
        </el-table-column>
        <!-- 数量期间 -->
        <el-table-column
          prop="quantity"
          label="数量"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '8'">
          <template slot-scope="scope">{{scope.row.quantity}}
          </template>
        </el-table-column>
        <!-- 数量 -->
        <el-table-column
          prop="workConfigList1"
          min-width="150"
          v-for="(item, index) in workConfig.workConfigList1"
          :key="index"
          :label="item.name"
          v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4' && !ofoFlowWork.thingtype">
          <template slot="header">
            <el-popover
              placement="right"
              width="150"
              trigger="click"
              :ref="`popover${item.ofoFlowWorkConfigId}`">
              <el-input size="mini" v-model="configValue" clearable class="right-input" v-if="item.showType != '2'">
                <template slot="append" v-if="item.showType == '1'">%</template>
              </el-input>
              <el-select
                v-model="configValue"
                filterable
                clearable
                style="width:100%"
                v-if="item.showType == '2'"
              >
                <el-option 
                  v-for="(val, id) in JSON.parse(item.options)"
                  :key="id"
                  :label="val.dictLabel"
                  :value="val.dictValue + ''"
                ></el-option>
              </el-select>
              <div style="text-align: right; margin-top: 10px">
                <el-button type="primary" size="mini" @click="submitConfigValue(item, index, 'workConfigList1')">确 定</el-button>
              </div>
              <a slot="reference" class="vatgroup" @click="configValue = ''">{{item.name}}</a>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.workConfigList1[index].configValue"
              clearable
              class="right-input"
              @input="computeSl(scope.row, index, 'workConfigList1')"
              v-if="scope.row.workConfigList1[index] && scope.row.workConfigList1[index].showType != '2'"
            >
              <template slot="append" v-if="scope.row.workConfigList1[index].showType == '1'">%</template>
            </el-input>
            <el-select
              v-model="scope.row.workConfigList1[index].configValue"
              filterable
              clearable
              style="width:100%"
              v-if="scope.row.workConfigList1[index] && scope.row.workConfigList1[index].showType == '2'"
            >
              <el-option 
                v-for="(val, id) in JSON.parse(scope.row.workConfigList1[index].options)"
                :key="id"
                :label="val.dictLabel"
                :value="val.dictValue + ''"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="quantity"
          label="数量"
          min-width="150"
          v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.quantity" @input="compute1(scope.row)" clearable class="right-input"/>
          </template>
        </el-table-column>
        <!-- 开始时间 -->
        <el-table-column
          prop="beginValue"
          label="开始时间"
          width="250"
          v-if="ofoFlowWork.costmanagetype == '7'">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.beginValue"
              type="datetime"
              placeholder="选择开始时间"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              @change="handleChangeSj1(scope.row)">
            </el-date-picker>
          </template>
        </el-table-column>
        <!-- 结束时间 -->
        <el-table-column
          prop="endValue"
          label="结束时间"
          width="250"
          v-if="ofoFlowWork.costmanagetype == '7'">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.endValue"
              type="datetime"
              placeholder="选择结束时间"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              @change="handleChangeSj2(scope.row)">
            </el-date-picker>
          </template>
        </el-table-column>
        <!-- 时间期间 -->
        <el-table-column
          prop="quantity"
          label="时间期间"
          v-if="ofoFlowWork.costmanagetype == '7'">
          <template slot-scope="scope">{{scope.row.quantity | filter_handleTimeRange}}
          </template>
        </el-table-column>
        <!-- 单价 -->
        <el-table-column
          prop="workConfigList2"
          min-width="150"
          v-for="(item, index) in workConfig.workConfigList2"
          :key="item.ofoFlowWorkConfigId"
          :label="item.name"
          v-if="(ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5') && !ofoFlowWork.thingtype && workConfig.workConfigList2.length">
          <template slot="header">
            <el-popover
              placement="right"
              width="150"
              trigger="click"
              :ref="`popover${item.ofoFlowWorkConfigId}`">
              <el-input size="mini" v-model="configValue" clearable class="right-input" v-if="item.showType != '2'">
                <template slot="append" v-if="item.showType == '1'">%</template>
              </el-input>
              <el-select
                v-model="configValue"
                filterable
                clearable
                style="width:100%"
                v-if="item.showType == '2'"
              >
                <el-option 
                  v-for="(val, id) in JSON.parse(item.options)"
                  :key="id"
                  :label="val.dictLabel"
                  :value="val.dictValue + ''"
                ></el-option>
              </el-select>
              <div style="text-align: right; margin-top: 10px">
                <el-button type="primary" size="mini" @click="submitConfigValue(item, index, 'workConfigList2')">确 定</el-button>
              </div>
              <a slot="reference" class="vatgroup" @click="configValue = ''">{{item.name}}</a>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <el-input v-model="scope.row.workConfigList2[index].configValue" clearable class="right-input" @input="computeSl(scope.row, index, 'workConfigList2')" v-if="scope.row.workConfigList2[index] && scope.row.workConfigList2[index].showType != '2'">
              <template slot="append" v-if="scope.row.workConfigList2[index].showType == '1'">%</template>
            </el-input>
            <el-select
              v-model="scope.row.workConfigList2[index].configValue"
              filterable
              clearable
              style="width:100%"
              v-if="scope.row.workConfigList2[index] && scope.row.workConfigList2[index].showType == '2'"
            >
              <el-option 
                v-for="(val, id) in JSON.parse(scope.row.workConfigList2[index].options)"
                :key="id"
                :label="val.dictLabel"
                :value="val.dictValue + ''"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          :label="isTax ? '单价（含税）' : '单价（不含税）'"
          min-width="150"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.price" @input="compute2(scope.row)" class="right-input" clearable @blur="compute22(scope.row)" @focus="compute222(scope.row)"/>
          </template>
        </el-table-column>
        <!-- 金额 -->
        <el-table-column
          prop="workConfigList3"
          min-width="150"
          v-for="(item, index) in workConfig.workConfigList3"
          :key="item.ofoFlowWorkConfigId"
          :label="item.name"
          v-if="(ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '4' || ofoFlowWork.costmanagetype == '5') && !ofoFlowWork.thingtype">
          <template slot="header">
            <el-popover
              placement="right"
              width="150"
              trigger="click"
              :ref="`popover${item.ofoFlowWorkConfigId}`">
              <el-input size="mini" v-model="configValue" clearable class="right-input" v-if="item.showType != '2'">
                <template slot="append" v-if="item.showType == '1'">%</template>
              </el-input>
              <el-select
                v-model="configValue"
                filterable
                clearable
                style="width:100%"
                v-if="item.showType == '2'"
              >
                <el-option 
                  v-for="(val, id) in JSON.parse(item.options)"
                  :key="id"
                  :label="val.dictLabel"
                  :value="val.dictValue + ''"
                ></el-option>
              </el-select>
              <div style="text-align: right; margin-top: 10px">
                <el-button type="primary" size="mini" @click="submitConfigValue(item, index, 'workConfigList3')">确 定</el-button>
              </div>
              <a slot="reference" class="vatgroup" @click="configValue = ''">{{item.name}}</a>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <el-input v-model="scope.row.workConfigList3[index].configValue" clearable class="right-input" @input="computeSl(scope.row, index, 'workConfigList3')" v-if="scope.row.workConfigList3[index] && scope.row.workConfigList3[index].showType != '2'">
              <template slot="append" v-if="scope.row.workConfigList3[index].showType == '1'">%</template>
            </el-input>
            <el-select
              v-model="scope.row.workConfigList3[index].configValue"
              filterable
              clearable
              style="width:100%"
              v-if="scope.row.workConfigList3[index] && scope.row.workConfigList3[index].showType == '2'"
            >
              <el-option 
                v-for="(val, id) in JSON.parse(scope.row.workConfigList3[index].options)"
                :key="id"
                :label="val.dictLabel"
                :value="val.dictValue + ''"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="total"
          :label="isTax ? '金额（含税）' : '金额（不含税）'"
          min-width="150"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.total" clearable @input="compute3(scope.row)" class="right-input" @blur="compute33(scope.row)" @focus="compute333(scope.row)"/>
          </template>
        </el-table-column>
        <!-- 费用金额 -->
        <el-table-column
          prop="total"
          label="费用金额"
          min-width="200"
          v-if="ofoFlowWork.costmanagetype == '4'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.total" clearable class="right-input" @blur="compute33(scope.row)" @focus="compute333(scope.row)"/>
          </template>
        </el-table-column>
        <!-- 税率 -->
        <el-table-column
          prop="vatgroup"
          label="税率"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot="header">
            <a class="vatgroup" @click="changeVatgroup('all', 'vatgroup')">税率
              <i class="el-icon-edit-outline"></i>
            </a>
          </template>
          <template slot-scope="scope">
            <a class="vatgroup" @click="changeVatgroup('single', 'vatgroup', scope.row.vatgroup, scope.$index, scope.row)">
              <DictTag :options="dropDown.invoiceVat" :value="scope.row.vatgroup"/>
            </a>
          </template>
        </el-table-column>
        <!-- 税额 -->
        <el-table-column
          prop="totalVat"
          label="税额"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <!-- <template slot-scope="scope">
            <el-input v-model="scope.row.totalVat" disabled class="right-input"/>
          </template> -->
        </el-table-column>
        <!-- 票种 -->
        <el-table-column
          prop="invoicetype"
          label="票种"
          width="190"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot="header">
            <a class="vatgroup" @click="changeVatgroup('all', 'invoicetype')">票种
              <i class="el-icon-edit-outline"></i>
            </a>
          </template>
          <template slot-scope="scope">
            <a class="vatgroup" @click="changeVatgroup('single', 'invoicetype', scope.row.invoicetype, scope.$index, scope.row)">
              <DictTag :options="dropDown.invoiceType" :value="scope.row.invoicetype"/>
            </a>
          </template>
        </el-table-column>
        <!-- 成本单价 -->
        <el-table-column
          prop="cost"
          :label="isTax ? '成本单价（含税）' : '成本单价（不含税）'"
          min-width="150"
          v-if="ofoFlowWork.costmanagetype == '2'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.cost" @input="compute5(scope.row)" class="right-input" @blur="compute55(scope.row)" @focus="compute555(scope.row)"/>
          </template>
        </el-table-column>
        <!-- 成本金额 -->
        <el-table-column
          prop="costTotal"
          :label="isTax ? '成本金额（含税）' : '成本金额（不含税）'"
          min-width="150"
          v-if="ofoFlowWork.costmanagetype == '2'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.costTotal" @input="compute6(scope.row)" clearable class="right-input" @blur="compute66(scope.row)" @focus="compute666(scope.row)"/>
          </template>
        </el-table-column>
        <!-- 成本税额 -->
        <el-table-column
          prop="costTotalVat"
          label="成本税额"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '2'">
          <!-- <template slot-scope="scope">
            <el-input v-model="scope.row.costTotalVat" disabled clearable class="right-input"/>
          </template> -->
        </el-table-column>
        <!-- 自定义 -->
        <el-table-column
          prop="workConfigList4"
          min-width="160"
          v-for="(item, index) in workConfig.workConfigList4"
          :key="index"
          :label="item.name"
          v-if="!ofoFlowWork.thingtype">
          <template slot="header">
            <el-popover
              placement="right"
              width="160"
              trigger="click"
              :ref="`popover${item.ofoFlowWorkConfigId}`">
              <el-input size="mini" v-model="configValue" clearable class="right-input" v-if="item.showType == '1'">
                <template slot="append">%</template>
              </el-input>
              <el-select
                v-model="configValue"
                filterable
                clearable
                style="width:100%"
                v-if="item.showType == '2'"
                size="mini"
              >
                <el-option 
                  v-for="(val, id) in JSON.parse(item.options)"
                  :key="id"
                  :label="val.dictLabel"
                  :value="val.dictValue + ''"
                ></el-option>
              </el-select>
              <el-input size="mini" v-model="configValue" clearable v-if="item.showType == '3'"></el-input>
              <el-date-picker
                v-if="item.showType == '4'" 
                v-model="configValue" 
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                style="width: 100%"
                size="mini">
              </el-date-picker>
              <!-- <el-input size="mini" v-model="configValue" clearable v-else></el-input> -->
              <el-input
                v-model="configValue"
                clearable
                v-if="item.showType == '5'"
                class="right-input"
                size="mini"
                @blur="configValue = comdify(configValue)"
                @focus="configValue = delComdify(configValue)"
              >
              </el-input>
              <div style="text-align: right; margin-top: 10px">
                <el-button type="primary" size="mini" @click="submitConfigValue(item, index, 'workConfigList4')">确 定</el-button>
              </div>
              <a slot="reference" class="vatgroup" @click="configValue = ''">{{item.name}}</a>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <!-- <el-input
              v-model="scope.row.workConfigList4[index].configValue"
              clearable
            ></el-input> -->
            <el-input
              v-model="scope.row.workConfigList4[index].configValue"
              clearable
              class="right-input"
              v-if="scope.row.workConfigList4[index] && scope.row.workConfigList4[index].showType == '1'"
            >
              <template slot="append">%</template>
            </el-input>
            <el-select
              v-model="scope.row.workConfigList4[index].configValue"
              filterable
              clearable
              style="width:100%"
              v-if="scope.row.workConfigList4[index] && scope.row.workConfigList4[index].showType == '2'"
            >
              <el-option 
                v-for="(val, id) in JSON.parse(scope.row.workConfigList4[index].options)"
                :key="id"
                :label="val.dictLabel"
                :value="val.dictValue + ''"
              ></el-option>
            </el-select>
            <el-input
              v-model="scope.row.workConfigList4[index].configValue"
              clearable
              v-if="scope.row.workConfigList4[index] && scope.row.workConfigList4[index].showType == '3'"
            >
            </el-input>
            <el-date-picker
              v-if="scope.row.workConfigList4[index] && scope.row.workConfigList4[index].showType == '4'" 
              v-model="scope.row.workConfigList4[index].configValue" 
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              style="width: 100%">
            </el-date-picker>
            <el-input
              v-model="scope.row.workConfigList4[index].configValue"
              clearable
              v-if="scope.row.workConfigList4[index] && scope.row.workConfigList4[index].showType == '5'"
              class="right-input"
              @input="scope.row.workConfigList4[index].configValue=scope.row.workConfigList4[index].configValue.replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
              @blur="configComdify(scope.row.workConfigList4[index].configValue, index, scope.$index)"
              @focus="configDelComdify(scope.row.workConfigList4[index].configValue, index, scope.$index)"
            >
            </el-input>
          </template>
        </el-table-column>
        <!-- 备注 -->
        <el-table-column
          prop="remark"
          label="备注"
          min-width="150">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remark" />
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="60"
          fixed="right"
          align="right"
          >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleDelete(scope.row, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      
      <table style="width: 100%" v-if="ofoFlowWork.costmanagetype == '4'">
        <tr style="height: 30px">
          <td style="width: 150px">合计</td>
          <td>金额：{{ptotalNovat | comdify}}</td>
        </tr>
      </table>
      <table style="width: 100%" v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
        <tr style="height: 30px">
          <td style="width: 150px">合计</td>
          <td>金额：{{ptotalNovat | comdify}}</td>
          <td>税额：{{ptotalVat | comdify}}</td>
        </tr>
        <tr>
          <td style="width: 150px"></td>
          <td>价税合计: {{ptotal | comdify}}</td>
        </tr>
      </table>
      <table style="width: 100%; margin-top:10px;border-top: 1px solid #eeeeee" v-if="ofoFlowWork.costmanagetype == '2'">
        <tr style="height: 30px">
          <td style="width: 150px">成本合计</td>
          <td>成本金额：{{ctotalNovat | comdify}}</td>
          <td>成本税额：{{ctotalVat | comdify}}</td>
        </tr>
        <tr>
          <td style="width: 150px"></td>
          <td>成本价税合计: {{ctotal | comdify}}</td>
        </tr>
      </table>
    </template>
    
    <!-- 查看时不可编辑的表格 -->
    <!-- ofoFlowWork.isCalcBom == '0' ? '#b4b4b4' :  -->
    <template v-if="disabled">
      <el-table
        :data="productsList"
        :header-cell-style="{background: '#F9F9FB',color:'#606266'}"
        :key="Math.random()"
        border
        :row-key="getRowKey"
        default-expand-all
        ref="noEditTable"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        :row-style="tableTreeRowStyle">
        <!-- <el-table-column
          label="1111"
          width="50">
          111
        </el-table-column> -->
        <el-table-column
          type="index"
          label="行号"
          width="50">
        </el-table-column>
        <!-- 位置 -->
        <el-table-column
          prop="cbowhName"
          label="位置"
          width="200"
          type=""
          v-if="ofoFlowWork.chooseOwh == '0'">
        </el-table-column>
        <!-- 货物或应税劳务、服务名称 -->
        <el-table-column
          prop="ofrresourceName"
          :label="ofoFlowWork.kindtypeName"
          min-width="200">
        </el-table-column>
        <!-- <el-table-column v-for="(item, index) in obussDimeList" :key="index" :label="item.dimeName" :prop="item.dimeValue">
          <template slot-scope="scope">
            <span>{{scope.row.obussDimeList[index].dimeValue}}</span>
          </template>
        </el-table-column> -->
        <!-- 起始数量 -->
        <el-table-column
          prop="beginValue"
          label="起始数量"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '8'">
        </el-table-column>
        <!-- 终止数量 -->
        <el-table-column
          prop="endValue"
          label="终止数量"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '8'">
        </el-table-column>
        <!-- 数量期间 -->
        <el-table-column
          prop="quantity"
          label="数量"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '8'">
          <template slot-scope="scope">{{scope.row.quantity}}
          </template>
        </el-table-column>
        <!-- 数量 -->
        <el-table-column
          :prop="'quantity' + item.ofoFlowWorkConfigId"
          min-width="150"
          align="right"
          v-for="(item, index) in workConfig.workConfigList1"
          :key="item.ofoFlowWorkConfigId"
          :label="item.name"
          v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4' && !ofoFlowWork.thingtype">
          <template slot-scope="scope">
            <DictTag :options="JSON.parse(scope.row.workConfigList1[index].options)" :value="scope.row.workConfigList1[index].configValue" v-if="scope.row.workConfigList1[index].showType == '2'"/>
            <div v-else>
              {{scope.row.workConfigList1[index].configValue}}
              <span v-if="scope.row.workConfigList1[index].showType == '1' && scope.row.workConfigList1[index].configValue">%</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="quantity"
          label="数量"
          min-width="150"
          align="right"
          v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'">
          <!-- <template slot-scope="scope">{{comdifyPrams1(scope.row.quantity)}}</template> -->
        </el-table-column>
        <el-table-column
          prop="quantityKz"
          label="(控制)数量"
          min-width="150"
          align="right"
          v-if="limit.quantity && !ofoFlowWork.thingtype && limit.quantity.limitRate">
          <template slot-scope="scope">
            {{scope.row.ofoFlowWorkLimitShow.quantity}}
          </template>
        </el-table-column>
        <el-table-column
          prop="quantityQz"
          label="(前置)数量"
          min-width="150"
          align="right"
          v-if="limit.quantity && !ofoFlowWork.thingtype">
          <template slot-scope="scope">
            {{scope.row.ofoFlowWorkLimitShow.preQuantity}}
          </template>
        </el-table-column>
        <!-- 开始时间 -->
        <el-table-column
          prop="beginValue"
          label="开始时间"
          width="250"
          align="center"
          v-if="ofoFlowWork.costmanagetype == '7'">
        </el-table-column>
        <!-- 结束时间 -->
        <el-table-column
          prop="endValue"
          label="结束时间"
          width="250"
          align="center"
          v-if="ofoFlowWork.costmanagetype == '7'">
        </el-table-column>
        <!-- 时间期间 -->
        <el-table-column
          prop="quantity"
          label="时间期间"
          v-if="ofoFlowWork.costmanagetype == '7'">
          <template slot-scope="scope">{{scope.row.quantity | filter_handleTimeRange}}
          </template>
        </el-table-column>
        <!-- 单价 -->
        <el-table-column
          :prop="'price' + item.ofoFlowWorkConfigId"
          min-width="150"
          align="right"
          v-for="(item, index) in workConfig.workConfigList2"
          :key="item.ofoFlowWorkConfigId"
          :label="item.name"
          v-if="(ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5') && !ofoFlowWork.thingtype">
          <template slot-scope="scope">
            <DictTag :options="JSON.parse(scope.row.workConfigList2[index].options)" :value="scope.row.workConfigList2[index].configValue" v-if="scope.row.workConfigList2[index].showType == '2'"/>
            <div v-else>
              {{scope.row.workConfigList2[index].configValue | comdify('no')}}
              <span v-if="scope.row.workConfigList2[index].showType == '1' && scope.row.workConfigList2[index].configValue">%</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="单价（含税）"
          min-width="150"
          align="right"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">{{comdifyPrams(scope.row.price)}}</template>
        </el-table-column>
        <el-table-column
          prop="priceKz"
          label="(控制)单价"
          min-width="150"
          align="right"
          v-if="limit.price && !ofoFlowWork.thingtype && limit.price.limitRate">
          <template slot-scope="scope">
            {{scope.row.ofoFlowWorkLimitShow.price | comdify}}
          </template>
        </el-table-column>
        <el-table-column
          prop="priceQz"
          label="(前置)单价"
          min-width="150"
          align="right"
          v-if="limit.price && !ofoFlowWork.thingtype">
          <template slot-scope="scope">
            {{scope.row.ofoFlowWorkLimitShow.prePrice | comdify}}
          </template>
        </el-table-column>
        <!-- 金额 -->
        <el-table-column
          :prop="'total' + item.ofoFlowWorkConfigId"
          min-width="150"
          align="right"
          v-for="(item, index) in workConfig.workConfigList3"
          :key="item.ofoFlowWorkConfigId"
          :label="item.name"
          v-if="(ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '4' || ofoFlowWork.costmanagetype == '5') && !ofoFlowWork.thingtype">
          <template slot-scope="scope">
            <DictTag :options="JSON.parse(scope.row.workConfigList3[index].options)" :value="scope.row.workConfigList3[index].configValue" v-if="scope.row.workConfigList3[index].showType == '2'"/>
            <div v-else>
              {{scope.row.workConfigList3[index].configValue | comdify('no')}}
              <span v-if="scope.row.workConfigList3[index].showType == '1' && scope.row.workConfigList3[index].configValue">%</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="total"
          label="金额（含税）"
          min-width="150"
          align="right"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">{{scope.row.total | comdify}}</template>
        </el-table-column>
        <el-table-column
          prop="lineTotalKz"
          label="(控制)金额"
          min-width="150"
          align="right"
          v-if="limit.lineTotal && !ofoFlowWork.thingtype && limit.price.limitRate">
          <template slot-scope="scope">
            {{scope.row.ofoFlowWorkLimitShow.lineTotal | comdify}}
          </template>
        </el-table-column>
        <el-table-column
          prop="lineTotalQz"
          label="(前置)金额"
          min-width="150"
          align="right"
          v-if="limit.lineTotal && !ofoFlowWork.thingtype">
          <template slot-scope="scope">
            {{scope.row.ofoFlowWorkLimitShow.preLineTotal | comdify}}
          </template>
        </el-table-column>
        <!-- 费用金额 -->
        <el-table-column
          prop="total"
          label="费用金额"
          min-width="200"
          align="right"
          v-if="ofoFlowWork.costmanagetype == '4'">
          <template slot-scope="scope">{{scope.row.total | comdify}}</template>
        </el-table-column>
        <!-- 税率 -->
        <el-table-column
          prop="vatgroup"
          label="税率"
          align="center"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">
            <DictTag :options="dropDown.invoiceVat" :value="scope.row.vatgroup"/>
          </template>
        </el-table-column>
        <!-- 税额 -->
        <el-table-column
          prop="totalVat"
          label="税额"
          align="right"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">{{scope.row.totalVat | comdify}}</template>
        </el-table-column>
        <!-- 票种 -->
        <el-table-column
          prop="invoicetype"
          label="票种"
          align="center"
          min-width="200"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">
            <DictTag :options="dropDown.invoiceType" :value="scope.row.invoicetype"/>
          </template>
        </el-table-column>
        <!-- 成本单价 -->
        <el-table-column
          prop="cost"
          align="right"
          min-width="150"
          label="成本单价（含税）"
          v-if="ofoFlowWork.costmanagetype == '2'">
          <template slot-scope="scope">{{comdifyPrams(scope.row.cost)}}</template>
        </el-table-column>
        <!-- 成本金额 -->
        <el-table-column
          prop="costTotal"
          align="right"
          min-width="150"
          label="成本金额（含税）"
          v-if="ofoFlowWork.costmanagetype == '2'">
          <template slot-scope="scope">{{scope.row.costTotal | comdify}}</template>
        </el-table-column>
        <!-- 成本税额 -->
        <el-table-column
          prop="costTotalVat"
          label="成本税额"
          align="right"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '2'">
          <template slot-scope="scope">{{scope.row.costTotalVat | comdify}}</template>
        </el-table-column>
        <!-- 自定义 -->
        <el-table-column
          :prop="'customize' + item.ofoFlowWorkConfigId"
          min-width="150"
          align="center"
          v-for="(item, index) in workConfig.workConfigList4"
          :key="index"
          :label="item.name"
          v-if="!ofoFlowWork.thingtype">
          <template slot-scope="scope">
            <DictTag :options="JSON.parse(scope.row.workConfigList4[index].options)" :value="scope.row.workConfigList4[index].configValue" v-if="scope.row.workConfigList4[index].showType == '2'"/>
            <template v-else-if="scope.row.workConfigList4[index].showType == '1'">
              {{scope.row.workConfigList4[index].configValue}}
              <span v-if="scope.row.workConfigList4[index].showType == '1' && scope.row.workConfigList4[index].configValue">%</span>
            </template>
            <!-- <template v-else-if="scope.row.workConfigList4[index].showType == '5'">
              {{scope.row.workConfigList4[index].configValue}}
            </template> -->
            <template v-else>
              {{scope.row.workConfigList4[index].configValue}}
            </template>
          </template>
        </el-table-column>
        <!-- 备注 -->
        <el-table-column
          prop="remark"
          label="备注"
          min-width="150">
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="disabled"
        @size-change="handleSizeChange"
        background
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 50, 100, 200]"
        :page-size="pageSize"
        layout="->, prev, pager, next, sizes, jumper, total"
        :total="total"
        style="margin: 16px 0"
      >
      </el-pagination>
      <table style="width: 100%" v-if="ofoFlowWork.costmanagetype == '4'">
        <tr style="height: 30px">
          <td style="width: 150px">合计</td>
          <td>金额：{{ptotalNovat1 | comdify}}</td>
        </tr>
      </table>
      <table style="width: 100%" v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
        <tr style="height: 30px">
          <td style="width: 150px">合计</td>
          <td>金额：{{ptotalNovat1 | comdify}}</td>
          <td>税额：{{ptotalVat1 | comdify}}</td>
        </tr>
        <tr>
          <td style="width: 150px"></td>
          <td>价税合计: {{ptotal1 | comdify}}</td>
        </tr>
      </table>
      <table style="width: 100%" v-if="limit.total && !ofoFlowWork.thingtype && limit.total.limitRate">
        <tr style="height: 30px">
          <td style="width: 150px">控制合计</td>
          <td>{{resLimit | comdify}}</td>
        </tr>
      </table>
      <table style="width: 100%" v-if="limit.total && !ofoFlowWork.thingtype">
        <tr style="height: 30px">
          <td style="width: 150px">前置合计</td>
          <td>{{resPreLimit | comdify}}</td>
        </tr>
      </table>
      <table style="width: 100%; margin-top:10px;border-top: 1px solid #eeeeee" v-if="ofoFlowWork.costmanagetype == '2'">
        <tr style="height: 30px">
          <td style="width: 150px">成本合计</td>
          <td>成本金额：{{ctotalNovat1 | comdify}}</td>
          <td>成本税额：{{ctotalVat1 | comdify}}</td>
        </tr>
        <tr>
          <td style="width: 150px"></td>
          <td>成本价税合计: {{ctotal1 | comdify}}</td>
        </tr>
      </table>
    </template>
    <!-- 复制从列表对话框 -->
    <el-dialog
      :title="`从 ${ofoFlowWork.beforeworkName} 复制到 ${ofoFlowWork.name}`"
      :visible.sync="dialogCopyFromVisible"
      width="70%"
      append-to-body
      top="59px">
      <div slot="title">
        {{`从 ${ofoFlowWork.beforeworkName} 复制到 ${ofoFlowWork.name}`}}
        <!-- <el-button size="mini" style="margin-left:16px" @click="selectAll">全选</el-button> -->
      </div>
      <el-table
        :data="copyFromList"
        height="100%"
        style="width: 100%;overflow: auto"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
        v-loading="loading"
        :row-class-name="tableRowClassName"
        ref="tables"
        @cell-click="cellClick"
        @select="selectFather"
        @select-all="selectAllFather"
        @expand-change="expandChange"
      >
      <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table
              :data="props.row.ofoWorkResourceList"
              @select="(selection,row)=>selectChild(selection, row, props.$index)"
              @select-all="selectAllChild($event,props.$index)"
              :ref="'tableCopyFrom' + props.$index"
              @row-click="(row,column,e)=>handleCopyFromRowClick(row,column,e,props.$index)"
              :row-key="getRowKeyCopyFrom"
              style="width:97%; float:right"
              default-expand-all
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            >
              <el-table-column
                type="selection"
                width="55"
                :reserve-selection="true">
              </el-table-column>
              <el-table-column
                prop="ofrresourceName"
                label="产品名称">
              </el-table-column>
              <el-table-column
                prop="cbowhName"
                label="位置">
              </el-table-column>
              <template v-if="!ofoFlowWork.beforeworkConfig || ofoFlowWork.beforeworkConfig.indexOf('1') != -1">
                <!-- 数量 -->
                <el-table-column
                  prop="quantity"
                  align="right"
                  label="数量">
                </el-table-column>
                <!-- 剩余数量 -->
                <el-table-column
                  prop="openqty"
                  align="right"
                  label="剩余数量">
                  <template slot-scope="scope">
                    <strong style="font-size:18px">{{scope.row.openqty}}</strong>
                  </template>
                </el-table-column>
              </template>
              <template v-if="!ofoFlowWork.beforeworkConfig || ofoFlowWork.beforeworkConfig.indexOf('3') != -1">
                <!-- 金额 -->
                <el-table-column
                  prop="total"
                  align="right"
                  label="金额（含税）">
                  <template slot-scope="scope">{{scope.row.total | comdify}}</template>
                </el-table-column>
                <!-- 剩余金额 -->
                <el-table-column
                  prop="openqty"
                  align="right"
                  label="剩余金额">
                  <template slot-scope="scope">
                    <strong style="font-size:18px">{{scope.row.opentotal | comdify}}</strong>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称">
        </el-table-column>
        <!-- 数量 -->
        <el-table-column
          prop="code"
          label="编号">
        </el-table-column>
        <!-- 金额 -->
        <!-- <el-table-column
          prop="total"
          align="right"
          label="金额（含税）">
          <template slot-scope="scope">{{scope.row.ptotal | comdify}}</template>
        </el-table-column> -->
        <!-- 活动日期 -->
        <el-table-column
          prop="total"
          align="center"
          label="日期">
          <template slot-scope="scope">{{scope.row.workDate | filter_handleDate}}</template>
        </el-table-column>
        <!-- 交易方 -->
        <el-table-column
          prop="dealName"
          label="交易方"
         >
        </el-table-column>
        <!-- 备注 -->
        <el-table-column
          prop="remarks"
          label="备注">
        </el-table-column>
        <!-- <el-table-column
          align="right"
          width="150">
          <template slot="header">
            <el-input
              v-model="search1"
              size="small"
              placeholder="输入名称搜索"
              clearable/>
          </template>
        </el-table-column> -->
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCopyFromVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmCopyFrom">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 多选 -->
    <el-dialog :title="ofoFlowWork.kindtypeName" :visible.sync="dialogTableVisible" append-to-body top="59px" width="60%">
      <el-table 
        :data="goodsList.filter(data => 
          (!search || data.resourceName.toLowerCase().includes(search.toLowerCase()))
        )"
        @selection-change="handleMultipleSelectionChange"
        ref="tableMultiple"
        @row-click="handleMultipleRowClick"
        :row-key="getRowKeyMultiple"
        :height="'70vh'"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true"> </el-table-column>
        <el-table-column property="ofrResourceId" label="编号" width="80"></el-table-column>
        <el-table-column property="resourceName" label="名称" min-width="150"></el-table-column>
        <el-table-column width="240" v-if="ofoFlowWork.isShowResourceStock == '0'">
          <template slot="header">
            <div style="display:flex;justify-content:space-between">
              <div>位置</div>
              <div>库存</div>
            </div>
          </template>
          <template slot-scope="scope">
            <el-table 
              v-if="scope.row.ofrOwhResourceList.length"
              :data="scope.row.ofrOwhResourceList"
              :show-header="false"
            >
            
              <el-table-column property="owhName" width="120"></el-table-column>
              <el-table-column property="resourceQuantity" width="100" align="right" header-align="center"></el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column property="resourceQuantity" label="总库存" width="100" align="right" header-align="center" v-if="ofoFlowWork.isShowResourceStock == '0'">
          <template slot-scope="scope">
            {{calcStock(scope.row.ofrOwhResourceList)}}
          </template>
        </el-table-column>
        <el-table-column property="availableQuantityList" label="可用数量" width="100" align="right" header-align="center">
          <template slot-scope="scope">
            {{handleCalc(scope.row.availableQuantityList)}}
          </template>
        </el-table-column>
        <el-table-column
          align="right"
          width="150">
          <template slot="header">
            <el-input
              v-model="search"
              size="small"
              placeholder="输入名称搜索"
              clearable/>
          </template>
        </el-table-column>
      </el-table>
      
      <div slot="footer" class="dialog-footer" style="display:flex;justify-content:space-between">
        <div>
          已选{{goods.length}}行
          <el-checkbox v-model="checked">是否带入可用数量</el-checkbox>
        </div>
        <div>
          <el-button @click="dialogTableVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmMultiple">确 定</el-button>
        </div>
        
      </div>
    </el-dialog>
    <!-- 新增资源 -->
    <el-dialog
      title="新增资源"
      :visible.sync="dialogResourceVisible"
      append-to-body
      top="59px"
      class="res-dialog">
      <el-form
        :model="resourceForm"
        :rules="rulesReource"
        ref="resourceForm"
        class="demo-ruleForm"
        label-position="right"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="选择资源" prop="cbRestypeId">
              <el-select v-model="resourceForm.cbRestypeId" style="width:98%" filterable @change="changeRes">
                <el-option 
                  v-for="item in cbResourceTypeList"
                  :label="item.restypeName"
                  :value="item.cbRestypeId"
                  :key="item.cbRestypeId"
                  v-if="item.isAdd == '0'"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="资源名称" prop="resourceName">
              <el-input
                v-model="resourceForm.resourceName"
                placeholder="请输入资源名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="描述" prop="remarks">
              <el-input
                v-model="resourceForm.remarks"
                placeholder="请输入描述"
                style="width:97%"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <Form :formList="resourceFormList" :gutter="20" :ruleForm="resourceForm"/>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogResourceVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitResourceForm('resourceForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 多行编辑 -->
    <el-dialog
      :title="(sel == 'vatgroup') ? '选择税率' : ((sel == 'invoicetype') ? '选择票种' : ((sel == 'cbowhId') ? '选择仓库' : ''))"
      :visible.sync="dialogSelectVisible"
      append-to-body
      class="res-dialog"
      top="59px"
      width="25%">
      <el-radio-group v-model="select" v-if="sel == 'vatgroup'">
        <el-radio v-for="(item, index) in dropDown.invoiceVat" :key="index" :label="item.dictValue" style="margin-bottom:16px;">{{item.dictLabel}}</el-radio>
      </el-radio-group>
      <el-radio-group v-model="select" v-if="sel == 'invoicetype'">
        <el-radio v-for="(item, index) in dropDown.invoiceType" :key="index" :label="item.dictValue" style="margin-bottom:16px">{{item.dictLabel}}</el-radio>
      </el-radio-group>
      <el-tree
        v-if="sel == 'cbowhId'"
        ref="treeList"
        :data="cbOwhData1"
        :props="{label: 'name', id: 'cbOwhId'}"
        node-key="cbOwhId"
        check-strictly
        highlight-current
        :expand-on-click-node="false"
        @node-click="handleNodeClick"
        default-expand-all
        >
      </el-tree>
      <!-- <el-select v-model="select" filterable clearable style="width:100%" v-if="sel == 'cbowhId'">
        <el-option 
        v-for="(item, index) in cbOwhData"
        :key="index"
        :label="item.name"
        :value="item.cbOwhId"
        ></el-option>
      </el-select> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogSelectVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitSelect">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导入对话框 -->
    <el-dialog
      :title="`导入${ofoFlowWork.kindtypeName}`"
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body
      top="59px"
      class="res-dialog"
      >
      <el-upload
        class="upload-demo"
        :auto-upload="false"
        drag
        action="#"
        multiple
        accept=".xlsx, .xls"
        :show-file-list="false"
        :on-change="handleChange">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" @click="exportBtn">下载模板</el-link>
        </div>
      </el-upload>
      <span style="margin-top:10px;color:red;display:inline-block">注：黄色字体表示选中多条资源</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
import DimeTag from '@/components/DimeTag.vue'
import {mapState} from 'vuex'
import Form from '@/components/Form.vue'
import handleTree from '@/utils/handleTree'
import handleList from '@/utils/handleList'
import handleWorkConfigList from '@/utils/handleWorkConfigList'
import qs from 'qs'
import * as XLSX from 'xlsx'
  export default {
    name: 'product',
    components: {
      DictTag,
      Form,
      DimeTag
    },
    props: {
      disabled: Boolean,
      ofoFlowWork: Object,
      productsList: Array,
      // documentstatus: String,
      ofoWorkId: [Number, String],
      total: [Number, String],
      pageNum: [Number, String],
      pageSize: [Number, String],
      ctotal1: [Number, String],
      ctotalNovat1: [Number, String],
      ctotalVat1: [Number, String],
      ptotal1: [Number, String],
      ptotalNovat1: [Number, String],
      ptotalVat1: [Number, String],
      workConfig: Object,
      limit: {
        type: Object,
        default: ()=>({})
      },
      resLimit: {
        type: [Number, String],
        default: ''
      },
      resPreLimit: {
        type: [Number, String],
        default: ''
      },
      params: {
        type: Object,
        default: () => ({
          priceAccuracy: 2,
          quantityAccuracy: 8
        })
      },
      // index: {
      //   type: [Number, String],
      //   default: 0
      // },
      // cbOwhData: Array,
      // goodsList: Array
    },
    data() {
      return {
        isTax: true, // 是否含税
        // productsList: [], // 产品列表
        cbOwhData: [],
        goodsList:[], // 货物或应税劳务、服务名称列表
        ofoWorkThingList: [], // 明细列表
        dialogTableVisible: false,
        goods: [],
        startDate: '',
        endDate: '',
        startCount: '',
        endCount: '',
        dialogCopyFromVisible: false, // 复制从对话框
        copyFromList: [], // 复制从列表
        comfirmCopyFromList: [], // 勾选的复制从列表
        loading: true,
        search: '',
        tableOption: [],
        searchCopyFrom: '',
        dialogFormVisible: false,
        ruleForm: {
          resourceName: '',
          remarks: ''
        },
        formList: [],
        rules: {
          resourceName: [{ required: true, message: "请输入名称", trigger: "submit" }],
          documentstatus: [ { required: true, message: "请选择状态", trigger: "submit" }],
        },
        isDisabled: false,
        dialogResourceVisible: false,
        resourceForm: {
          resourceName: '',
          remarks: '',
          documentstatus: '1',
          cbRestypeId: '',
          dataSourceConfig: []
        },
        rulesReource: {
          resourceName: [{ required: true, message: "请输入资源名称", trigger: "change" }]
        },
        resourceFormList: [],
        cbResourceType: {},
        flag: true,
        search1: '',
        selectedId: [],
        selectAllChildMap: new Map(),
        // pageNum: 1,
        // pageSize: 10,
        // total: 20
        select: '',
        dialogSelectVisible: false,
        // index: 0,
        isAll: true,
        sel: '',
        isAdd: '',
        cbResourceTypeList: [],
        ofrResourceId: '',
        ofrresourceName: '',
        isAddRes: false,
        editResIndex: 0,
        configValue: '',
        dialogVisible: false,
        tableData: [],
        isMultiRes: false,
        row: {},
        index: 0,
        checked: false
      }
    },
    created () {
      // console.log(11111);
      this.getList();
      this.productsList.forEach(item => {
        this.isTax = !item.isVat
      })
    },
    methods: {
      handleCalc(list) {
        // console.log(JSON.parse(JSON.stringify(list)));
        // if(list.length && list.every(item => item.showValue)) {
        if(list && list.length) {
          let arr = []
          let length = list.length
          for(let i = 0; i < (length - 1); i++) {
            arr.push(list[i].brackets && list[i].brackets.includes('(') ? list[i].brackets : '', list[i].showValue, list[i].brackets && list[i].brackets.includes(')') ? list[i].brackets : '', this.handleCalcu(list[i].calcu))
          }
          arr.push(list[length - 1].brackets && list[length - 1].brackets.includes('(') ? list[length - 1].brackets : '', list[length - 1].showValue, list[length - 1].brackets && list[length - 1].brackets.includes(')') ? list[length - 1].brackets : '')
          
          // if(showType == '1') {
          //   return eval(arr.join('')).toFixed(4)
          // }else if(showType == '2') {
          //   return eval(arr.join('')).toFixed(2)
          // }else if(showType == '3') {
          //   return (eval(arr.join('')) * 100).toFixed(0) + '%'
          // }else {
            return eval(arr.join(''))
          // }
        }else {
          return ''
        }
      },
      configComdify(n, index, rowId) {
        if(!n) return '';
        n = Number(n).toFixed(2)
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        this.productsList[rowId].workConfigList4[index].configValue = `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
      },
      configDelComdify(n, index, rowId) {
        // let obj = JSON.parse(JSON.stringify(item))
        if(!n) return '';
        let numS = n;
        numS = numS.toString();
        this.productsList[rowId].workConfigList4[index].configValue = numS.replace(/,/gi, '');
      },
      calcStock(arr) {
        if(arr.length) {
          return arr.reduce((prev, cur, index, arr) => {
            return prev + cur.resourceQuantity
          }, 0)
        }else {
          return ''
        }
      },
      handleChange(file, fileList) {
        const loading = this.$loading({
          lock: true,
          text: '导入中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.5)'
        });
        let that = this
        let reader = new FileReader()
        reader.readAsArrayBuffer(file.raw)
        reader.onload = function () {
          let buffer = reader.result
          let bytes = new Uint8Array(buffer)
          let length = bytes.byteLength
          let binary = ''
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i])
          }
          let wb = XLSX.read(binary, {
            type: 'binary',
            cellDates: true
          })
          let outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
          let da = [...outdata];
          // console.log(JSON.parse(JSON.stringify(da)));
          // 这里是把表格里面的名称改成表格里面的字段
          da.map((item, index) => {
            let newItem = {}
            that.tableData.forEach(val => {
              for(let key in item) {
                if(key == val.label) {
                  if(val.prop == 'vatgroup') {
                    // console.log(item[key]);
                    newItem[val.prop] = item[key].toFixed(2)
                  }else if(val.prop == 'invoicetype') {
                    that.dropDown.invoiceType.forEach(el => {
                      if(el.dictLabel == item[key]) {
                        newItem[val.prop] = el.dictValue + ''
                      }
                    })
                  }else if(val.prop == 'price' || val.prop == 'total') {
                    newItem[val.prop] = that.comdify(item[key] + '')
                  }else if(val.prop == 'resDime') {
                    
                  }else if(((that.ofoFlowWork.costmanagetype == '7') && (val.prop == 'beginValue' || val.prop == 'endValue'))) {
                    newItem[val.prop] = that.parseTime(new Date(item[key]).getTime() + 43000, '{y}-{m}-{d} {h}:{i}')
                  }else {
                    newItem[val.prop] = item[key] + ''
                  }
                  break
                }else {
                  newItem[val.prop] = ''
                }
              }
            })
            // 初始化资源行配置
            newItem.workConfigList1 = (that.ofoFlowWork.checkConfig && that.workConfig.workConfigList1) ? JSON.parse(JSON.stringify(that.workConfig.workConfigList1)) : []
            newItem.workConfigList2 = (that.ofoFlowWork.checkConfig && that.workConfig.workConfigList2) ? JSON.parse(JSON.stringify(that.workConfig.workConfigList2)) : []
            newItem.workConfigList3 = (that.ofoFlowWork.checkConfig && that.workConfig.workConfigList3) ? JSON.parse(JSON.stringify(that.workConfig.workConfigList3)) : []
            newItem.workConfigList4 = (that.ofoFlowWork.checkConfig && that.workConfig.workConfigList4) ? JSON.parse(JSON.stringify(that.workConfig.workConfigList4)) : []
            for(let key1 in newItem) {
              if(key1 == 'workConfigList1' || key1 == 'workConfigList2' || key1 == 'workConfigList3' || key1 == 'workConfigList4') {
                newItem[key1].forEach(val => {
                  for(let key in item) {
                    if(val.name == key) {
                      val.configValue = item[key]
                      break
                    }
                  }
                })
                if(key1 == 'workConfigList1' || key1 == 'workConfigList2' || key1 == 'workConfigList3') {
                  let result = newItem[key1].some((item => {
                    return item.configValue
                  }))
                  if(result) { // 填了资源行，计算资源行
                    that.computeSl(newItem, undefined, key1)
                  }else { // 没有资源行，计算金额税额
                    that.compute1(newItem)
                  }
                }else {
                  // console.log(JSON.parse(JSON.stringify(newItem[key1])));
                  newItem[key1].forEach(val => {
                    if(val.showType == '1') {
                      val.configValue *= 100
                    }else if(val.showType == '2') {
                      let obj = JSON.parse(val.options).find(item => item.dictLabel == val.configValue)
                      val.configValue = obj ? String(obj.dictValue) : ''
                    }else if(val.showType == '4') {
                      val.configValue = that.parseTime(new Date(val.configValue).getTime() + 43000, '{y}-{m}-{d}')
                    }else if(val.showType == '5') {
                      val.configValue = that.comdify(val.configValue)
                    }
                  })
                }
                
              }
            }
            // 初始化资源名称和id
            newItem.ofrresourceId = ''
            that.goodsList.forEach(val => {
              if(val.name.trim() == newItem.ofrresourceName.trim() || val.resourceName.trim() == newItem.ofrresourceName.trim()) {
                if(!newItem.ofrresourceId) { // 只找到一条资源
                  newItem.ofrresourceId = val.ofrResourceId + ''
                }else { // 找到多条资源
                  newItem.ofrresourceId = newItem.ofrresourceId + ',' + val.ofrResourceId
                }
              }
            })
            if(newItem.ofrresourceId.includes(',')) { // 找到多条资源
              newItem.isMultiRes = newItem.ofrresourceId.split(',').length
            }else if(!newItem.ofrresourceId) { // 一条资源都没有
              that.$http.post(url.addOfrResource, qs.stringify({
                cbRestypeId: that.cbResourceTypeList[0].cbRestypeId,
                documentstatus: '1',
                resourceName: newItem.ofrresourceName
              })).then(res => {
                if(res.data.code === 200) {
                  newItem.ofrresourceId = res.data.data
                  that.$http.get(url.getResTypesAndList, {params:
                    {
                      cbRestypeIds: that.ofoFlowWork.kindtype,
                    }
                  }).then(res => {
                    if(res.data.code == 200) {
                      that.goodsList = res.data.data.list
                      that.goodsList.forEach(item => {
                        if(item.ofrResourceId == that.ofrResourceId) {
                          that.ofrresourceName = item.resourceName
                        }
                        item.name = item.resourceName.split(':')[0]
                      })
                    }
                  })
                }
              })
            }else { // 只找到一条资源
              newItem.isMultiRes = 1
            }
            // 初始化位置
            newItem.cbowhId = (that.ofoFlowWork.chooseOwh == '0' && that.cbOwhData.length) ? that.cbOwhData[0].cbOwhId : ''
            newItem.cbowhName = (that.ofoFlowWork.chooseOwh == '0' && that.cbOwhData.length) ? that.cbOwhData[0].name : ''
            // 初始化成本
            newItem.cost = ''
            newItem.costTotal = ''
            newItem.costTotalVat = ''
            if(newItem.beginValue && newItem.endValue) {
              if(that.ofoFlowWork.costmanagetype == '7') {
                that.handleChangeSj1(newItem)
              }else if(that.ofoFlowWork.costmanagetype == '8') {
                that.handleCount1(newItem)
              }
            }
            newItem.bomId = -that.index - 1
            that.index ++
            // newItem.beginValue = ''
            // newItem.endValue = ''
            // console.log(JSON.parse(JSON.stringify(newItem)));
            that.productsList.push(newItem)
          })
          // console.log(JSON.parse(JSON.stringify(that.productsList)));
          loading.close()
          that.dialogVisible = false
        }
      },
      // 导入按钮
      async importBtn() {
        this.dialogVisible = true
        this.tableData = []
        let dime = null
        let newDime = []
        let thingIndex = null
        // await this.$http.get(url.getImportResTypeList, {
        //   params: {
        //     kind: this.cbResourceTypeList[0].kind,
        //     restypeName: this.cbResourceTypeList[0].restypeName
        //   },
        // }).then(res => {
        //   dime = res.data.data.list[0].dime
        //   thingIndex = dime.indexOf('内容名称') - 2
        //   dime = dime.slice(6, thingIndex).split(', ')
        //   dime.forEach(item => {
        //     newDime.push({
        //       label: item,
        //       prop: 'resDime'
        //     })
        //   })
        // })
        this.$refs.multipleTable.$children.forEach(item => {
          if(item.prop != undefined && item.prop != 'cbowhId' && item.prop != 'cost' && item.prop != 'costTotal' && item.prop != 'costTotalVat') {
            this.tableData.push({
              label: item.label,
              prop: item.prop
            })
          }
        })
        this.tableData.splice(1, 0, ...newDime)
      },
      // 导出按钮
      async exportBtn() {
        let arr = this.tableData.map(obj => {return obj.label})
        let workSheet = XLSX.utils.aoa_to_sheet([arr]);
        workSheet["!cols"] = []
        for(var i = 0; i < arr.length; i ++){
          workSheet["!cols"][i] = {wpx: 80}
        }
        let bookNew = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(bookNew, workSheet, this.ofoFlowWork.kindtypeName) // 工作簿名称
        let name = this.ofoFlowWork.kindtypeName + '.xlsx'
        XLSX.writeFile(bookNew, name) // 保存的文件名
      },
      handleNodeClick(data, node, component) {
        this.select = data.cbOwhId
      },
      changeVatgroup(type, sel, value, id, row) {
        this.dialogSelectVisible = true
        this.sel = sel
        if(type == 'all') {
          this.select = ''
          if(this.sel == 'cbowhId') {
            this.$nextTick(function () {
              this.$refs.treeList.setCurrentKey(null);
            });
          }
          this.isAll = true
        }else {
          this.select = value
          if(this.sel == 'cbowhId') {
            this.$nextTick(function () {
              this.$refs.treeList.setCurrentKey(value);
            });
          }
          // this.index = id
          this.row = row
          this.isAll = false
        }
      },
      submitSelect() {
        if(this.select) {
          if(this.isAll) {
            if(this.productsList.length) {
              this.changeSelect(this.productsList)
            }
          }else {
            // this.productsList[this.index][this.sel] = this.select
            // if(this.sel == 'vatgroup') {
            //   this.compute4( this.productsList[this.index])
            // }else if(this.sel == 'cbowhId') {
            //   this.productsList[this.index].cbowhName = this.changeCbowh(this.select)
            // }
            this.row[this.sel] = this.select
            if(this.sel == 'vatgroup') {
              this.compute4(this.row)
            }else if(this.sel == 'cbowhId') {
              this.row.cbowhName = this.changeCbowh(this.select)
            }
          }
        }
        this.dialogSelectVisible = false
      },
      changeSelect(treeList) {
        treeList.forEach(row => {
          row[this.sel] = this.select
          if(this.sel == 'vatgroup') {
            this.compute4(row)
          }else if(this.sel == 'cbowhId') {
            row.cbowhName = this.changeCbowh(row.cbowhId)
          }
          if(row.children) {
            this.changeSelect(row.children)
          }
        })
      },
      changeCbowh(e) {
        let name = ''
        this.cbOwhData.forEach(item => {
          if(item.cbOwhId == e) {
            name = item.name
          }
        })
        return name
      },
      handleSizeChange(val) {
        this.$emit('getWorkResList', {ofoWorkId: this.ofoWorkId, pageNum: 1,pageSize: val})
      },
      handleCurrentChange(val) {
        this.$emit('getWorkResList', {ofoWorkId: this.ofoWorkId, pageNum: val, pageSize: this.pageSize})
      },
      tableRowClassName({row, rowIndex}) {
        // if (row.ofoWorkId == this.ruleForm.ofoWorkId) {
          return 'success-row';
        // }
        // return '';
      },
      tableTreeRowStyle({row, rowIndex}) {
        // console.log(JSON.parse(JSON.stringify(row)));
        let color = 230 + row.level * 4
        return this.ofoFlowWork.isCalcBom == '0' ? {
          background: `rgb(${color},${color},${color})`
        } : {}
      },
      // 新增资源
      addResource() {
        this.resourceForm.resourceName = ''
        this.resourceForm.remarks = ''
        this.resourceForm.dataSourceConfig = []
        this.dialogResourceVisible = true
        this.cbResourceTypeList.some(item => {
          if(item.isAdd == '0') {
            this.resourceForm.cbRestypeId = item.cbRestypeId
            this.getCbussDimeList()
            return true
          }
        })
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.resourceForm.clearValidate();
        })
        // 获取资源维度列表
        
      },
      changeRes() {
        this.getCbussDimeList()
      },
      getCbussDimeList() {
        this.$http.get(url.getCbussDimeList, {params: {cbussId: this.resourceForm.cbRestypeId, dimetype: '1'}}).then(res => {
          if(res.data.code == 200) {
            this.resourceFormList = this.handleFormList(res.data.data)
            this.resourceForm.dataSourceConfig = this.resourceFormList
          }
        })
      },
      // 提交资源
      submitResourceForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isDisabled = true
            let dimes = []
            if(this.resourceFormList.length) {
              dimes = this.handleDimes(this.resourceFormList)
            }
            delete this.resourceForm.dataSourceConfig
            // 新增产品
            this.$http.post(url.addOfrResource, qs.stringify({
              ...this.resourceForm,
              dimes: JSON.stringify(dimes)
            })).then(res => {
              this.isDisabled = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg)
                this.dialogResourceVisible = false
                this.ofrResourceId = res.data.data
                this.$http.get(url.getResTypesAndList, {params:
                  {
                    cbRestypeIds: this.ofoFlowWork.kindtype,
                  }
                }).then(res => {
                  if(res.data.code == 200) {
                    this.goodsList = res.data.data.list
                    this.goodsList.forEach(item => {
                      if(item.ofrResourceId == this.ofrResourceId) {
                        this.ofrresourceName = item.resourceName
                      }
                      item.name = item.resourceName.split(':')[0]
                    })
                    this.productsList.push({
                      cbowhId: (this.ofoFlowWork.chooseOwh == '0' && this.cbOwhData.length) ? this.cbOwhData[0].cbOwhId : '',
                      cbowhName: (this.ofoFlowWork.chooseOwh == '0' && this.cbOwhData.length) ? this.cbOwhData[0].name : '',
                      ofrresourceId: this.ofrResourceId,
                      ofrresourceName: this.ofrresourceName,
                      quantity: this.ofoFlowWork.costmanagetype == '4' ? 1 : '',
                      price: '',
                      total: '',
                      vatgroup: this.dropDown.invoiceVat[0].dictValue,
                      totalVat: '',
                      invoicetype: this.dropDown.invoiceType[0].dictValue,
                      cost: '',
                      costTotal: '',
                      costTotalVat: '',
                      beginValue: '',
                      endValue: '',
                      workConfigList1: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList1) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList1)) : [],
                      workConfigList2: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList2) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList2)) : [],
                      workConfigList3: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList3) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList3)) : [],
                      workConfigList4: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList4) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList4)) : [],
                    })
                  }
                })
              }
            })
          }
        })
      },
      // 点击复制从按钮
      async copyFromAdd() {
        this.loading = true
        let ofoflowworkId = this.ofoFlowWork.ofoFlowWorkId
        this.dialogCopyFromVisible = true
        this.comfirmCopyFromList = []
        await this.$http.get(url.getWorkFromList, {params: {ofoflowworkId}}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.copyFromList = res.data.data
            this.copyFromList.forEach((item, index) => {
              item.childIdList = []
              // item.ofoWorkResourceList = this.handleDimes(item.ofoWorkResourceList, 'ofrresourceName', 'ofrresourceName')
              if(item.ofoWorkResourceList.length) {
                item.ofoWorkResourceList.forEach(val => {
                  val.parentId = item.ofoWorkId
                  item.childIdList.push(val.ofoWorkResourceId)
                })
              }
              item.ofoWorkResourceList = this.$handleTree(item.ofoWorkResourceList, 'bomId', 'pid')
            })
          }
        })
        // this.copyFromList.forEach((item, index) => {
        //   this.$refs['tableCopyFrom' + index].clearSelection()
        // })
      },
      // 点击行折叠展开
      cellClick(row, column, event) {
        this.$refs.tables.toggleRowExpansion(row)
      },
      // selectChild(selection, row, index) {

      //   if(row.children) {
      //     this.setSelect(row.children, index, selection.some(item => item.ofoWorkResourceId == row.ofoWorkResourceId))
      //   }
      // },
      // setSelect(list, index, isCheck) {
      //   list.forEach(item => {
      //     this.$refs['tableCopyFrom' + index].toggleRowSelection(item, isCheck)
      //     if(item.children) {
      //       this.setSelect(item.children, index, isCheck)
      //     }
      //   })
      // },
      // selectAllChild(selection, clickIndex) {
      //   let a = selection.map(item => item.ofoWorkResourceId)
      //   let b = this.copyFromList[clickIndex].ofoWorkResourceList.map(item => item.ofoWorkResourceId)
      //   let isCheck = b.every(item => a.includes(item))
      //   this.copyFromList[clickIndex].ofoWorkResourceList.forEach(item => {
      //     if(item.children) {
      //       this.setSelect(item.children, clickIndex, isCheck)
      //     }else {
      //       this.$refs['tableCopyFrom' + clickIndex].toggleRowSelection(item, isCheck)
      //     }
      //   })
      // },
      // selectFather(selection, row) {
      //   const isCheck = selection.includes(row)
      //   this.copyFromList.forEach((item, index) => {
      //     if (item.ofoWorkId === row.ofoWorkId) {
      //       this.$refs.tables.toggleRowExpansion(item, true)
      //       const tempList = row.ofoWorkResourceList
      //       this.$nextTick(() => {
      //         if (tempList.length !== 0) {
      //           row.ofoWorkResourceList.forEach((childItem) => {
      //             // this.selectAllChildMap.set(index, item)
      //             this.$refs['tableCopyFrom' + index].toggleRowSelection(childItem, isCheck)
      //             if(childItem.children) {
      //               this.setSelect(childItem.children, index, isCheck)
      //             }
      //           })
      //         }
      //       })
      //     }
      //   })
      // },
      // selectAllFather(selection,) {},
      // expandChange(row, expandedRows) {},

      // selectAll() {
      //   // console.log(this.copyFromList);
      //   this.$nextTick(() => {
      //     // console.log(this.$refs);
      //   })
      //   // this.comfirmCopyFromList = []
      //   // this.copyFromList.forEach(item => {

      //   // })
      // },
      selectFather(selection, row) {
        const isCheck = selection.includes(row)
        this.copyFromList.forEach((item, index) => {
          if (item.ofoWorkId === row.ofoWorkId) {
            this.$refs.tables.toggleRowExpansion(item, true)
            const tempList = row.ofoWorkResourceList
            this.$nextTick(() => {
              if (tempList.length !== 0) {
                tempList.forEach((childItem) => {
                  this.selectAllChildMap.set(index, item)
                  this.$refs['tableCopyFrom' + index].toggleRowSelection(childItem, isCheck)
                  if(childItem.children) {
                    this.setSelect(childItem.children, index, isCheck)
                  }
                })
              }
            })
          }
        })
        if (isCheck) {
          this.validIs(row.ofoWorkResourceList)
        } else {
          this.cleanIs(null, row)
        }
      },
      selectAllFather(selection) {
        this.copyFromList.forEach(async (item, index) => {
          await this.$refs['tables'].toggleRowExpansion(item, true)
          if (selection.length !== 0) {
            this.selectFather([item], item)
            this.selectAllChild(item.ofoWorkResourceList, index)
          } else {
            // console.log(item);
            this.cleanIs(null, item)
          }
          this.$refs['tableCopyFrom' + index].clearSelection()
        })
        // 
      },
      expandChange(row, expandedRows) {
        // console.log(row);
        // console.log(expandedRows);
        const expandedRowsIds = expandedRows.map(m => m.ofoWorkId)
        this.$nextTick(() => {
          const ids = this.comfirmCopyFromList.map(f => f.ofoWorkResourceId)
          this.copyFromList.forEach((item, index) => {
            if(item.ofoWorkId == row.ofoWorkId && expandedRowsIds.includes(row.ofoWorkId)) {
              row.ofoWorkResourceList.forEach(val => {
                if(ids.includes(val.ofoWorkResourceId)) {
                  this.$refs['tableCopyFrom' + index].toggleRowSelection(val, true)
                  if(val.children) {
                    this.setSelect(val.children, index, true)
                  }
                }
              })
            }
          })
        })
        
      },
      selectChild(selection, row, index) {
        const ids = this.comfirmCopyFromList.map(f => f.ofoWorkResourceId)
        ids.includes(row.ofoWorkResourceId) ? 
        this.comfirmCopyFromList.splice(this.comfirmCopyFromList.findIndex(item => item.ofoWorkResourceId == row.ofoWorkResourceId), 1) : 
        this.comfirmCopyFromList.push(row)
        if(row.children) {
          this.setSelect(row.children, index, !ids.includes(row.ofoWorkResourceId))
        }
        const isCheck = this.$refs['tableCopyFrom' + index].selection.length > 0
        // this.getOneArr(JSON.parse(JSON.stringify(this.copyFromList))).forEach((item, index) => {
        //   if (item.ofoWorkId === row.parentId) {
        //     this.selectAllChildMap.set(index, item)
        //     console.log(this.selectAllChildMap);
        //     this.$refs.tables.toggleRowSelection(item, isCheck)
        //   }
        // })
        this.copyFromList.forEach((item, index) => {
          if (item.ofoWorkId === row.parentId) {
            this.selectAllChildMap.set(index, item)
            // console.log(this.selectAllChildMap);
            this.$refs.tables.toggleRowSelection(item, isCheck)
          }
        })
        // console.log(this.comfirmCopyFromList.map(f => f.ofrresourceName));
      },
      setSelect(list, index, isCheck) {
        list.forEach(item => {
          this.$refs['tableCopyFrom' + index].toggleRowSelection(item, isCheck)
          const ids = this.comfirmCopyFromList.map(f => f.ofoWorkResourceId)
          if(isCheck) {
            ids.includes(item.ofoWorkResourceId) ? true : this.comfirmCopyFromList.push(item)
          }else {
            ids.includes(item.ofoWorkResourceId) ? this.comfirmCopyFromList.splice(this.comfirmCopyFromList.findIndex(val => val.ofoWorkResourceId == item.ofoWorkResourceId), 1) : true
          }
          if(item.children) {
            this.setSelect(item.children, index, isCheck)
          }
        })
      },
      selectAllChild(selection, clickIndex) {
        let a = selection.map(item => item.ofoWorkResourceId)
        let b = this.copyFromList[clickIndex].ofoWorkResourceList.map(item => item.ofoWorkResourceId)
        let isCheck = b.every(item => a.includes(item))
        this.copyFromList[clickIndex].ofoWorkResourceList.forEach(item => {
          if(item.children) {
            this.setSelect(item.children, clickIndex, isCheck)
          }else {
            this.$refs['tableCopyFrom' + clickIndex].toggleRowSelection(item, isCheck)
          }
        })
        if (selection.length > 0) {
          const fatherRow = this.copyFromList.find(item => {
            return item.ofoWorkId === selection[0].parentId
          })
          this.selectAllChildMap.set(clickIndex, fatherRow)
          this.$refs.tables.toggleRowSelection(this.selectAllChildMap.get(clickIndex), true)
          // 非空时候 检验明细是否存在
          this.validIs(selection)
        } else {
          this.cleanIs(clickIndex)
          this.$refs.tables.toggleRowSelection(this.selectAllChildMap.get(clickIndex), false)
          this.selectAllChildMap.delete(clickIndex)
        }
      },
      //一级勾选框和子级头部勾选框检验
      validIs (selection) {
        // 非空时候 检验明细是否存在
        if (this.comfirmCopyFromList.length === 0) {
          this.comfirmCopyFromList.push(...selection)
        } else {
          let ids = this.comfirmCopyFromList.map(f => f.ofoWorkResourceId)
          selection.forEach(f => {
            if (ids.indexOf(f.ofoWorkResourceId) !== -1) {
              delete this.comfirmCopyFromList[ids.indexOf(f.ofoWorkResourceId)]
            }
          })
          this.comfirmCopyFromList = this.comfirmCopyFromList.filter(f => f.ofoWorkResourceId)
          ids = this.comfirmCopyFromList.map(f => f.ofoWorkResourceId)
          this.comfirmCopyFromList.push(...selection)
        }
      },
      //一级勾选框和子级头部勾选清空方法
      cleanIs (clickIndex, fatherRow) {
        const childIdList = clickIndex || clickIndex === 0 ? this.copyFromList[clickIndex].childIdList : fatherRow.childIdList
        let ids = this.comfirmCopyFromList.map(f => f.ofoWorkResourceId)
        childIdList.forEach(f => {
          if (ids.indexOf(f) !== -1) {
            delete this.comfirmCopyFromList[ids.indexOf(f)]
          }
        })
        this.comfirmCopyFromList = this.comfirmCopyFromList.filter(f => f.ofoWorkResourceId)
        ids = this.comfirmCopyFromList.map(f => f.ofoWorkResourceId)
      },
      // 勾选复制从列表
      handleCopyFromSelect(val, row) {
        let index = this.comfirmCopyFromList.findIndex(item => item.ofoWorkResourceId == row.ofoWorkResourceId)
        // let index = this.comfirmCopyFromList.findIndex(item => item == row.total)
        if (index == -1) {
          this.comfirmCopyFromList.push(row)
          // this.comfirmCopyFromList.push(row.total)
        }else {
          this.comfirmCopyFromList.splice(index, 1)
        }
      },
      handleCopyFromSelectAll(val, selectedTable) {
        selectedTable.forEach(el => {
          let index = this.comfirmCopyFromList.findIndex(item => item.ofoWorkResourceId == el.ofoWorkResourceId)
          // let index = this.comfirmCopyFromList.findIndex(item => item == el.total)
          if(val.length) {
            if (index == -1) {
              this.comfirmCopyFromList.push(el)
              // this.comfirmCopyFromList.push(el.total)
            }
          }else {
            if(index != -1) {
              this.comfirmCopyFromList.splice(index, 1)
            }
          }
        })
      },
      handleCopyFromRowClick(row, column, event, index) {
        this.$refs['tableCopyFrom' + index].toggleRowSelection(row)
        // const ids = this.comfirmCopyFromList.map(item => item.ofoWorkResourceId)
        // // if(row.children) {
        // //   this.setSelect(row.children, index, !ids.some(item => item == row.ofoWorkResourceId))
        // // }
        // // ids.includes(row.ofoWorkResourceId) ? this.comfirmCopyFromList.splice(ids.findIndex(item => item == row.ofoWorkResourceId), 1) : this.comfirmCopyFromList.push(row)
        this.selectChild([row], row, index)
      },
      getRowKeyCopyFrom(row) {
        return row.ofoWorkResourceId
      },
      getRowKey(row) {
        // console.log(JSON.parse(JSON.stringify(row)));
        return row.bomId
      },
      // 确认复制从
      async confirmCopyFrom() {
        if(this.ofoFlowWork.beforecopy == '3') {
          this.comfirmCopyFromList = this.comfirmCopyFromList.filter(val => {
            return !val.children
          })
        }else {
          this.comfirmCopyFromList = this.comfirmCopyFromList.filter(val => {
            return !val.pid
          })
        }
        this.dialogCopyFromVisible = false
        let obj = {}
        let thingsList = []
        this.copyFromList.forEach(item => {
          if(item.ofoWorkResourceList.length) {
            this.getOneArr(JSON.parse(JSON.stringify(item.ofoWorkResourceList))).forEach(val => {
              if(val.ofoWorkResourceId == this.comfirmCopyFromList[0].ofoWorkResourceId) {
                obj = item
              }
            })
          }
        })
        this.comfirmCopyFromList = handleList(this.comfirmCopyFromList, {ofoDealId: '1'}, '1', this.ofoFlowWork, this.cbOwhData, 'foWorkResourceId')
        this.comfirmCopyFromList = handleWorkConfigList(this.ofoFlowWork.checkConfig, this.comfirmCopyFromList, this.workConfig)
        this.comfirmCopyFromList.forEach(item => {
          if(this.ofoFlowWork.thingtype) {
            item.ofoWorkThingList.forEach(val => {
              thingsList.push(val)
            })
          }else {
            thingsList = []
          }
          
        })
        thingsList = handleList(thingsList, {ofoDealId: '1'}, '1', this.ofoFlowWork, this.cbOwhData, 'foWorkThingId')
        thingsList = handleWorkConfigList(this.ofoFlowWork.checkConfig, thingsList, this.workConfig)
        Promise.all(
          this.comfirmCopyFromList.map((item) => {
            return new Promise((resolve, reject) => {
              setTimeout(async () => {
                item.bomId = -1 - this.index
                this.index ++
                item.pid = null
                item.remark = ''
                // console.log(JSON.parse(JSON.stringify(item)));
                if(this.ofoFlowWork.isCalcBom == '0') {
                  // console.log(111111111);
                  await this.$http.get(url.bomResource + item.ofrresourceId).then(res => {
                    let data = res.data.data.map(val => {
                      return {
                        ...item,
                        ...val,
                        ofrresourceId: val.ofrResourceId,
                        ofrresourceName: val.name,
                        pid: val.pid === 0 ? item.bomId : (val.pid + '' + item.bomId),
                        bomId: val.bomId + '' + item.bomId,
                        quantity: val.quantity,
                        price: val.price,
                        oldQuantity: val.quantity,
                        total: '',
                        workConfigList1: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList1) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList1)) : [],
                        workConfigList2: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList2) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList2)) : [],
                        workConfigList3: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList3) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList3)) : [],
                        workConfigList4: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList4) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList4)) : [],
                      }
                    })
                    let tree = this.$handleTree(data, 'bomId', 'pid')
                    item.children = tree;
                    // item.children = [];
                  })
                }
                this.compute1(item)
                resolve( this.comfirmCopyFromList)
              },500)
            })
          })
        ).then((result) => {
          // console.log(1234);
          // console.log(JSON.parse(JSON.stringify(this.comfirmCopyFromList)));
          this.$emit('copyFromConfirm', {productsList: JSON.parse(JSON.stringify(this.comfirmCopyFromList)), obj, thingsList})
        })
      //  await this.comfirmCopyFromList.forEach(async (item) => {
          
      //   })
        
      },
      // 多选
      selectMore(isEdit, row, index) {
        // if(row.bomId < 0) {
        this.dialogTableVisible = true
        if(this.goods.length) {
          this.$refs.tableMultiple.clearSelection()
        }
        if(isEdit) {
          this.isAddRes = false
          // console.log(JSON.parse(JSON.stringify(row)))
          // console.log(this.productsList.findIndex(item => item.ofrresourceId == row.ofrresourceId));
          this.editResIndex = this.ofoFlowWork.isCalcBom == '0' ? this.productsList.findIndex(item => item.ofrresourceId == row.ofrresourceId) : index
          this.goodsList.forEach(item => {
            if(row.isMultiRes > 1) {
              this.isMultiRes = true
              row.ofrresourceId.split(',').forEach(val => {
                if(item.ofrResourceId == val) {
                  this.$nextTick(() => {
                    this.$refs.tableMultiple.toggleRowSelection(item, true)
                  })
                }
              })
            }else {
              this.isMultiRes = false
              if(item.ofrResourceId == row.ofrresourceId) {
                this.$nextTick(() => {
                  this.$refs.tableMultiple.toggleRowSelection(item, true)
                })
              }
            }
            
          })
        }else {
          this.isAddRes = true
        }
        // }
      },
      getBomResource(item, obj) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            let data = []
            this.$http.get(url.bomResource + item.ofrResourceId).then(res => {
              // console.log(data1);
              data = res.data.data.map(val => {
                return {
                  ...obj,
                  ...val,
                  ofrresourceId: val.ofrResourceId,
                  ofrresourceName: val.name,
                  pid: val.pid === 0 ? (-1 - this.productsList.length) : val.pid,
                  quantity: val.lossRate ? val.quantity * (1 + val.lossRate / 100) : val.quantity,
                  price: val.price,
                  oldQuantity: val.lossRate ? val.quantity * (1 + val.lossRate / 100) : val.quantity
                }
              })
              let tree = this.$handleTree(data, 'bomId', 'pid')
              obj.children = tree;
              this.productsList.push(obj)
            })
            resolve();
          }, 1000);
        });
        
      },
      // 确定多选
      async confirmMultiple() {
        if(this.goods.length) {
          if(this.isAddRes) {
            this.goods.forEach(async (item, index) => {
              // console.log(index);
              let obj = {
                cbowhId: (this.ofoFlowWork.chooseOwh == '0' && this.cbOwhData.length) ? this.cbOwhData[0].cbOwhId : '',
                cbowhName: (this.ofoFlowWork.chooseOwh == '0' && this.cbOwhData.length) ? this.cbOwhData[0].name : '',
                quantity: this.ofoFlowWork.costmanagetype == '4' ? 1 : (this.checked ? this.handleCalc(item.availableQuantityList) : ''),
                price: '',
                total: '',
                vatgroup: this.dropDown.invoiceVat[0].dictValue,
                totalVat: '',
                invoicetype: this.dropDown.invoiceType[0].dictValue,
                cost: '',
                costTotal: '',
                costTotalVat: '',
                beginValue: '',
                endValue: '',
                workConfigList1: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList1) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList1)) : [],
                workConfigList2: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList2) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList2)) : [],
                workConfigList3: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList3) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList3)) : [],
                workConfigList4: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList4) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList4)) : [],
                bomId: -1 - this.index,
                name: item.resourceName,
                rid: item.ofrResourceId,
                children: null,
                ofrresourceId: item.ofrResourceId,
                ofrresourceName: item.resourceName,
                pid: null
              }
              this.index ++
              // console.log(this.ofoFlowWork.isCalcBom);
              if(this.ofoFlowWork.isCalcBom == '0') {
                const res = await this.$http.get(url.bomResource + item.ofrResourceId)
                // console.log(index);
                let data = res.data.data.map(val => {
                  return {
                    ...obj,
                    ...val,
                    ofrresourceId: val.ofrResourceId,
                    ofrresourceName: val.name,
                    pid: val.pid === 0 ? obj.bomId : (val.pid + '' + obj.bomId),
                    bomId: val.bomId + '' + obj.bomId,
                    quantity: val.lossRate ? val.quantity * (1 + val.lossRate / 100) : val.quantity,
                    price: val.price,
                    oldQuantity: val.lossRate ? val.quantity * (1 + val.lossRate / 100) : val.quantity
                  }
                })
                // console.log(JSON.parse(JSON.stringify(data)))
                let tree = this.$handleTree(data, 'bomId', 'pid')
                // obj.bomId = -1 - this.productsList.length - index
                obj.children = tree;
              }
              this.productsList.push(obj)
              // console.log(JSON.parse(JSON.stringify(this.productsList)))
            })
            // console.log(JSON.parse(JSON.stringify(this.productsList)));
            this.dialogTableVisible = false
          }else {
            if(this.goods.length != 1) {
              this.$message.error('请选择一个资源');
            }else {
              // console.log(this.editResIndex);
              this.productsList[this.editResIndex] = {
                ...this.productsList[this.editResIndex],
                ofrresourceId: this.goods[0].ofrResourceId,
                ofrresourceName: this.goods[0].resourceName,
                rid: this.goods[0].ofrResourceId,
                name: this.goods[0].resourceName,
                children: null,
                pid: null,
                isMultiRes: 1
              }
              if(this.ofoFlowWork.isCalcBom == '0') {
                const res = await this.$http.get(url.bomResource + this.goods[0].ofrResourceId)
                // console.log(index);
                let data = res.data.data.map(val => {
                  return {
                    ...this.productsList[this.editResIndex],
                    ...val,
                    ofrresourceId: val.ofrResourceId,
                    ofrresourceName: val.name,
                    pid: val.pid === 0 ? this.productsList[this.editResIndex].bomId : val.pid,
                    quantity: val.quantity,
                    price: val.price,
                    oldQuantity: val.quantity
                  }
                })
                let tree = this.$handleTree(data, 'bomId', 'pid')
                // obj.bomId = -1 - this.productsList.length - index
                this.productsList[this.editResIndex].children = tree;
              }
              this.$set(this.productsList, this.editResIndex, this.productsList[this.editResIndex])
              // console.log(JSON.parse(JSON.stringify(this.productsList))[this.editResIndex]);
              // this.productsList[this.editResIndex].ofrresourceId = this.goods[0].ofrResourceId
              // this.productsList[this.editResIndex].ofrresourceName = this.goods[0].resourceName
              this.dialogTableVisible = false
              // this.productsList[this.editResIndex].isMultiRes = 1
            }
            
          }
        }else {
          this.$message.warning('请选择资源行');
        }
      },
      // 删除已选
      deleteMore() {
        for(var i = this.selectedId.length - 1; i >= 0; i--) {
          this.productsList.splice(this.selectedId[i], 1)
        }
      },
      // 产品增行
      addList() {
        this.productsList.push({
          cbowhId: (this.ofoFlowWork.chooseOwh == '0' && this.cbOwhData.length) ? this.cbOwhData[0].cbOwhId : '',
          ofrresourceId: this.goodsList.length ? this.goodsList[0].ofrResourceId : '',
          quantity: this.ofoFlowWork.costmanagetype == '4' ? 1 : '',
          price: '',
          total: '',
          vatgroup: this.dropDown.invoiceVat[0].dictValue,
          totalVat: '',
          invoicetype: this.dropDown.invoiceType[0].dictValue,
          cost: '',
          costTotal: '',
          costTotalVat: '',
          beginValue: '',
          endValue: '',
          remark: ''
        }) 
      },
      // 减行
      handleDelete(row, index) {
        // console.log(JSON.parse(JSON.stringify(row)));
        // console.log(row.bomId);
        // this.productsList.splice(index, 1)
        if(row.bomId <= 0) {
          // console.log(111111111111);
          // console.log(this.productsList.findIndex(item => item.bomId == row.bomId));
          
          this.productsList.splice(this.productsList.findIndex(item => item.bomId == row.bomId), 1)
        }else {
          // console.log(this.productsList.find(item => item.rid == row.rid))
          this.deleteChildren(this.productsList.find(item => item.rid == row.rid).children, row.bomId)
        }
      },
      deleteChildren(treeList, bomId) {
        if (!treeList || !treeList.length) {
          return
        }
        for (let i = 0; i < treeList.length; i++) {
          if (treeList[i].bomId === bomId) {
            treeList.splice(i, 1);
            break;
          }
          this.deleteChildren(treeList[i].children, bomId)
        }
      },
      // 表格的选中框事件
      handleRowChange(rows) {
        // console.log(rows);
        // console.log(this.productsList);
        if(rows.length) {
          let selectedId = []
          rows.forEach(item => {
            selectedId.push(item.index)
          })
          this.selectedId = selectedId
        }else {
          this.selectedId = []
        }
        // console.log(this.selectedId);
      },
      getRowKeyMultiple(row) {
        return row.ofrResourceId
      },
      // 表格的选中框事件
      handleMultipleSelectionChange(rows) {
        if(this.isAddRes || this.isMultiRes) {
          this.goods = rows
        }else {
          if(rows.length >= 2) {
            this.$refs.tableMultiple.toggleRowSelection(rows[0], false);
            this.goods = [rows[1]]
          }else {
            this.isMultiRes = false
            this.goods = rows
          }
        }
        // this.goods = rows
      },
      //点击行触发，选中或不选中复选框
      handleMultipleRowClick(row, column, event) {
        this.$refs.tableMultiple.toggleRowSelection(row);
        // console.log("this.selecTestContent", this.selectTestContent);
      },
      // 计算税额
      computeTax(row) {
        if(this.isTax) {
          // 税额
          row.totalVat = this.comdify(this.delComdify(row.total) / (1 + Number(row.vatgroup)) * row.vatgroup)
        }else {
          row.totalVat = this.comdify(this.delComdify(row.total) * row.vatgroup)
        }
      },
      // 计算成本税额
      computeCostTax(row) {
        if(this.isTax) {
          // 税额
          row.costTotalVat = this.comdify(this.delComdify(row.costTotal) / (1 + Number(row.vatgroup)) * row.vatgroup)
        }else {
          row.costTotalVat = this.comdify(this.delComdify(row.costTotal) * row.vatgroup)
        }
      },
      // 添加千分位
      comdify(n) {
        if(!n) return '';
        n = Number(n).toFixed(2)
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        return `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
      },
      // 添加千分位
      comdifyPrams(n) {
        if(!n) return '';
        n = Number(n).toFixed(this.params.priceAccuracy)
        // console.log(n);
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        // console.log(`${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`);
        return `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
      },
      // 添加千分位
      comdifyPrams1(n) {
        if(!n) return '';
        n = Number(n).toFixed(this.params.quantityAccuracy)
        // console.log(n);
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        // console.log(`${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`);
        return `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
      },
      // 去掉千分位
      delComdify(n) {
        if(!n) return '';
        let numS = n;
        numS = numS.toString();
        numS = numS.replace(/,/gi, '');
        return numS;
      },
      // 处理只能输入数字
      handlePrice(n) {
        return n.replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d{2}).*$/, '$1$2.$3')
      },
      // 处理只能输入数字
      handlePriceParams(n) {
        return n.replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(new RegExp('^(\\-)*(\\d+)\\.(\\d{'+this.params.priceAccuracy+'}).*$'), '$1$2.$3')
      },
      // 处理计算符
      handleCalcu(calcu) {
        if(calcu == '1') {
          return '+'
        }else if(calcu == '2') {
          return '-'
        }else if(calcu == '3') {
          return '*'
        }else if(calcu == '4') {
          return '/'
        }else {
          return ''
        }
      },
      submitConfigValue(item, index, prop) {
        this.$nextTick(() => {
          this.$refs[`popover${item.ofoFlowWorkConfigId}`][1].showPopper = false
        })
        this.productsList.forEach(val => {
          val[prop][index].configValue = this.configValue
          if(prop != 'workConfigList4') {
            this.computeSl(val, index, prop)
          }
        })
      },
      // 输入数量
      computeSl(row, index, prop) {
        if(prop == 'workConfigList1' && index) {
          row.workConfigList1[index].configValue = row.workConfigList1[index].configValue.replace(/[^\d.\-]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(new RegExp('^(\\-)*(\\d+)\\.(\\d{'+this.params.quantityAccuracy+'}).*$'), '$1$2.$3')
        }
        // console.log(JSON.parse(JSON.stringify(row[prop])));
        let length = row[prop].length
        if(length > 1) {
          let arr = []
          for(let i = 0; i < (length - 1); i++) {
            if(row[prop][i].showType != '2') {
              arr.push(row[prop][i].brackets && row[prop][i].brackets.includes('(') ? row[prop][i].brackets : '', row[prop][i].configValue ? (row[prop][i].showType == '1' ? (row[prop][i].configValue / 100) : row[prop][i].configValue) : 0, row[prop][i].brackets && row[prop][i].brackets.includes(')') ? row[prop][i].brackets : '', this.handleCalcu(row[prop][i].calcu))
            }
          }
          if(row[prop][length - 1].showType != '2') {
            arr.push(row[prop][length - 1].brackets && row[prop][length - 1].brackets.includes('(') ? row[prop][length - 1].brackets : '', row[prop][length - 1].configValue ? (row[prop][length - 1].showType == '1' ? (row[prop][length - 1].configValue / 100) : row[prop][length - 1].configValue) : 0, row[prop][length - 1].brackets && row[prop][length - 1].brackets.includes(')') ? row[prop][length - 1].brackets : '')
          }
          // console.log(arr.join(''));
          let total = eval(arr.join('')).toFixed(4)
          if(prop == 'workConfigList1') {
            row.quantity = total
            if(row.price || row.total) {
              if(!row.price) {
                row.price = this.comdifyPrams(this.delComdify(row.total) / row.quantity)
              }else {
                // 金额
                row.total = this.comdify(row.quantity * this.delComdify(row.price))
                this.computeTax(row)
              }
            }
            if(row.cost || row.total) {
              if(!row.cost) {
                row.cost = this.comdifyPrams(this.delComdify(row.costTotal) / row.quantity)
              }else {
                // 金额
                row.costTotal = this.comdify(row.quantity * this.delComdify(row.cost))
                this.computeCostTax(row)
              }
            }
          }else if(prop == 'workConfigList2') {
            row.price = total
            if(row.quantity || row.total) {
              if(!row.quantity) {
                row.quantity = (this.delComdify(row.total) / row.price).toFixed(this.params.quantityAccuracy)
              }else {
                // 金额
                row.total = this.comdify(row.quantity * row.price)
                this.computeTax(row)
              }
            }
          }else {
            row.total = total
            if(row.quantity || row.price) {
              if(row.quantity) {
                row.price = this.comdifyPrams(row.total / row.quantity)
              }else {
                row.quantity = (row.total / this.delComdify(row.price)).toFixed(this.params.quantityAccuracy)
              }
            }
            this.computeTax(row)
          }
        }
      },
      changeChildrenQuantity(row, quantity) {
        row.children.forEach(item => {
          item.quantity = (item.oldQuantity * quantity) ? String(item.oldQuantity * quantity) : ''
          this.compute1(item)
          if(item.children) {
            this.changeChildrenQuantity(item, quantity)
          }
        })
      },
      // 输入数量
      compute1(row) {
        row.quantity = String(row.quantity).replace(/[^\d.\-]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(new RegExp('^(\\-)*(\\d+)\\.(\\d{'+this.params.quantityAccuracy+'}).*$'), '$1$2.$3')
        if(!row.quantity) {
          row.total = ''
          row.totalVat = ''
          row.costTotal = ''
          row.costTotalVat = ''
        }else {
          if(row.bomId < 0 && row.children) {
            this.changeChildrenQuantity(row, row.quantity)
          }
          if(row.price || row.total) {
            if(!row.price) {
              row.price = this.comdifyPrams(this.delComdify(row.total) / row.quantity)
            }else {
              // 金额
              row.total = this.comdify(row.quantity * this.delComdify(row.price))
              this.computeTax(row)
            }
          }
          if(row.cost || row.total) {
            if(!row.cost) {
              row.cost = this.comdifyPrams(this.delComdify(row.costTotal) / row.quantity)
            }else {
              // 金额
              row.costTotal = this.comdify(row.quantity * this.delComdify(row.cost))
              this.computeCostTax(row)
            }
          }
        }
      },
      // 输入单价
      compute2(row) {
        // console.log(el);
        row.price = this.handlePriceParams(row.price)
        // row.price = this.comdify(row.price)
        if(!row.price) {
          row.total = ''
          row.totalVat = ''
        }else {
          if(row.quantity || row.total) {
            if(!row.quantity) {
              row.quantity = (this.delComdify(row.total) / row.price).toFixed(this.params.quantityAccuracy)
            }else {
              // 金额
              row.total = this.comdify(row.quantity * row.price)
              this.computeTax(row)
            }
          }
        }
      },
      compute22(row) {
        row.price = this.comdifyPrams(row.price)
      },
      compute222(row) {
        row.price = this.delComdify(row.price)
      },
      // 输入金额
      compute3(row) {
        row.total = this.handlePrice(row.total)
        if(!row.total) {
          row.price = ''
          row.totalVat = ''
        }else {
          if(row.quantity || row.price) {
            if(row.quantity) {
              row.price = this.comdifyPrams(row.total / row.quantity)
            }else {
              row.quantity = (row.total / this.delComdify(row.price)).toFixed(this.params.quantityAccuracy)
            }
          }
          this.computeTax(row)
        }
      },
      compute33(row) {
        row.total = this.comdify(row.total)
      },
      compute333(row) {
        row.total = this.delComdify(row.total)
      },
      // 输入税率
      compute4(row) {
        if(!row.total) {
          row.total = this.comdify(row.quantity * this.delComdify(row.price))
        }
        if(!row.costTotal) {
          row.costTotal = this.comdify(row.quantity * this.delComdify(row.cost))
        }
        this.computeTax(row)
        this.computeCostTax(row)
      },
      // 输入成本单价
      compute5(row) {
        row.cost = this.handlePriceParams(row.cost)
        if(!row.cost) {
          row.costTotal = ''
          row.totalVat = ''
        }else {
          if(row.quantity || row.costTotal) {
            if(!row.quantity) {
              row.quantity = (this.delComdify(row.costTotal) / row.cost).toFixed(this.params.quantityAccuracy)
            }else {
              // 金额
              row.costTotal = this.comdify(row.quantity * row.cost)
              this.computeCostTax(row)
            }
          }
        }
      },
      compute55(row) {
        row.cost = this.comdifyPrams(row.cost)
      },
      compute555(row) {
        row.cost = this.delComdify(row.cost)
      },
      // 输入成本金额
      compute6(row) {
        row.costTotal = this.handlePrice(row.costTotal)
        if(!row.costTotal) {
          row.cost = ''
          row.costTotalVat = ''
        }else {
          if(row.quantity || row.cost) {
            if(row.quantity) {
              row.cost = this.comdifyPrams(row.costTotal / row.quantity)
            }else {
              row.quantity = (row.costTotal / this.delComdify(row.cost)).toFixed(this.params.quantityAccuracy)
            }
          }
          this.computeCostTax(row)
        }
      },
      compute66(row) {
        row.costTotal = this.comdify(row.costTotal)
      },
      compute666(row) {
        row.costTotal = this.delComdify(row.costTotal)
      },
      // 计算时间
      handleChangeSj1(row) {
        console.log(row);
        this.startDate = new Date(row.beginValue)
        // console.log(this.startDate);
        if(row.endValue) {
          this.endDate = new Date(row.endValue)
          row.quantity = this.endDate - this.startDate
          // console.log(this.endDate - this.startDate);
        }
      },
      handleChangeSj2(row) {
        // console.log(row);
        this.endDate = new Date(row.endValue)
        // console.log(this.endDate);
        if(row.beginValue) {
          this.startDate = new Date(row.beginValue)
          row.quantity = this.endDate - this.startDate
          
        }
      },
      // 计算数量
      handleCount1(row) {
        this.startCount = row.beginValue
        if(row.endValue) {
          row.quantity = this.endCount - this.startCount
        }
      },
      handleCount2(row) {
        this.endCount = row.endValue
        if(row.beginValue) {
          row.quantity = this.endCount - this.startCount
        }
      },
      // 获取表格下拉列表
      async getList() {
        // 如果活动不是对象管理，获取仓库以及服务名称
        // if((this.documentstatus == '0' || this.documentstatus == '1' || this.documentstatus == '1') && this.flag) {
        // if(this.flag) {
        //   this.flag = false
          if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0') {
            // 获取仓库列表
            if(this.ofoFlowWork.chooseOwh == '0') {
              await this.$http.get(url.getCbOwhByCbResType, {params: {cbRestypeIds: this.ofoFlowWork.kindtype}}).then(res => {
                if(res.data.code == 200) {
                  this.cbOwhData = res.data.data
                  this.cbOwhData1 = handleTree(res.data.data, 'cbOwhId', 'pid')
                }
              })
            }
            // // 获取货物或应税劳务、服务名称列表
            // this.$http.get(url.getResTypeAndList, {params: {cbRestypeId: this.ofoFlowWork.kindtype}}).then(res => {
            //   this.cbResourceType = res.data.data.cbResourceType
            //   this.goodsList = res.data.data.list
            // })


            // 获取货物或应税劳务、服务名称列表
            this.$http.get(url.getResTypesAndList1 + this.ofoFlowWork.ofoFlowWorkId, {params: {cbRestypeIds: this.ofoFlowWork.kindtype}}).then(res => {
              if(res.data.code == 200) {
                this.cbResourceTypeList = res.data.data.cbResourceTypeList
                this.goodsList = res.data.data.list
                this.goodsList.forEach(item => {
                  item.name = item.resourceName.split(':')[0]
                })
                this.isAdd = res.data.data.isAdd
              }
            })
          }
        // }
        // 设置税率
        if(!this.dropDown.invoiceVat) {
          this.$http.get(url.getDataList, {params: {type: 'buss_invoice_vat'}}).then(res => {
            // console.log(res);
            // this.invoiceVat = res.data.data
            this.$store.commit('setInvoiceVat', res.data.data)
          })
        }
        // 设置发票类型
        if(!this.dropDown.invoiceType) {
          this.$http.get(url.getDataList, {params: {type: 'buss_invoice_type'}}).then(res => {
            // console.log(res);
            // this.invoiceVat = res.data.data
            this.$store.commit('setInvoiceType', res.data.data)
          })
        }
      },
      getOneArr (data) {
        let newData = []
        const callback = (item) => {
          (item.children || (item.children = [])).map(v => {
            callback(v)
          })
          delete item.children
          newData.push(item)
        }
        data.map(v => callback(v))
        return newData.reverse()
      },
    },
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown
      }),
      // 税额合计
      ptotalVat() {
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0') {
          let total = 0
          let data = JSON.parse(JSON.stringify(this.productsList))
          const flattenedArray = this.getOneArr(data);
          flattenedArray.forEach(item => {
            total += Number(this.delComdify(item.totalVat))
          })
          return total.toFixed(2)
        }
      },
      // 成本税额合计
      ctotalVat() {
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0') {
          let costTotal = 0
          let data = JSON.parse(JSON.stringify(this.productsList))
          const flattenedArray = this.getOneArr(data);
          flattenedArray.forEach(item => {
            costTotal += Number(this.delComdify(item.costTotalVat))
          })
          return costTotal.toFixed(2)
        }
      },
      // 金额合计
      ptotalNovat() {
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0') {
          let total = 0
          let data = JSON.parse(JSON.stringify(this.productsList))
          const flattenedArray = this.getOneArr(data);
          if(this.isTax) {
            flattenedArray.forEach(item => {
              total = total + Number(this.delComdify(item.total)) - Number(this.delComdify(item.totalVat))
            })
          }else {
            flattenedArray.forEach(item => {
              total += Number(this.delComdify(item.total))
            })
          }
          return total.toFixed(2)
        }
      },
      // 成本金额合计
      ctotalNovat() {
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0') {
          let costTotal = 0
          let data = JSON.parse(JSON.stringify(this.productsList))
          const flattenedArray = this.getOneArr(data);
          if(this.isTax) {
            flattenedArray.forEach(item => {
              costTotal = costTotal + Number(this.delComdify(item.costTotal)) - Number(this.delComdify(item.costTotalVat))
            })
          }else {
            flattenedArray.forEach(item => {
              costTotal += Number(this.delComdify(item.costTotal))
            })
          }
          return costTotal.toFixed(2)
        }
      },
      // 价税合计
      ptotal() {
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0') {
          let total = 0
          let data = JSON.parse(JSON.stringify(this.productsList))
          const flattenedArray = this.getOneArr(data);
          if(this.isTax) {
            flattenedArray.forEach(item => {
              total = total + Number(this.delComdify(item.total))
            })
          }else {
            flattenedArray.forEach(item => {
              total = total + Number(this.delComdify(item.total)) + Number(this.delComdify(item.totalVat))
            })
          }
          return total.toFixed(2)
        }
      },
      // 成本价税合计
      ctotal() {
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0') {
          let costTotal = 0
          let data = JSON.parse(JSON.stringify(this.productsList))
          const flattenedArray = this.getOneArr(data);
          if(this.isTax) {
            flattenedArray.forEach(item => {
              costTotal = costTotal + Number(this.delComdify(item.costTotal))
            })
          }else {
            flattenedArray.forEach(item => {
              costTotal = costTotal + Number(this.delComdify(item.costTotal)) + Number(this.delComdify(item.costTotalVat))
            })
          }
          return costTotal.toFixed(2)
        }
      },
     
    },
    watch: {
      'ofoFlowWork': {
        handler(newValue, oldValue) {
          // this.getList()
        }
      },
      'isTax': {
        handler(newValue, oldValue) {
          if(oldValue) { // 含税 -> 不含税
            this.productsList.forEach(item => {
              // console.log(item.total);
              let oldMoneyTax = this.delComdify(item.total)
              let oldMoneyCostTax = this.delComdify(item.costTotal)
              item.total = this.comdify(oldMoneyTax - this.delComdify(item.totalVat))
              item.costTotal = this.comdify(oldMoneyCostTax - this.delComdify(item.costTotalVat))
              if(item.quantity) {
                item.price = this.comdify(this.delComdify(item.total) / item.quantity)
                item.cost = this.comdify(this.delComdify(item.costTotal) / item.quantity)
              }
              
            })
          }else { // 不含税 -> 含税
            this.productsList.forEach(item => {
              // console.log(item.total);
              let oldMoneyTax = this.delComdify(item.total)
              let oldMoneyCostTax = this.delComdify(item.costTotal)
              item.total = this.comdify(Number(oldMoneyTax) + Number(this.delComdify(item.totalVat)))
              item.costTotal = this.comdify(Number(oldMoneyCostTax) + Number(this.delComdify(item.costTotalVat)))
              if(item.quantity) {
                item.price = this.comdify(this.delComdify(item.total) / item.quantity)
                item.cost = this.comdify(this.delComdify(item.costTotal) / item.quantity)
              }
            })
          }
        },
        deep: true
      },
      'productsList': {
        handler(newValue, oldValue) {
          // console.log('新');
          // console.log(JSON.parse(JSON.stringify(newValue)));
          // console.log('旧');
          // console.log(JSON.parse(JSON.stringify(oldValue)));
          let list = []
          // let data = []
          // newValue.forEach(item => {
          //   data.push(item)
          // })
          // let data = Object.assign([], this.productsList)
          let data = JSON.parse(JSON.stringify(newValue)).reverse()
          const flattenedArray = this.getOneArr(data);
          // console.log(JSON.parse(JSON.stringify(flattenedArray)));
          flattenedArray.forEach((item, index) => {
            let obj = {}
            obj.cbowhId = item.cbowhId
            obj.cbowhName = item.cbowhName
            obj.ofrresourceId = item.ofrresourceId
            obj.ofrresourceName = item.ofrresourceName
            obj.quantity = item.quantity
            obj.cost = ''
            obj.vatgroup = item.vatgroup
            obj.invoicetype = item.invoicetype
            obj.totalVat = this.delComdify(item.totalVat)
            obj.isVat = '0'
            // obj.cost = item.cost
            // obj.costTotal = item.costTotal
            obj.costTotalVat = this.delComdify(item.costTotalVat)
            obj.beginValue = item.beginValue
            obj.endValue = item.endValue
            obj.beginValue = item.beginValue
            obj.endValue = item.endValue
            obj.baseOfoWorkResourceId = item.baseOfoWorkResourceId
            obj.ofoWorkResourceId = item.ofoWorkResourceId
            obj.baseOfoWorkId = item.baseOfoWorkId
            obj.remark = item.remark
            obj.configValueList = [...item.workConfigList1, ...item.workConfigList2, ...item.workConfigList3, ...item.workConfigList4],
            obj.bomId = item.bomId
            obj.pid = item.pid
            obj.rid = item.rid
            obj.name = item.name
            if(!this.isTax) {
              let oldMoneyTax = this.delComdify(item.total)
              obj.total = Number(oldMoneyTax) + Number(this.delComdify(item.totalVat))
              let oldMoneyCostTax = this.delComdify(item.costTotal)
              obj.costTotal = Number(oldMoneyCostTax) + Number(this.delComdify(item.costTotalVat))
              if(item.quantity) {
                obj.price = (obj.total / item.quantity).toFixed(this.params.priceAccuracy)
                obj.cost = (obj.costTotal / item.quantity).toFixed(this.params.priceAccuracy)
              }
            }else {
              obj.price = this.delComdify(item.price)
              obj.total = this.delComdify(item.total)
              obj.cost = this.delComdify(item.cost)
              obj.costTotal = this.delComdify(item.costTotal)
            }
            list.push(obj)
          })
          // console.log(list);
          let ptotalObj = {
            ptotal: this.ptotal,
            ptotalNovat: this.ptotalNovat,
            ptotalVat: this.ptotalVat
          }
          let ctotalObj = {
            ctotal: this.ctotal,
            ctotalNovat: this.ctotalNovat,
            ctotalVat: this.ctotalVat
          }
          // this.productsList = list
          let productsList = [list, ptotalObj, ctotalObj]
          // console.log(JSON.parse(JSON.stringify(productsList)));
          
          this.$emit('sendProductsList', productsList)
        },
        deep: true
      },
      // 'ofoFlowWork': {
      //   handler(newValue, oldValue) {
      //     // console.log(newValue);
          
      //   }
      // }
    },
  }
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  margin: 0 auto;
  /* max-height: calc(100% - 72px); */
  height: calc(100% - 72px);
}
::v-deep .el-dialog__body {
  padding: 10px 0 10px 20px;
  /* max-height: calc(100vh - 206px); */
  height: calc(100vh - 206px);
  overflow: auto;
}

  ::v-deep .el-table .success-row {
    background: #ecf5ff;
  }
.res-dialog {
  ::v-deep .el-dialog {
    margin: 0 auto;
    max-height: calc(100% - 72px);
    height: auto;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px 10px 20px;
    max-height: calc(100vh - 206px);
    height: auto;
    overflow: auto;
  }
}
.right-input {
  ::v-deep .el-input__inner {
    text-align: right;
  }
}
.vatgroup {
  /* color: red; */
  cursor: pointer;
}
.vatgroup:hover {
  color: rgb(32, 160, 255);
}
::v-deep .el-table--border td {
  border-right: 0;
}
</style>